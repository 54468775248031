import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Spin, Row, Col, Form, Select
} from 'antd';
import {
  SearchOutlined
} from '@ant-design/icons'

import { studentSubjectClient } from '../../clients/student/StudentSubjectClient';
import { studentAreaClient } from '../../clients/student/StudentAreaClient';
import SubjectCard from '../../components/SubjectCard'; 
import { ISubject } from '../../types/Subject';
import Header from '../../components/Header';

import style from './SubjectListPage.module.scss';
import { Area, GreatArea } from '../../types/Area';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils'

const SubjectListPage: React.FC = () => {

  const [subjects, setSubjects] = useState<ISubject[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingBigAreas, setLoadingBigAreas] = useState(false);
  const [bigAreas, setBigAreas] = useState<GreatArea[]>([]);
  const [selectedBigArea, setSelectedBigArea] = useState<string>();
  const [allAreas, setAllAreas] = useState<Area[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [loadingAreas, setLoadingAreas] = useState(false);
  const [selectedArea, setSelectedArea] = useState<string>();
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    loadSubjects();
    loadBigAreas();
    loadAreas();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(selectedBigArea){
      filterAreas();
    }
  // eslint-disable-next-line
  }, [selectedBigArea]);

  return (
    <div className={style.subjectListPage}>
      <Header />
      <div className={style.filters}>
        <Form layout='vertical'>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label={capitalize(dict.grandeArea)} name='grandeArea'>
                <Select 
                  loading={loadingBigAreas} 
                  onChange={(value: string) => setSelectedBigArea(value)}
                  allowClear
                >
                  {bigAreas.map((value: GreatArea) => (
                    <Select.Option value={value.id} key={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={capitalize(dict.area.singular)} name='area'>
                <Select 
                  loading={loadingAreas}
                  onChange={(value: string) => setSelectedArea(value)}
                  allowClear
                >
                  {areas.map((value: Area) => (
                    <Select.Option value={value.id} key={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <SearchOutlined />
      </div>
      {loading &&
        <div className={style.loading}>
          <Spin tip='Carregando..' />
        </div>
      }
      <div className={style.subjectList}>
        {subjects
          .filter((value: ISubject) => 
            (selectedBigArea && value?.subjectGreatArea?.id == selectedBigArea && !selectedArea) ||
            (selectedArea && value?.subjectArea?.id == selectedArea && !selectedBigArea) ||
            (selectedArea && selectedBigArea && value?.subjectGreatArea?.id == selectedBigArea && value?.subjectArea?.id === selectedArea) ||
            (!selectedBigArea && !selectedArea)
          ).map((subject: ISubject) => (<SubjectCard key={subject.id} subject={subject} />))
        }
      </div>
    </div>
  );

  async function loadSubjects () {
    try {
      const companyId = getCompanyId()

      const { results } = await studentSubjectClient.findByCompany(companyId);
      setSubjects(results);
    } finally {
      setLoading(false);
    }
  }

  async function loadBigAreas () {
    const companyId = getCompanyId()
    setLoadingBigAreas(true);
    const { results } = await studentAreaClient.findBigAreaByCompany(companyId);

    setBigAreas(results || []);
    setLoadingBigAreas(false);
  }

  async function loadAreas () {
    setLoadingAreas(true)
    const companyId = getCompanyId()

    const { results } = await studentAreaClient.findAreaByCompany(companyId);
    setAllAreas(results);
    setAreas(allAreas)
    setLoadingAreas(false);
  }

  function filterAreas() {
    const filteredAreas = allAreas.filter( area => area.subjectGreatAreaId === selectedBigArea)
    setAreas(filteredAreas)
  }
}

export default SubjectListPage;
