export interface Bank {
  id: string;
  label: string;
}

export const BANKS = [
  { id: '341', label: '341 - ITAU UNIBANCO S.A.' },
  { id: '001', label: '001 - BANCO DO BRASIL S.A.' },
  { id: '237', label: '237 - BANCO BRADESCO S.A.' },
  { id: '104', label: '104 - CAIXA ECONÔMICA FEDERAL' },
  { id: '033', label: '033 - BANCO SANTANDER (BRASIL) S.A.' },
  { id: '745', label: '745 - BANCO CITIBANK S.A' },
  { id: '422', label: '422 - BANCO SAFRA S.A.' },
  { id: '003', label: '003 - BANCO DA AMAZONIA S.A.' },
  { id: '004', label: '004 - BANCO DO NORDESTE DO BRASIL S.A.' },
  { id: '007', label: '007 - BANCO NAC.DESENV.ECON. SOCIAL S.A.' },
  { id: '014', label: '014 - NATIXIS BRASIL S.A. - BCO. MULTIPLO' },
  { id: '015', label: '015 - UBS BRASIL CCTMV S.A.' },
  { id: '017', label: '017 - BNY MELLON BANCO S.A.' },
  { id: '018', label: '018 - BANCO TRICURY S.A.' },
  { id: '021', label: '021 - BANESTES S.A. BCO.EST.ESPIRITO SANTO' },
  { id: '025', label: '025 - BANCO ALFA S.A.' },
  { id: '029', label: '029 - BCO.ITAU BMG CONSIGNADO S.A' },
  { id: '036', label: '036 - BANCO BRADESCO BBI S.A.' },
  { id: '037', label: '037 - BANCO DO EST. DO PARA S.A.' },
  { id: '040', label: '040 - BANCO CARGILL S.A.' },
  { id: '041', label: '041 - BCO.EST.R.GRANDE DO SUL S.A.' },
  { id: '047', label: '047 - BANCO DO EST. DE SERGIPE S.A.' },
  { id: '060', label: '060 - CONFIDENCE CORRETORA DE CâMBIO S.A.' },
  { id: '062', label: '062 - HIPERCARD BANCO MULTIPLO S.A.' },
  { id: '063', label: '063 - BANCO BRADESCARD S.A' },
  { id: '065', label: '065 - BANCO ANDBANK BRASIL S.A.' },
  { id: '066', label: '066 - BANCO MORGAN STANLEY S.A.' },
  { id: '069', label: '069 - BANCO BPN BRASIL S.A' },
  { id: '070', label: '070 - BRB-BANCO DE BRASILIA S.A.' },
  { id: '074', label: '074 - BANCO J. SAFRA S.A.' },
  { id: '075', label: '075 - BANCO ABN AMRO S.A.' },
  { id: '076', label: '076 - BANCO KDB DO BRASIL S.A.' },
  { id: '077', label: '077 - BANCO INTERMEDIUM S.A.' },
  { id: '078', label: '078 - HAITONG BANCO DE INVEST DO BRASIL S.A.' },
  { id: '079', label: '079 - BANCO ORIGINAL DO AGRONEGOCIO S.A' },
  { id: '080', label: '080 - B&T ASSOCIADOS CORRETORA DE CAMBIO' },
  { id: '081', label: '081 - BBN BANCO BRASILEIRO DE NEGOCIOS' },
  { id: '082', label: '082 - BANCO TOPAZIO S.A.' },
  { id: '083', label: '083 - BANCO DA CHINA BRASIL S.A' },
  { id: '085', label: '085 - COOP CENTRAL CRED URBANO-CC CECRED' },
  { id: '092', label: '092 - BRICKELL S.A. CRED.FINC.INVESTIMENTO' },
  { id: '094', label: '094 - BANCO FINAXIS S.A.' },
  { id: '095', label: '095 - BANCO CONFIDENCE DE CAMBIO S.A.' },
  { id: '096', label: '096 - BANCO BM&F SERV.LIQ.E CUSTODIA S.A.' },
  { id: '097', label: '097 - COOP.C.CRED.NOROESTE BRAS.LTDA' },
  { id: '101', label: '101 - RENANSCENCA DIST.TIT.VAL.MOB.LTDA' },
  { id: '102', label: '102 - XP INVEST.CORR.CAMB.VLS MOB.S.A.' },
  { id: '103', label: '103 - EBS CAPITAL CORRETORA DE CAMBIO S.A.' },
  { id: '107', label: '107 - BANCO BBM S.A.' },
  { id: '111', label: '111 - OLIVEIRA TRUST DIST TIT.VAL.MOBILIARIOS' },
  { id: '113', label: '113 - MAGLIANO S.A COR.CAMB.VLS MOBLS.' },
  { id: '117', label: '117 - ADVANCED CORRET.CAMBIO LTDA' },
  { id: '118', label: '118 - STANDARD C.BANK BRASIL S.A. BI' },
  { id: '119', label: '119 - BANCO WESTERN UNION DO BRASIL S.A' },
  { id: '120', label: '120 - BANCO RODOBENS S.A' },
  { id: '121', label: '121 - BANCO AGIPLAN S.A' },
  { id: '122', label: '122 - BANCO BRADESCO BERJ S.A.' },
  { id: '124', label: '124 - BANCO WOORI BANK DO BRASIL S.A' },
  { id: '125', label: '125 - BRASIL PLURAL S.A BCO MULTIPLO' },
  { id: '126', label: '126 - BR PARTNERS BCO INVEST. S.A.' },
  { id: '128', label: '128 - MSB BANK - BANCO DE CAMBIO' },
  { id: '129', label: '129 - BI UBS BRASIL' },
  { id: '131', label: '131 - BANCO SC TULLET PREBON' },
  { id: '132', label: '132 - ICBC DO BRASIL BCO MULTIPLO S.A.' },
  { id: '134', label: '134 - BCG LIQUIDEZ DISTR TITS VLRS MOBLS LTDA' },
  { id: '135', label: '135 - GRADUAL CORR DE CAMB TIT E VAL MOB S.A' },
  { id: '138', label: '138 - GET MONEY CORRETORA DE CAMBIO S.A.' },
  { id: '139', label: '139 - INTESA SANPAOLO BRASIL S.A' },
  { id: '142', label: '142 - BROKER BRASIL CORRET CAMBIO LTDA' },
  { id: '143', label: '143 - TREVISO CORRETORA DE CAMBIO S/A' },
  { id: '144', label: '144 - BEXS BANCO DE CAMBIO S.A.' },
  { id: '145', label: '145 - LEVYCAM - CORRET DE CAMBIO E VALORES' },
  { id: '146', label: '146 - GUITTA CORRETORA DE CAMBIO' },
  { id: '147', label: '147 - RICO CORR DE TITULOS E VALORES IMOB S.A.' },
  { id: '157', label: '157 - ICAP DO BRASIL CORRETORA TITS VLS' },
  { id: '163', label: '163 - COMMERZBANK BRASIL S.A.' },
  { id: '167', label: '167 - S.HAYATA CORRETORA DE CAMBIO S.A.' },
  { id: '169', label: '169 - BANCO OLE BONSUCESSO CONSIG. S.A' },
  { id: '172', label: '172 - ALBATROSS CCV S.A.' },
  { id: '173', label: '173 - BRL TRUST DIST. DE TIT. E VAL. S.A.' },
  { id: '174', label: '174 - PERNAMBUCANAS FINANCIADORA S.A.' },
  { id: '180', label: '180 - BANCO CM CAPITAL MARKETS CCTVM LTDA' },
  { id: '184', label: '184 - BANCO ITAU BBA S.A.' },
  { id: '190', label: '190 - CECM SERV PUBL EST RS EM POA' },
  { id: '194', label: '194 - PARMETAL DISTRI. TIT. VAL. IMOB. LTDA' },
  { id: '196', label: '196 - FAIR CORRETORA DE CAMBIO S.A.' },
  { id: '204', label: '204 - BANCO BRADESCO CARTOES S.A.' },
  { id: '208', label: '208 - BANCO BTG PACTUAL S.A.' },
  { id: '212', label: '212 - BANCO ORIGINAL S.A.' },
  { id: '213', label: '213 - BANCO ARBI S.A.' },
  { id: '217', label: '217 - BANCO JOHN DEERE S.A.' },
  { id: '218', label: '218 - BANCO BS2 S.A.' },
  { id: '222', label: '222 - BANCO CREDIT AGRICOLE BRASIL S.A.' },
  { id: '224', label: '224 - BANCO FIBRA S.A.' },
  { id: '230', label: '230 - UNICARD BANCO MULTIPLO S.A.' },
  { id: '233', label: '233 - BANCO CIFRA S.A' },
  { id: '241', label: '241 - BANCO CLASSICO S.A.' },
  { id: '243', label: '243 - BANCO MAXIMA S.A.' },
  { id: '246', label: '246 - BANCO ABC-BRASIL S.A.' },
  { id: '248', label: '248 - BANCO BOAVISTA INTERATLANTICO S.A.' },
  { id: '249', label: '249 - BANCO INVESTCRED UNIBANCO S.A.' },
  { id: '250', label: '250 - BCV - BANCO DE CRÉDITO E VAREJO S/A' },
  { id: '253', label: '253 - BEXS CORRETORA DE CAMBIO S/A' },
  { id: '254', label: '254 - PARANA BANCO S.A.' },
  { id: '260', label: '260 - NU PAGAMENTOS S.A.' },
  { id: '263', label: '263 - BANCO CACIQUE S.A.' },
  { id: '265', label: '265 - BANCO FATOR S.A.' },
  { id: '266', label: '266 - BANCO CEDULA S.A.' },
  { id: '268', label: '268 - BARIGUI COMPANHIA HIPOTECÁRIA' },
  { id: '300', label: '300 - BANCO DE LA NACION ARGENTINA' },
  { id: '318', label: '318 - BANCO BMG S.A' },
  { id: '320', label: '320 - CHINA CONSTRUCTION BANK (BRASIL)BCO MULT' },
  { id: '366', label: '366 - BANCO SOCIETE GENERALE BRASIL S.A.' },
  { id: '370', label: '370 - BANCO MIZUHO DO BRASIL S.A.' },
  { id: '376', label: '376 - BANCO J. P. MORGAN S.A.' },
  { id: '389', label: '389 - BANCO MERCANTIL DO BRASIL S.A.' },
  { id: '394', label: '394 - BCO BRADESCO FINANCIAMENTOS S.A' },
  { id: '399', label: '399 - KIRTON BANK S.A. BCO. MULTIPLO' },
  { id: '412', label: '412 - BANCO CAPITAL S.A.' },
  { id: '456', label: '456 - BANCO DE TOKYO-MITSUBISHI UFJ BR S.A.' },
  { id: '464', label: '464 - BANCO SUMITOMO MITSUI BRASILEIRO S.A' },
  { id: '473', label: '473 - BANCO CAIXA GERAL - BRASIL S.A.' },
  { id: '477', label: '477 - CITIBANK N.A.' },
  { id: '487', label: '487 - DEUTSCHE BANK S.A.-BANCO ALEMAO' },
  { id: '492', label: '492 - ING BANK N.V.' },
  { id: '494', label: '494 - BANCO DE LA REP. OR. DEL URUGUAY' },
  { id: '495', label: '495 - BANCO DE LA PROV. DE BUENOS AIRES' },
  { id: '505', label: '505 - BANCO CREDIT SUISSE (BRASIL) S.A.' },
  { id: '545', label: '545 - SENSO CORR.CAMB.VAL.MOBILIARIOS S.A.' },
  { id: '600', label: '600 - BANCO LUSO BRASILEIRO S.A.' },
  { id: '604', label: '604 - BANCO INDUSTRIAL DO BRASIL S.A.' },
  { id: '610', label: '610 - BANCO VR S.A.' },
  { id: '611', label: '611 - BANCO PAULISTA S.A.' },
  { id: '612', label: '612 - BANCO GUANABARA S.A.' },
  { id: '613', label: '613 - BANCO PECUNIA S.A.' },
  { id: '623', label: '623 - BANCO PAN S.A.' },
  { id: '626', label: '626 - BANCO FICSA S.A.' },
  { id: '630', label: '630 - BANCO INTERCAP S.A.' },
  { id: '633', label: '633 - BANCO RENDIMENTO S.A.' },
  { id: '634', label: '634 - BANCO TRIANGULO S.A.' },
  { id: '637', label: '637 - BANCO SOFISA S.A.' },
  { id: '641', label: '641 - BANCO ALVORADA S.A' },
  { id: '643', label: '643 - BANCO PINE S.A.' },
  { id: '652', label: '652 - BANCO ITAU HOLDING FINANCEIRA S.A.' },
  { id: '653', label: '653 - BANCO INDUSVAL S.A.' },
  { id: '654', label: '654 - BANCO A.J.RENNER S.A.' },
  { id: '655', label: '655 - BANCO VOTORANTIM S.A.' },
  { id: '707', label: '707 - BANCO DAYCOVAL S.A.' },
  { id: '712', label: '712 - BANCO OURINVEST S.A' },
  { id: '719', label: '719 - BANIF-BCO.INTERN.FUNCHAL (BR) S.A.' },
  { id: '735', label: '735 - BANCO NEON S.A.' },
  { id: '739', label: '739 - BANCO CETELEM S.A.' },
  { id: '741', label: '741 - BANCO RIBEIRAO PRETO S.A.' },
  { id: '743', label: '743 - BANCO SEMEAR S.A.' },
  { id: '746', label: '746 - BANCO MODAL S.A.' },
  { id: '747', label: '747 - BANCO RABOBANK INTERNATIONAL BRASIL S.A.' },
  { id: '748', label: '748 - BANCO COOPERATIVO SICREDI S.A.' },
  { id: '751', label: '751 - SCOTIABANK BRASIL S/A B.MULTIPLO' },
  { id: '752', label: '752 - BANCO BNP PARIBAS BRASIL S.A.' },
  { id: '753', label: '753 - NOVO BANCO CONTINENTAL S.A.B-MULTIPLO' },
  { id: '754', label: '754 - BANCO SISTEMA S.A.' },
  { id: '755', label: '755 - B.OF A.MERRILL LYNCH B.MULT.S.A.' },
  { id: '756', label: '756 - BANCO COOPERATIVO DO BRASIL S.A.' },
  { id: '757', label: '757 - BANCO KEB HANA DO BRASIL S.A.' },
  { id: '988', label: '988 - NOVA PLATAFORMA DE COBRANçA' },
] as Bank[];