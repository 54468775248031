import { CalendarOutlined, CheckOutlined, ClockCircleOutlined, LeftCircleTwoTone, LoadingOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Alert, Col, DatePicker, Divider, Form, Input, message, Result, Row, Skeleton, Tabs } from "antd";
import { Store } from "antd/lib/form/interface";
import { formatToTimeZone } from "date-fns-timezone";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import Timer from "react-compound-timer";
import { useHistory, useParams } from "react-router-dom";
import { messageClient } from "../../clients/message/MessageClient";
import { studentClassClient } from "../../clients/student/StudentClassClient";
import { tutorClassClient } from "../../clients/tutor/TutorClassClient";
import Button from "../../components/Button";
import ClassDetailHeader from "../../components/ClassDetailHeader";
import PreClassForm from "../../componentsTutor/PreClassForm";
import { useAuth } from "../../hooks/AuthHook";
import { ClassStatus, IClass, UserStatus } from "../../types/Class";
import { CompanyDict } from "../../types/CompanyDict";
import { getCompanyDict, getCompanyId, getSecondaryColor } from "../../utils/CompanyConfigUtils";
import { capitalize } from "../../utils/StrUtils"
import style from "./ClassDetail.module.scss";
const { TabPane } = Tabs;

const ClassDetail: React.FC = () => {
  const auth = useAuth();
  const params = useParams<any>();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [startLoading, setStartLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [, setChatLoading] = useState(false);
  const [currClass, setCurrClass] = useState<IClass>();
  const dict: CompanyDict = getCompanyDict()

  const userStatus = (currClass?.usersStatus || []).find((userStatus: UserStatus) => userStatus.teacherId === auth.user.id);
  const notAcceptedList = (currClass?.usersStatus || []).filter((userStatus: UserStatus) => !userStatus.statusId);
  useEffect(() => {
    loadClass();

    if (currClass?.statusId && currClass.statusId === ClassStatus.SCHEDULED && userStatus?.statusId) {
      const interval = setInterval(() => {
        if (notAcceptedList.length) {
          loadClass();
        }
      }, 5000);

      return () => clearInterval(interval);
    }

    return;

    // eslint-disable-next-line
  }, [currClass?.statusId, userStatus?.statusId]);

  const limitToSchedule = currClass?.limitToScheduleUtc
    ? currClass.limitToScheduleUtc
    : undefined;
  const formatedLimit = limitToSchedule
    ? formatToTimeZone(limitToSchedule, "DD/MM", {
      timeZone: "America/Recife",
    })
    : undefined;

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div className={style.classDetail}>
      <LeftCircleTwoTone
        twoToneColor={getSecondaryColor()}
        style={{ fontSize: 24 }}
        className={style.goBackBtn}
        onClick={goBack}
      />
      {currClass && currClass?.scheduledOnUtc && (
        <>
          {currClass && <ClassDetailHeader currClass={currClass} />}
          <Tabs type="card" size="small">
            <TabPane tab="Detalhes" key="1">
              <div className={style.content}>
                <div className={style.actions}>
                  {!userStatus?.statusId && currClass.statusId === ClassStatus.SCHEDULED && (
                    <Button
                      icon={<PlayCircleOutlined />}
                      loading={startLoading}
                      onClick={startClass}
                    >
                      Iniciar
                    </Button>
                  )}
                  {userStatus?.statusId && userStatus.statusId === ClassStatus.ACCEPTED && currClass.statusId === ClassStatus.SCHEDULED && (
                    <Button
                      icon={<LoadingOutlined />}
                      loading={true}
                    >
                      Aguardando
                    </Button>
                  )}
                  {currClass.statusId === ClassStatus.ACTIVE && (
                    <Button
                      icon={<CheckOutlined />}
                      loading={saveLoading}
                      onClick={finishClass}
                    >
                      Finalizar
                    </Button>
                  )}
                </div>

                <br />
                {currClass.statusId === ClassStatus.ACTIVE &&
                  <Result
                  status="success"
                  title="O atendimento está rolando :)"
                  subTitle={
                    <>
                      <p>Atendimento iniciado às: {formatToTimeZone(currClass.startOnUtc, "HH:mm [em] DD/MM", {timeZone: "America/Recife",})}</p>
                      <p>
                        Duração do atendimento:{' '}
                        <Timer initialTime={new Date().getTime() - new Date(currClass.startOnUtc).getTime()}>
                          <Timer.Hours />h
                          <Timer.Minutes />m
                          <Timer.Seconds />s
                        </Timer>
                      </p>
                    </>
                  }
                />
                }

                {currClass.statusId === ClassStatus.DONE &&
                  <Result
                  status="success"
                  title="Finalizado com sucesso! 🤙"
                  subTitle={
                    <>
                      <p>Início: {formatToTimeZone(currClass.startOnUtc, "HH:mm [em] DD/MM", {timeZone: "America/Recife",})}</p>
                      <p>Fim: {formatToTimeZone(currClass.endOnUtc, "HH:mm [em] DD/MM", {timeZone: "America/Recife",})}</p>
                    </>
                  }
                />
                }
                <p className="preFormatedParagraph">
                  {currClass?.subject.description}
                </p>
                <Divider orientation="left">{capitalize(dict.cliente.plural)}</Divider>
                <ul>
                  {currClass?.students.map((student) => (
                    <li key={student.id}>
                      <span onClick={() => goToChat(student.id)}>{student.name}</span>
                      {isPresent(student.id) && (
                        <span>: presente <span role="img">✅</span></span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </TabPane>
            <TabPane tab="Anotações" key="2">
              <PreClassForm classId={currClass.id} preClass={currClass.preClass} />
            </TabPane>
          </Tabs>
        </>
      )}

      {!currClass?.scheduledOnUtc && (
        <div className={style.scheduleClass}>
          <ClockCircleOutlined style={{ fontSize: 64 }} />
          <p>
            Você ainda não agendou este atendimento. <br />
            <strong>Agende seu atendimento agora:</strong>
          </p>

          <Form onFinish={save}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label="Dia"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Escoha uma data",
                    },
                  ]}
                >
                  <DatePicker
                    format="DD/MM"
                    disabledDate={disabledDate}
                    placeholder="Dia"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Horário"
                  name="time"
                  rules={[
                    {
                      required: true,
                      message: "Escoha um horário",
                    },
                  ]}
                >
                  <Input
                    type='time'
                    min="00:00"
                    max="24:00"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button
              icon={<CalendarOutlined />}
              type="primary"
              loading={saveLoading}
              htmlType="submit"
            >
              {" "}
              Agendar{" "}
            </Button>
          </Form>

          {/* <Alert
            type="warning"
            message={`Você tem até o dia ${formatedLimit} para agendar.`}
          /> */}
        </div>
      )}
    </div>
  );

  async function loadClass() {
    const { id } = params;

    try {
      const { data, error } = await studentClassClient.findById(id);

      if (error) {
        message.error(error.message || "Falha ao carregar");
        return;
      }

      setCurrClass(data);
    } finally {
      setLoading(false);
    }
  }

  async function save(values: Store) {
    const { date, time } = values;

    const [hour, minute] = time.split(":");
    const year = date.year();
    const month = date.month();
    const day = date.date();

    const scheduledOnUtc = new Date(year, month, day, hour, minute);

    if (currClass) {
      setSaveLoading(true);

      try {
        const { error } = await studentClassClient.update(currClass.id, {
          scheduledOnUtc,
        });

        if (error) {
          message.error(error.message || "Falha ao salvar");
          return;
        }

        message.success("Atendimento agendado com sucesso!");
        loadClass();
      } finally {
        setSaveLoading(false);
      }
    }
  }

  function goBack() {
    history.goBack();
  }

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  async function startClass() {
    setStartLoading(true);

    if (currClass) {
      try {
        const { error } =
          auth.user.userType === "student"
            ? await studentClassClient.startClass(
              currClass.id,
              auth?.user?.id
            )
            : await tutorClassClient.startClass(
              currClass.id,
              auth?.user?.id
            );

        if (error) {
          message.error(error.message || "Falha ao iniciar o atendimento");
          return;
        }
      } finally {
        loadClass();
        setStartLoading(false);
      }
    }

  }

  async function finishClass() {
    if (currClass) {
      setSaveLoading(true);

      try {
        const { error } = await tutorClassClient.checkout(currClass.id);

        if (error) {
          message.error(error.message || 'Falha ao finalizar o atendimento.');
          return;
        }

        message.success('Atendimento finalizado com sucesso!');
      } catch {
        message.error('Falha ao finalizar o atendimento.');
      } finally {
        loadClass();
        setSaveLoading(false);
      }

    }
  }

  async function goToChat(studentId: string) {

    setChatLoading(true);

    try {

      const companyId = getCompanyId()

      // Try to get exisitng chaat between student and teacher
      const { results, error } = await messageClient.getChats({
        studentId: studentId,
        teacherId: auth.user.id,
        companyId: parseInt(companyId)
      })
      
      if (!error) {

        // If there's no chat, creates one and redirects to msgs page
        if (results && results.length === 0) {
          const { error } = await messageClient.createChat({
            studentId: studentId,
            teacherId: auth.user.id,
            companyId: parseInt(companyId)
          })
    
          if (!error) {
            history.push(`/profissional/mensagens?studentId=${studentId}`);
            return
          }
        }
        // If there's a chat, only redirects to msg page
        else {
          history.push(`/profissional/mensagens?studentId=${studentId}`);
          return
        }
      }

      message.error('Oops, não foi possível iniciar uma conversa. Por favor, tente novamente mais tarde');

    } finally {
      setChatLoading(false);
    }
  }

  function isPresent(studentId) {
    const studentStatusObj = (currClass?.usersStatus || []).find((user) => {
      return user.studentId == studentId
    })

    return studentStatusObj ? studentStatusObj.statusId == 20 : false
  }
};

export default ClassDetail;
