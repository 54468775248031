import { axiosClient } from "../axiosHandler";
import { Options } from "../axiosTypes";
import { BASE_URL } from "../configClient";
import { IMessage } from "../../types/Chat";

interface IMessageClient {
  sendMessage(message: Partial<IMessage>): Promise<any>;
  getMessages(id: string, userIdParam: {userId: string}): Promise<any>;
  getChats(any): Promise<any>;
  getChatById(id: string): Promise<any>;
  createChat(params: {teacherId: any, studentId: any, companyId: any}): Promise<any>;
}

const MessageClient = (
  baseURL: string,
  options?: Options
): IMessageClient => {

  const client = axiosClient(baseURL, options);

  return {
    sendMessage,
    getMessages,
    getChats,
    getChatById,
    createChat
  }

  async function sendMessage(message: any): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: '/message',
      data: message,
    });

    return data;
  }

  async function getMessages(id: string, userIdParam: any): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/chat/messages/${id}`,
      params: userIdParam
    });

    return data;
  }

  async function getChats(params: any): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/chat`,
      params
    });

    return data;
  }

  async function getChatById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/chat/${id}`,
    });

    return data;
  }

  async function createChat(params: any): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: `/chat`,
      data: params
    });

    return data;
  }
}

export const messageClient = MessageClient(BASE_URL);