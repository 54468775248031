import { addDays, isBefore, isEqual, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { getPrimaryColor, getBtnTextColor, getHighlightColor } from "../../utils/CompanyConfigUtils";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useSwipeable } from "react-swipeable";

import style from "./ScheduleDayForm.module.scss";
import { getDayOfWeekAbreviattedText, getDayOfWeekText, getMonthText } from "../../utils/DateUtils";

interface IScheduleDayForm {
  selectedDate: Date;
  setSelectedDate: any;
}

const dateNow = new Date();

const ScheduleDayForm: React.FC<IScheduleDayForm> = ({ selectedDate, setSelectedDate }) => {
  const [currentDateOptions, setCurrentDateOptions] = useState<Date[]>([]);

  useEffect(() => {
    const newArray: Date[] = [subDays(dateNow, dateNow.getDay())];
    for (let i = 0; i < 6; i++) {
      newArray.push(addDays(newArray[i], 1));
    }
    setCurrentDateOptions(newArray);
    setSelectedDate(dateNow);
  }, [setSelectedDate]);

  const handleGetPreviousDates = () => {
    const previousInitialDate = currentDateOptions[0];
    if (!isEqual(previousInitialDate, dateNow) && !isBefore(previousInitialDate, dateNow)) {
      const newArray: Date[] = [subDays(previousInitialDate, 7)];
      for (let i = 0; i < 6; i++) {
        newArray.push(addDays(newArray[i], 1));
      }
      setCurrentDateOptions(newArray);
    }
  };

  const handleGetNextDates = () => {
    const previousInitialDate = currentDateOptions[0];
    const newArray: Date[] = [addDays(previousInitialDate, 7)];
    for (let i = 0; i < 6; i++) {
      newArray.push(addDays(newArray[i], 1));
    }
    setCurrentDateOptions(newArray);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (eventData) => handleGetNextDates(),
    onSwipedRight: (eventData) => handleGetPreviousDates(),
  });

  return (
    <div className={style.dayForm}>
      <div className={currentDateOptions[0]?.getMonth() !== currentDateOptions[6]?.getMonth() ? style.months : style.month}>
        <div>
          {getMonthText(currentDateOptions[0]?.getMonth())} de {currentDateOptions[0]?.getFullYear()}
        </div>
        {currentDateOptions[0]?.getMonth() !== currentDateOptions[6]?.getMonth() && (
          <div className={style.monthPass}>
            {getMonthText(currentDateOptions[6]?.getMonth())} de {currentDateOptions[6]?.getFullYear()}
          </div>
        )}
      </div>
      <div {...swipeHandlers} className={style.daysCarousel}>
        <LeftOutlined id={"prev-week-arrow"} className={style.passButton} onClick={handleGetPreviousDates}></LeftOutlined>
        {currentDateOptions.map((date) => {
          const dayOfWeek = getDayOfWeekAbreviattedText(date.getDay());
          const dayOfWeekText = getDayOfWeekText(date.getDay());

          return (
            <div
              id={`${dayOfWeekText}-button`}
              className={style.dayButton}
              style={
                isEqual(date, selectedDate)
                  ? { backgroundColor: getPrimaryColor() + "80", color: getBtnTextColor(), borderColor: getBtnTextColor() }
                  : isBefore(date, dateNow)
                  ? { backgroundColor: "lightgray", color: "dimgrey", cursor: "not-allowed" }
                  : {}
              }
              onClick={isBefore(date, dateNow) ? () => {} : () => setSelectedDate(date)}
            >
              <div className={style.dayOfWeekDesktop}>{dayOfWeek.desktop}</div>
              <div className={style.dayOfWeekMobile}>{dayOfWeek.mobile}</div>
              <div className={style.monthDay}>{date.getDate()}</div>
            </div>
          );
        })}
        <RightOutlined id={"next-week-arrow"} className={style.passButton} onClick={handleGetNextDates}></RightOutlined>
      </div>
    </div>
  );
};

export default ScheduleDayForm;
