import * as React from "react";
import style from "./DashboardCardButton.module.scss";
import Button from "../Button";
import { useHistory } from "react-router-dom";

interface IDashboardCardButton {
    title: string,
    description: string,
    actionLink: string,
    icon: React.HTMLProps<HTMLElement>
}

const DashboardCardButton: React.FC<IDashboardCardButton> = (content: IDashboardCardButton) => {
    const history = useHistory();

    return(
        <div className={style.classCard}>
            <div className={style.content}>
                <div className={style.title}>
                    {content.icon}
                    <p className={style.titleText}>{content.title}</p>
                </div>
                <span>
                    {content.description}
                </span>
            </div>
            <div className={style.footerActions}>
                <Button onClick={onClickAction}>
                    Ver mais
                </Button>
            </div>
        </div>
    );

    function onClickAction() {
        history.push(content.actionLink)
    }
}

export default DashboardCardButton;
