import { addHours, isAfter, isEqual } from "date-fns";
import React, { useState } from "react";
import ScheduleTimeForm from "../../components/ScheduleTimeForm";
import ScheduleDayForm from "../../components/ScheduleDayForm";
import ScheduleSelectedOptionsPreview from "../../components/ScheduleSelectedOptionsPreview";
import { scheduleOption } from "../../types/Schedule";

import style from "./ScheduleForm.module.scss";
import OoriButton from "../../components/Button/Button";
import { SaveOutlined } from "@ant-design/icons";

interface iScheduleForm {
  scheduleCallback: (datesToSchedule: Date[]) => Promise<void>;
}

const ScheduleForm: React.FC<iScheduleForm> = ({ scheduleCallback }) => {
  let scheduleTimes: Date[] = [new Date()];
  scheduleTimes[0].setHours(0, 0, 0, 0);
  for (let i = 0; i < 23; i++) {
    scheduleTimes.push(addHours(scheduleTimes[i], 1));
  }

  const [selectedScheduleOptions, setSelectedScheduleOptions] = useState<scheduleOption[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [saveLoading, setSaveLoading] = useState(false);
  const [scheduleDayFormRef, setScheduleDayFormRef] = useState<any>(null);

  const handleSelectTime = (selectedTime: Date, isScheduled: boolean) => {
    let newArray: scheduleOption[] = [...selectedScheduleOptions];
    const selectedDateIndex = selectedScheduleOptions.findIndex((scheduleOption) => {
      return isEqual(scheduleOption.date, selectedDate);
    });
    if (!isScheduled) {
      if (selectedDateIndex === -1) {
        const newScheduleOption = { date: selectedDate, times: [selectedTime] };
        const insertIndex = newArray.findIndex((scheduleOption) => {
          return isAfter(scheduleOption.date, selectedDate);
        });
        if (insertIndex === -1) {
          newArray.push(newScheduleOption);
        } else {
          newArray.splice(insertIndex, 0, newScheduleOption);
        }
      } else {
        const insertIndex = newArray[selectedDateIndex].times.findIndex((time) => {
          return isAfter(time, selectedTime);
        });
        if (insertIndex === -1) {
          newArray[selectedDateIndex].times.push(selectedTime);
        } else {
          newArray[selectedDateIndex].times.splice(insertIndex, 0, selectedTime);
        }
      }
    } else {
      newArray[selectedDateIndex].times = newArray[selectedDateIndex].times.filter((time) => {
        return !isEqual(time, selectedTime);
      });
      if (newArray[selectedDateIndex].times.length == 0) {
        newArray = newArray.filter((scheduleOption) => {
          return !isEqual(scheduleOption.date, selectedDate);
        });
      }
    }
    setSelectedScheduleOptions(newArray);
  };

  return (
    <div className={style.scheduleForm}>
      <p
        className={style.sectionTitle}
        ref={(el) => {
          setScheduleDayFormRef(el);
        }}
      >
        Escolha um dia:
      </p>
      <ScheduleDayForm selectedDate={selectedDate} setSelectedDate={setSelectedDate}></ScheduleDayForm>

      <p className={style.sectionTitle}>Horários disponíveis:</p>
      <ScheduleTimeForm
        selectedDate={selectedDate}
        times={scheduleTimes}
        selectedTimes={getSelectedTimesForSelectedDate()}
        handleSelectTime={handleSelectTime}
        scheduleDayFormRef={scheduleDayFormRef}
      ></ScheduleTimeForm>

      <p className={style.sectionTitle}>Horários selecionados:</p>
      <ScheduleSelectedOptionsPreview handleClick={unselectTime} selectedScheduleOptions={selectedScheduleOptions}></ScheduleSelectedOptionsPreview>

      <OoriButton icon={<SaveOutlined />} type="primary" htmlType="submit" loading={saveLoading} onClick={save}>
        Agendar
      </OoriButton>
    </div>
  );

  function getSelectedTimesForSelectedDate() {
    let selectedDates = new Array<Date>();

    selectedDates = selectedScheduleOptions
      .filter((scheduleOption) => {
        return isEqual(scheduleOption.date, selectedDate);
      })
      .map((scheduleOption) => scheduleOption.times)[0];

    return selectedDates;
  }

  function getDatesToScheduleFromSelected() {
    const timesToSchedule = new Array<Date>();

    selectedScheduleOptions.forEach((scheduleOption) => {
      scheduleOption.times.forEach((time) => {
        const timeToSchedule = new Date(
          scheduleOption.date.getFullYear(),
          scheduleOption.date.getMonth(),
          scheduleOption.date.getDate(),
          time.getHours(),
          time.getMinutes()
        );

        timesToSchedule.push(timeToSchedule);
      });
    });

    return timesToSchedule;
  }

  async function save() {
    setSaveLoading(true);

    const datesToSchedule = getDatesToScheduleFromSelected();
    await scheduleCallback(datesToSchedule);

    setSaveLoading(false);
  }

  async function unselectTime(dateIndex: number, timeIndex: number) {
    let newScheduleOptions = [...selectedScheduleOptions];

    newScheduleOptions[dateIndex].times.splice(timeIndex, 1);

    setSelectedScheduleOptions([...newScheduleOptions]);
  }
};

export default ScheduleForm;
