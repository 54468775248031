export const AccountTypeLabel = {
  "conta_corrente": 'Conta corrente',
  "conta_poupanca": 'Conta poupança'
}

export const BankCodeLabel = {
  "260": 'Nu Pagamentos S.A.',
  "341": 'Itaú Unibanco',
  "237": 'Banco Bradesco S.A.',
  "001": 'Banco do Brasil S.A.',
  "104": 'Caixa Econômica Federal'
}