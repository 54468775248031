import * as React from "react";
import { useState, useEffect } from "react";
import { Divider, message, Skeleton, Tabs } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

import { studentClassClient } from "../../clients/student/StudentClassClient";
import ClassCard from "../../components/ClassCard";
import Header from "../../components/Header";
import { useAuth } from "../../hooks/AuthHook";
import { IClass, ClassStatus } from "../../types/Class";

import style from "./Home.module.scss";
import StudentDebts from "../../componentsStudent/StudentDebts";
import Calendar from "../../components/Calendar/Calendar";
import { CompanyDict } from "../../types/CompanyDict";
import { getCompanyDict, getCompanyId } from "../../utils/CompanyConfigUtils";
import StudentIncompleteProfile from "../../componentsStudent/StudentIncompleteProfile";

interface IUnscheduledClasses {
  [key: string]: IClass[];
}

const Home: React.FC = () => {
  const auth = useAuth();
  const [classes, setClasses] = useState<IClass[]>([]);
  const [unscheduledClasses, setUnscheduledClasses] = useState<IUnscheduledClasses>({});
  const [loading, setLoading] = useState(true);
  const [upcomingClassesAsc, setUpcomingClassesAsc] = useState(false);
  const [requestedClassesAsc, setRequestedClassesAsc] = useState(false);
  const [refusedClassesAsc, setRefusedClassesAsc] = useState(false);
  const dict: CompanyDict = getCompanyDict();

  useEffect(() => {
    loadClasses();
    // eslint-disable-next-line
  }, []);

  const refusedClasses = classes.filter((currClass: IClass) => currClass.statusId === ClassStatus.REFUSED);

  return (
    <div className={style.homePage}>
      <Header />
      <StudentIncompleteProfile />
      <StudentDebts />
      <Tabs type="card">
        <Tabs.TabPane tab="Lista" key="1">
          <section className={style.classes}>
            <section className={style.classesSection}>
              <Divider orientation="left">
                Agendamentos
                {upcomingClassesAsc && (
                  <>
                    <CaretUpOutlined style={{ marginLeft: "8px" }} onClick={sortUpcomingClasses} />
                  </>
                )}
                {!upcomingClassesAsc && (
                  <>
                    <CaretDownOutlined style={{ marginLeft: "8px" }} onClick={sortUpcomingClasses} />
                  </>
                )}
              </Divider>
              <div className={style.classList}>
                {loading && (
                  <>
                    <Skeleton active />
                    <Skeleton active />
                  </>
                )}
                {!loading &&
                  classes
                    .filter((c: IClass) => [ClassStatus.SCHEDULED, ClassStatus.ACCEPTED, ClassStatus.ACTIVE].includes(c.statusId))
                    .sort((a, b) => ((upcomingClassesAsc ? a.scheduledOnUtc > b.scheduledOnUtc : a.scheduledOnUtc < b.scheduledOnUtc) ? 1 : -1))
                    .map((c: IClass) => <ClassCard key={c.classId} currClass={c} />)}
                {!loading &&
                  classes.filter((c: IClass) => [ClassStatus.SCHEDULED, ClassStatus.ACCEPTED, ClassStatus.ACTIVE].includes(c.statusId)).length ===
                    0 && <p>Não há agendamentos.</p>}
              </div>
            </section>
            <section className={style.classesSection}>
              <Divider orientation="left">
                Solicitações
                {requestedClassesAsc && (
                  <>
                    <CaretUpOutlined style={{ marginLeft: "8px" }} onClick={sortRequestedClasses} />
                  </>
                )}
                {!requestedClassesAsc && (
                  <>
                    <CaretDownOutlined style={{ marginLeft: "8px" }} onClick={sortRequestedClasses} />
                  </>
                )}
              </Divider>
              <div id={"requested-list"} className={style.classList}>
                {loading && (
                  <>
                    <Skeleton active />
                    <Skeleton active />
                  </>
                )}
                {!loading &&
                  classes
                    .filter((c: IClass) => [ClassStatus.REQUESTED].includes(c.statusId) && c.scheduledOnUtc)
                    .sort((a, b) => ((requestedClassesAsc ? a.scheduledOnUtc > b.scheduledOnUtc : a.scheduledOnUtc < b.scheduledOnUtc) ? 1 : -1))
                    .map((c: IClass) => <ClassCard key={c.classId} currClass={c} />)}
                {!loading && classes.filter((c: IClass) => [ClassStatus.REQUESTED].includes(c.statusId) && c.scheduledOnUtc).length === 0 && (
                  <p>Não há solicitações.</p>
                )}
              </div>
            </section>
            <section className={style.classesSection}>
              {!!refusedClasses.length && (
                <>
                  <Divider orientation="left">
                    Agendamentos recusados
                    {refusedClassesAsc && (
                      <>
                        <CaretUpOutlined style={{ marginLeft: "8px" }} onClick={sortRefusedClasses} />
                      </>
                    )}
                    {!refusedClassesAsc && (
                      <>
                        <CaretDownOutlined style={{ marginLeft: "8px" }} onClick={sortRefusedClasses} />
                      </>
                    )}
                  </Divider>
                  <div className={style.classList}>
                    {refusedClasses
                      .sort((a, b) => ((refusedClassesAsc ? a.scheduledOnUtc > b.scheduledOnUtc : a.scheduledOnUtc < b.scheduledOnUtc) ? 1 : -1))
                      .map((c: IClass) => (
                        <ClassCard key={c.classId} currClass={c} />
                      ))}
                  </div>
                </>
              )}
              <Divider orientation="left">Agendar</Divider>
              <div className={style.classList}>
                {loading && (
                  <>
                    <Skeleton active />
                    <Skeleton active />
                  </>
                )}
                {!loading &&
                  Object.values(unscheduledClasses).map((classes: IClass[]) => (
                    <ClassCard key={classes[0].classId} currClass={classes[0]} unscheduledClasses={classes.length} />
                  ))}
                {!loading && Object.keys(unscheduledClasses).length === 0 && <p>Não há {dict.atendimento.plural} para agendar.</p>}
              </div>
            </section>
          </section>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Calendário" key="2">
          <section className={style.calendar}>
            <Calendar classes={classes} />
          </section>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );

  async function loadClasses() {
    try {
      const companyId = getCompanyId();
      const { results, error } = await studentClassClient.findAll({
        studentId: auth?.user?.id,
        companyId: companyId,
      });

      if (error) {
        message.error(error.message || "Falha ao carregar " + dict.atendimento.plural);
        return;
      }

      const unscheduled = results
        .filter((c: IClass) => !c.scheduledOnUtc)
        .reduce(
          (agg, curr) => ({
            ...agg,
            [curr.packageId]: agg[curr.packageId] ? [...agg[curr.packageId], curr] : [curr],
          }),
          {}
        );

      setUnscheduledClasses(unscheduled);

      setClasses(results);
    } finally {
      setLoading(false);
    }
  }

  function sortUpcomingClasses() {
    setUpcomingClassesAsc(!upcomingClassesAsc);
  }

  function sortRequestedClasses() {
    setRequestedClassesAsc(!requestedClassesAsc);
  }

  function sortRefusedClasses() {
    setRefusedClassesAsc(!refusedClassesAsc);
  }
};

export default Home;
