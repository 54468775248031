import * as React from 'react';
import { useState, useEffect } from 'react';
import { Skeleton, message, Timeline, Alert, Collapse } from 'antd';

import { tutorClient } from '../../clients/tutor/TutorClient';
import { TutorTransaction } from '../../types/Teacher';
import { useAuth } from '../../hooks/AuthHook';
import { formatToTimeZone } from 'date-fns-timezone';
import { formatCurrency } from '../../utils/CurrencyUtils';
import { PaymentMethodLabel, TransactionStatusLabel, TransactionStatus } from '../../types/Transaction';
import { differenceInMinutes } from 'date-fns';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils';
import { formatPercentage } from '../../utils/NumberUtils';

const TutorTransactions: React.FC = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<TutorTransaction[]>([]);

  useEffect(() => {

    loadTransactions();

    //eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <Skeleton active />}
      {!!transactions.length &&
        <Timeline>
          {transactions
            .filter((transaction: TutorTransaction) => transaction.status !== 'cancelled')
            .sort((a, b) => {
              const aDate = new Date(a.createdAt)
              const bDate = new Date(b.createdAt)
              return bDate.getTime() - aDate.getTime()
            })
            .map((transaction: TutorTransaction) => (
            <Timeline.Item color={getTimelineColor(transaction.status)} key={transaction.transactionId}>
              {formatToTimeZone(transaction.createdAt, "DD/MM/YY HH:mm", { timeZone: "America/Recife" })}: {formatCurrency(transaction.teacherTotal, 2)} (de {transaction?.student?.name})
              <br />
              <Collapse>
                <Collapse.Panel header="Detalhes" key={transaction.transactionId}>
                  <TransactionInfo transaction={transaction} />
                </Collapse.Panel>
              </Collapse>
            </Timeline.Item>
        ))}
        </Timeline>
      }
      {!loading && !transactions.length &&
        <Alert type='info' message='Ainda não existe nenhuma transação feita' />
      }
    </>
  );

  async function loadTransactions () {
    try {

      const companyId = getCompanyId()
      const { results, error } = await tutorClient.getTransactions({
        teacherId: auth.user.id,
        companyId
      });

      if (error) {
        message.error(error.message || 'Falha ao carregar transações');
        return;
      }
      setTransactions(results);
    } catch {
      message.error('Falha ao carregar transações');
    } finally {
      setLoading(false);
    }

  }

  function getTimelineColor (status: TransactionStatus) {

    const GREEN_STATUS = ['paid', 'refunded'];
    const BLUE_STATUS = ['processing', 'authorized'];
    const ORANGE_STATUS = ['waiting_payment', 'pending_refund'];
    const RED_STATUS = ['refused'];
    const GREY_STATUS = ['cancelled', 'error'];

    if (GREEN_STATUS.includes(status)) {
      return 'green';
    }

    if (BLUE_STATUS.includes(status)) {
      return 'blue';
    }

    if (ORANGE_STATUS.includes(status)) {
      return 'orange'
    }

    if (RED_STATUS.includes(status)) {
      return 'red'
    }

    if (GREY_STATUS.includes(status)) {
      return 'grey'
    }

    return '';
  }
}

interface ITransactionInfo {
  transaction: TutorTransaction
}

const TransactionInfo: React.FC<ITransactionInfo> = ({
  transaction
}) => {

  const date = transaction.class.scheduledOnUtc
    ? new Date(transaction.class.scheduledOnUtc)
    : undefined;
  const formatedDate = date
    ? formatToTimeZone(date, "DD/MM/YY [às] HH:mm", { timeZone: "America/Recife" })
    : undefined;

  const dict: CompanyDict = getCompanyDict()
  const ooriFee = transaction.ooriTotal + transaction.devTotal + transaction.devTotal
  const subtotal = transaction.total - ooriFee

  return (
    <>
      <strong>Método de pagamento:</strong> {PaymentMethodLabel[transaction.paymentMethod]} <br />
      <strong>Status:</strong> {TransactionStatusLabel[transaction.status]} <br />
      <strong>Valor pago:</strong> {formatCurrency(transaction.total, 2)}<br />
      <strong>Taxas OOri:</strong> 9% do valor pago {transaction.paymentMethod === 'boleto' ? ` + R$ 3,50 do boleto` : ''} = { formatCurrency(ooriFee, 2) }<br />
      <strong>Subtotal:</strong> {formatCurrency(transaction.total, 2)} - {formatCurrency(ooriFee, 2)} = {formatCurrency(subtotal, 2)}<br />
      <strong>Taxa da administração:</strong> {formatPercentage(transaction.adminTotal / subtotal, 2)} do subtotal = { formatCurrency(transaction.adminTotal, 2) }<br />
      <strong>Valor a receber:</strong> {formatCurrency(subtotal, 2)} - {formatCurrency(transaction.adminTotal, 2)} = {formatCurrency(transaction.teacherTotal, 2)}<br />
      <strong>Cobrança referente à: </strong>
        {transaction.type === 'checkout' && <>{capitalize(dict.atendimento.singular)} de {transaction.class.subjectName} {formatedDate}</>}
        {transaction.type === 'cancellation' && <>Cancelamento de {dict.atendimento.singular} {transaction.class.subjectName} {formatedDate}</>}
      <br />
      {transaction.type === 'class' && (
        <>
          <strong>Duração de {dict.atendimento.singular}: </strong>
          {differenceInMinutes(new Date(transaction.class.endOnUtc), new Date(transaction.class.startOnUtc))} minutos
        </>
      )}
      <br />
    </>
  );
}

export default TutorTransactions;
