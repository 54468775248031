import * as React from 'react';
import { useState, useEffect } from 'react';
import { Skeleton, message, Timeline, Alert, Collapse } from 'antd';

import { studentClient } from '../../clients/student/StudentClient';
import { StudentTransaction } from '../../types/Student';
import { useAuth } from '../../hooks/AuthHook';
import { formatToTimeZone } from 'date-fns-timezone';
import { differenceInMinutes } from 'date-fns';
import { formatCurrency } from '../../utils/CurrencyUtils';
import { TransactionStatusLabel, PaymentMethodLabel, TransactionStatus } from '../../types/Transaction';
import { isBefore, isAfter, endOfToday } from 'date-fns';
import { Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils'

const StudentTransactions: React.FC = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<StudentTransaction[]>([]);

  useEffect(() => {
    loadTransactions();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <Skeleton active />}
      {!!transactions.length &&
        <Timeline>
          {transactions
            .filter((transaction: StudentTransaction) => transaction.status !== 'cancelled')
            .sort((a, b) => {
              const aDate = new Date(a.createdAt)
              const bDate = new Date(b.createdAt)
              return bDate.getTime() - aDate.getTime()
            })
            .map((transaction: StudentTransaction) => (
            <Timeline.Item color={getTimelineColor(transaction.status)} key={transaction.transactionId}>
              {formatToTimeZone(new Date(transaction.createdAt), "DD/MM/YY HH:mm", { timeZone: "America/Recife" })}: {formatCurrency(transaction.total, 2)} (p/ {transaction.teacher.name})
              <br />
              <Collapse>
                <Collapse.Panel header="Detalhes" key={transaction.transactionId}>
                  <TransactionInfo transaction={transaction} afterSave={loadTransactions} />
                </Collapse.Panel>
              </Collapse>
            </Timeline.Item>
        ))}
        </Timeline>
      }
      {!loading && !transactions.length &&
        <Alert type='info' message='Ainda não existe nenhuma transação feita' />
      }
    </>
  );

  async function loadTransactions () {
    try {
      const companyId = getCompanyId()
      const { results, error } = await studentClient.getTransactions({
        studentId: auth.user.id,
        companyId
      });

      if (error) {
        message.error(error.message || 'Falha ao carregar transações');
        return;
      }

      setTransactions(results);
    } catch {
      message.error('Falha ao carregar transações');
    } finally {
      setLoading(false);
    }

  }

  function getTimelineColor (status: TransactionStatus) {

    const GREEN_STATUS = ['paid', 'refunded'];
    const BLUE_STATUS = ['processing', 'authorized'];
    const ORANGE_STATUS = ['waiting_payment', 'pending_refund'];
    const RED_STATUS = ['cancelled', 'refused', 'error'];

    if (GREEN_STATUS.includes(status)) {
      return 'green';
    }

    if (BLUE_STATUS.includes(status)) {
      return 'blue';
    }

    if (ORANGE_STATUS.includes(status)) {
      return 'orange'
    }

    if (RED_STATUS.includes(status)) {
      return 'red'
    }

    return '';
  }
}


interface ITransactionInfo {
  transaction: StudentTransaction;
  afterSave: () => Promise<void>;
}

const TransactionInfo: React.FC<ITransactionInfo> = ({
  transaction,
  afterSave
}) => {

  const [loading, setLoading] = useState(false);
  const dict: CompanyDict = getCompanyDict()


  const date = transaction.class.scheduledOnUtc
    ? transaction.class.scheduledOnUtc
    : undefined;
  const formatedDate = date
    ? formatToTimeZone(date, "DD/MM/YY [às] HH:mm", { timeZone: "America/Recife" })
    : undefined;

  return (
    <>
      <strong>Método de pagamento:</strong> {PaymentMethodLabel[transaction.paymentMethod]} <br />
      <strong>Status:</strong> {TransactionStatusLabel[transaction.status]} <br />
      <strong>Valor:</strong> {formatCurrency(transaction.total, 2)} {transaction.paymentMethod === 'boleto' ? '(valor do atendimento + R$ 3,50 do boleto)' : ''}<br />
      <strong>Cobrança referente à: </strong>
      {transaction.type === 'checkout' &&
        <>
          {capitalize(dict.atendimento.singular)} de {transaction.class?.subject?.name} em {formatedDate}
        </>
      }
        {transaction.type === 'cancellation' && <>Cancelamento {dict.atendimento.singular} de {transaction.class?.subject?.name} em {formatedDate}</>}
      <br />
      {transaction.type === 'checkout' && (
        <>
          <strong>Tempo de {dict.atendimento.singular}: </strong>
          {differenceInMinutes(new Date(transaction.class.endOnUtc), new Date(transaction.class.startOnUtc))} minutos
        </>
      )}
      <br />
      {transaction.status !== 'error' && transaction.paymentMethod === 'boleto' &&
        <>
          {transaction.boletoExpirationDate &&
            <>
              <strong>Vencimento do boleto:</strong> {formatToTimeZone(new Date(transaction.boletoExpirationDate), "DD/MM", { timeZone: "America/Recife" })} <br />
              {isBefore(endOfToday(), new Date(transaction.boletoExpirationDate)) &&
                <a href={transaction.boletoUrl} rel="noreferrer noopener" target='_blank'>Visualizar Boleto</a>
              }
              <br /><br />
              <CopyToClipboard
                text={transaction.boletoBarcode}
                onCopy={() => message.success('Código de barras copiado')}
              >
                <Button> Copiar código de barras</Button>
              </CopyToClipboard>
              <br /><br />
              <strong>{transaction.boletoBarcode}</strong>
              <br /><br />
            </>
          }
          {isAfter(new Date(transaction.boletoExpirationDate), endOfToday()) &&
            <Button onClick={() => retryPayment(transaction)} loading={loading}>
              Re-tentar cobrança
            </Button>
          }
        </>
      }
      {(transaction.status === 'error' || transaction.status === 'refused') &&
        <Button style={{ marginTop: '16px' }} onClick={() => retryPayment(transaction)} loading={loading}>
          Re-tentar cobrança
        </Button>
      }
    </>
  );

  async function retryPayment (transaction: StudentTransaction) {
    setLoading(true);
    try {
      const { error } = await studentClient.retryPayment({
        classId: transaction.classId,
        studentId: transaction.studentId,
        transactionId: transaction.id
      });

      if (error) {
        message.error(error.message || 'Falha ao re-tentar o pagamento');
        return;
      }

      message.success('Pagamento re-gerado com sucesso');
      window.location.reload();
    } catch (err) {
      console.log(err)
      message.error('Falha ao re-tentar o pagamento');
    } finally {
      await afterSave();
      setLoading(false);
    }
  }
}
export default StudentTransactions;
