import * as React from "react";
import { useHistory } from "react-router-dom";
import { Avatar } from "antd";

import { ISubject } from "../../types/Subject";
import defaultAvatar from "../../assets/defaultAvatar.jpeg";
import style from "./SubjectCard.module.scss";
import Button from "../Button";
import { formatCurrency } from "../../utils/CurrencyUtils";
import { CompanyDict } from "../../types/CompanyDict";
import { getCompanyDict } from "../../utils/CompanyConfigUtils";

interface ISubjectCard {
  subject: ISubject;
}

const SubjectCard: React.FC<ISubjectCard> = ({ subject }) => {
  const history = useHistory();
  const dict: CompanyDict = getCompanyDict();

  return (
    <div className={style.subjectCard} onClick={goToSubject} id={`${subject.name}-card`}>
      <div className={style.subjectHeader}>
        <div className={style.subjectTitle}>
          <h2>
            <strong>{subject.name}</strong>
          </h2>
          <small>com {subject.teacher?.name}</small>
        </div>
        <Avatar src={subject?.teacher?.profileImageUrl || defaultAvatar} size={64} />
      </div>

      <div className={style.actions}>
        <span>
          Hora/{dict.atendimento.singular}: <br /> <strong>{formatCurrency(subject.costPerHour, 2)}</strong>
        </span>
        <Button type="primary">Ver {dict.especialidade.singular}</Button>
      </div>
    </div>
  );

  function goToSubject() {
    history.push(`/cliente/especialidade/${subject.id}`);
  }
};

export default SubjectCard;
