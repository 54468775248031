import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Input, message, Row, Col, Spin } from 'antd';
import Button from '../../components/Button';
import { SaveOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import queryString from 'query-string';
import { getCompanyId} from '../../utils/CompanyConfigUtils';

import { adminBigAreaClient } from '../../clients/admin/AdminBigAreaClient';
import { Area } from '../../types/Area';

const BigAreaForm: React.FC = () => {

  const location = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingArea, setLoadingArea] = useState(false);
  const [area, setArea] = useState<Area>();

  const { subjectAreaId }: any = queryString.parse(location.search);

  useEffect(() => {
    if (subjectAreaId) {
      loadArea();
    }
    //eslint-disable-next-line
  }, []);

  if (loadingArea) {
    return <Spin tip='Carregando...' />
  }

  return (
    <Form form={form} layout='vertical' onFinish={save}>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item label='Nome' name='name'>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Button 
        icon={<SaveOutlined />} 
        type='primary'
        htmlType='submit'
        loading={loading}
      >Salvar</Button> 
    </Form>
  );

  async function save (values: Store) {
    setLoading(true);
    const companyId = getCompanyId()

    try {
      const { success, error } = subjectAreaId && area
        ? await adminBigAreaClient.update(area.id, values)
        : await adminBigAreaClient.create({
          ...values,
          companyId: parseInt(companyId)
        });

      if(error && !success) {
        message.error(error.message || 'Falha ao salvar');
        return;
      } 
      message.success('Salvo com sucesso');
    } catch {
      message.error('Falha ao salvar');
    } finally {
      setLoading(false);
    }
  }

  async function loadArea () {
    setLoadingArea(true);
    const { data }  = await adminBigAreaClient.findById(subjectAreaId);
    form.setFieldsValue(data);
    setArea(data);
    setLoadingArea(false);
  }
}

export default BigAreaForm;
