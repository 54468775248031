import * as React from 'react';
import { useState } from 'react';
import { Tabs, Drawer, Divider } from 'antd';
import style from './AdminThemeSettingsPage.module.scss';
import ThemeSettingsForm from '../../componentsAdmin/ThemeSettingsForm';
import BigAreaForm from '../../componentsAdmin/BigAreaForm';
import BigAreaListTable from '../../componentsAdmin/BigAreaListTable';
import AreaListTable from '../../componentsAdmin/AreaListTable';
import AreaForm from '../../componentsAdmin/AreaForm';
import { getCompany } from '../../utils/LocalStorageUtils';
import Button from '../../components/Button';

import Header from '../../components/Header';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils';

const AdminThemeSettingsPage: React.FC = () => {
  const company = getCompany()
  const [showBigAreaDrawer, setShowBigAreaDrawer] = useState(false);
  const [showAreaDrawer, setShowAreaDrawer] = useState(false);
  const [updateView, setUpdateView] = useState(false);
  const dict: CompanyDict = getCompanyDict()

  return(
    <div className={style.themeSettingsPage}>
      <Header />
      <Tabs type='card'>
        <Tabs.TabPane tab='Ambiente' key='1'>
          <ThemeSettingsForm config = {company ? JSON.parse(company) : ""}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab={capitalize(dict.grandeArea)} key='2'>
          <Button 
              type='primary'
              onClick={openBigAreaRegisterForm}
            >Cadastrar {dict.grandeArea}</Button>
            <Divider />
            <BigAreaListTable updateView={updateView} />
            <Drawer 
              title={'Cadastro de ' + dict.grandeArea}
              visible={showBigAreaDrawer}
              width={window.innerWidth < 600 ? '100%' : '60%'}
              destroyOnClose
              onClose={closeBigAreaRegisterForm}
            >
              <BigAreaForm />
            </Drawer>
        </Tabs.TabPane>
        <Tabs.TabPane tab={capitalize(dict.area.singular)} key='3'>
        <Button 
            type='primary'
            onClick={openAreaRegisterForm}
          >Cadastrar {dict.area.singular}</Button>
          <Divider />
          <AreaListTable updateView={updateView} />
          <Drawer 
            title={'Cadastro de ' + dict.area.singular}
            visible={showAreaDrawer}
            width={window.innerWidth < 600 ? '100%' : '60%'}
            destroyOnClose
            onClose={closeAreaRegisterForm}
          >
            <AreaForm />
          </Drawer>
        </Tabs.TabPane>
      </Tabs>
     
    </div>
  );

  function openBigAreaRegisterForm() {
    setShowBigAreaDrawer(true);
    setUpdateView(false);
  }

  function closeBigAreaRegisterForm() {
    setShowBigAreaDrawer(false);
    setUpdateView(true);
  }

  function openAreaRegisterForm() {
    setShowAreaDrawer(true);
    setUpdateView(false);
  }

  function closeAreaRegisterForm() {
    setShowAreaDrawer(false);
    setUpdateView(true);
  }
}

export default AdminThemeSettingsPage;
