import * as React from "react";
import { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { Skeleton, Divider, message, Alert } from "antd";
import { LeftCircleOutlined, MessageOutlined } from "@ant-design/icons";

import Button from "../../components/Button";
import ClassForm from "../../componentsStudent/ClassForm";
import { studentSubjectClient } from "../../clients/student/StudentSubjectClient";
import { studentClient } from "../../clients/student/StudentClient";
import { messageClient } from "../../clients/message/MessageClient";
import { useAuth } from "../../hooks/AuthHook";
import { ISubject } from "../../types/Subject";

import style from "./SubjectPage.module.scss";
import { formatCurrency } from "../../utils/CurrencyUtils";
import { getCompanyDict, getCompanyId, getSecondaryColor } from "../../utils/CompanyConfigUtils";
import { CompanyDict } from "../../types/CompanyDict";
import StudentIncompleteProfile from "../../componentsStudent/StudentIncompleteProfile";
import ScheduleForm from "../../componentsStudent/ScheduleForm";
import ScheduleDescription from "../../componentsStudent/ScheduleDescription";
import { IClass } from "../../types/Class";
import { studentClassClient } from "../../clients/student/StudentClassClient";

const SubjectPage: React.FC = () => {
  const auth = useAuth();
  const params = useParams<any>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [chatLoading, setChatLoading] = useState(false);
  const [subject, setSubject] = useState<ISubject>();
  const [scheduleAvailable, setScheduleAvailable] = useState(false);
  const [hasBlockingDebts, setHasBlockingDebts] = useState(false);
  const dict: CompanyDict = getCompanyDict();

  useEffect(() => {
    loadSubject();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.subjectPage}>
      {!subject && loading && <Skeleton active />}
      {subject && (
        <>
          <div className={style.header}>
            <LeftCircleOutlined twoToneColor={getSecondaryColor()} style={{ fontSize: 24 }} onClick={goBack} />
            <div className={style.subjectHeader}>
              <h1>{subject.name}</h1>
              <p>
                com <Link to={`/profissional/${subject?.teacher.id}`}>{subject.teacher.name}</Link>
              </p>
            </div>
            <Button type="primary" onClick={goToChat} icon={<MessageOutlined />} loading={chatLoading} shape="circle" />
          </div>

          <div className={style.subjectDescription}>
            <ScheduleDescription
              serviceSlug={dict.atendimento.singular}
              teacherAvatar={subject.teacher.avatar}
              teacherName={subject.teacher.name}
              title={subject.name}
              value={formatCurrency(subject.costPerHour)}
            />
          </div>

          {hasBlockingDebts && (
            <Alert
              type="error"
              message={
                <>
                  Você tem débitos pendentes, acerte-os antes de agendar um novo atendimento.
                  <br />
                  {<Link to={`/carteira?tab=2`}>Clique aqui para ir ao perfil</Link>}
                </>
              }
            />
          )}
          <StudentIncompleteProfile />
          <br />

          {scheduleAvailable && !hasBlockingDebts && <ScheduleForm scheduleCallback={scheduleListOfClasses}></ScheduleForm>}
        </>
      )}
    </div>
  );

  async function loadSubject() {
    try {
      const { id } = params;
      const { data, error } = await studentSubjectClient.findById(id);
      const { data: studentData } = await studentClient.findById(auth.user.id);

      var completeProfile = true;

      if (!studentData?.birthDate || !studentData?.cpf || !studentData?.name || !studentData?.phone) {
        completeProfile = false;
      }

      if (!studentData?.address?.id) {
        completeProfile = false;
      }

      if (!studentData?.paymentMethod) {
        completeProfile = false;
      }

      if (!studentData?.emailConfirmed) {
        completeProfile = false;
      }

      setScheduleAvailable(completeProfile);

      const { data: dataDebts } = await studentClient.getDebts(auth.user.id);
      setHasBlockingDebts(!!dataDebts.blockingDebts.length);

      if (error) {
        return;
      }

      setSubject(data);
    } finally {
      setLoading(false);
    }
  }

  async function goToChat() {
    setChatLoading(true);

    try {
      const companyId = getCompanyId();

      const { results } = await messageClient.getChats({
        studentId: auth.user.id,
        teacherId: subject?.teacherId,
        companyId: parseInt(companyId),
      });

      if (results.length > 0) {
        history.push(`/mensagens?teacherId=${subject?.teacherId}`);
        return;
      }

      const { error } = await messageClient.createChat({
        studentId: auth.user.id,
        teacherId: subject?.teacherId,
        companyId: parseInt(companyId),
      });

      if (!error) {
        history.push(`/mensagens?teacherId=${subject?.teacherId}`);
        return;
      }

      message.error("Oops, não foi possível iniciar uma conversa. Por favor, tente novamente mais tarde");
    } finally {
      setChatLoading(false);
    }
  }

  function goBack() {
    history.goBack();
  }

  function calculateCost(quantityOfClasses: number) {
    const totalCostWithoutPackage = quantityOfClasses * Number(subject?.costPerHour || 0);

    const totalCostWidthDiscount =
      quantityOfClasses >= Number(subject?.minHoursForDiscount || 0) ? totalCostWithoutPackage * (1 - Number(subject?.discountPercentage || 0) / 100) : totalCostWithoutPackage;

    return totalCostWidthDiscount;
  }

  async function scheduleSingleClass(datesToSchedule: Date, costOfClass: number) {
    const payload: Partial<IClass> = {
      teacherId: subject?.teacherId,
      subjectId: subject?.id,
      students: [
        {
          name: auth.user.name,
          email: auth.user.email,
          id: auth.user.id,
        },
      ],
      costPerStudent: costOfClass,
      scheduledDuration: 1,
      companyId: getCompanyId(),
      numberOfClasses: 1,
      scheduledOnUtc: datesToSchedule,
    };

    try {
      const { data } = await studentClassClient.schedule({
        ...payload,
      });

      const { message: errorMessage, students: blockingStudents } = data || {}; //Note: blocking student is deprecable cause it was part of the old multi student per class logic, migth be usefull some day

      if (errorMessage) {
        message.error(errorMessage);
        return;
      }

      message.success("Agendamento realizado. Aguarde a confirmação do profissional");
      history.push("/");
    } catch (err) {
      message.error("Falha ao realizar agendamento");
    } finally {
      setLoading(false);
    }
  }

  async function scheduleListOfClasses(datesToSchedule: Date[]) {
    if (datesToSchedule?.length > 0) {
      const costOfClass = calculateCost(datesToSchedule.length);

      await Promise.all(datesToSchedule.map(async (date) => await scheduleSingleClass(date, costOfClass)));

      //datesToSchedule.forEach(async (date) => await scheduleSingleClass(date, costOfClass));
    } else {
      message.error("Selecione ao menos um horário!");
    }
  }
};

export default SubjectPage;
