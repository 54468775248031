import { SaveOutlined } from "@ant-design/icons";
import { Col, Form, Input, message, Row } from "antd";
import MaskedInput from "antd-mask-input";
import { Store } from "antd/lib/form/interface";
import * as React from "react";
import { useState } from "react";
import { tutorClient } from "../../clients/tutor/TutorClient";
import Button from "../../components/Button";
import { useAuth } from "../../hooks/AuthHook";
import { removeNonNumbers } from "../../utils/StrUtils";
import { format, parse } from "date-fns";

interface IProfileForm {
  afterSave: () => void;
  tutor: any;
}

const ProfileForm: React.FC<IProfileForm> = ({
  afterSave, tutor
}) => {

  const auth = useAuth();
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  form.setFieldsValue(tutor);

  return (
    <Form form={form} layout="vertical" onFinish={save}>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Nome"
            rules={[
              {
                required: true,
                message: "Nome é obrigatório",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phone"
            label="WhatsApp"
            rules={[
              {
                required: true,
                message: "Celular é obrigatório",
              },
            ]}
          >
            <MaskedInput mask="(11) 11111-1111" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                required: true,
                message: "E-mail é obrigatório",
              },
              {
                type: "email",
                message: "Digite um e-mail válido",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="password"
            label="Senha"
          >
            <Input type="password" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={12}>
          <Form.Item name="birthDate" label="Data de Nascimento">
            <MaskedInput mask="11/11/1111" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="degree" label="Grau de Formação">
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item name="certificates" label="Certificados e Diplomas">
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item name="bio" label="Biografia">
            <Input.TextArea rows={3} />
          </Form.Item>
        </Col>
      </Row>

      <Button
        icon={<SaveOutlined />}
        type="primary"
        htmlType="submit"
        loading={saveLoading}
      >
        Salvar
      </Button>
    </Form>
  );

  async function save(values: Store) {
    setSaveLoading(true);

    try {
      const response = await tutorClient.update(auth.user.id, {
        ...values,
        phone: '+55' + removeNonNumbers(values.phone),
        birthDate: values.birthDate ? format(parse(values.birthDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') : '',
      });

      if (response) {
        message.success("Salvo com sucesso");
      } else {
        message.error("Falha ao salvar o perfil");
      }
    } catch (err) {
      message.error(err?.response?.data?.error?.message || 'Falha ao salvar perfil');
    } finally {
      setSaveLoading(false);
      afterSave();
    }
  }
}

export default ProfileForm;
