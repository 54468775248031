import * as React from 'react';
import { useState } from 'react';
import { Input, message } from 'antd';
import { SendOutlined } from '@ant-design/icons';

import Button from '../Button';

import style from './NewMessageForm.module.scss';
import { messageClient } from '../../clients/message/MessageClient';
import { useAuth } from '../../hooks/AuthHook';
import { useLocation } from 'react-router-dom';

interface INewMessageForm {
  studentId: string;
  teacherId: string;
  chatId: string;
  afterSend: (first: Boolean) => void;
}

const NewMessageForm: React.FC<INewMessageForm> = ({
  chatId, studentId, teacherId, afterSend
}) => {

  const auth = useAuth();
  const location = useLocation();
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <div className={style.newMessageForm}>
      <Input.TextArea 
        placeholder='Mensagem'
        rows={1} 
        autoSize={true}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
      <Button
        type='primary'
        shape='circle' 
        icon={<SendOutlined />}  
        loading={loading}
        onClick={sendMessage}
      />
    </div>
  );

  async function sendMessage () {
    if (!body) {
      message.error('Digite uma mensagem.')
      return;
    }

    try {
      setLoading(true);

      const fromUserId = location.pathname.includes('profissional') ? teacherId : studentId
      const toUserId = location.pathname.includes('profissional') ? studentId : teacherId
      
      const { error } = await messageClient.sendMessage({
        fromUserId,
        toUserId,
        body,
        chatId
      });
  
      if (error) {
        message.error(error.message || 'Falha ao enviar mensagem.');
        return;
      }
  
      message.success('Mensagem enviada com sucesso.');
      afterSend(true);
      setBody('');
    } catch {
      message.error('Falha ao enviar mensagem.');
    } finally { 
      setLoading(false);
    }
  }

}

export default NewMessageForm;
