const hostName = window.location.hostname;

const CONFIG_ENV = ((hostName) => {
  if (hostName.includes('h.oori.me')) {
    return 'homolog';
  } else if (hostName.includes('localhost')) {
    return String(process.env.REACT_APP_CONFIG_ENV).toLocaleLowerCase();
  } else {
    return 'production';
  }


})(hostName);

const CONFIG = ((env: string) => {
  switch (env) {
    case 'production':
      return {
        API_URL: 'https://api.oori.me',
        COOKIE_DOMAIN: '.oori.me',
        PAGARME_KEY: 'ek_live_6AB040w1Sp9nq9jVLSxL3kdI0jsj6w',
        GOOGLE_CLIENT_ID: '135965753917-74f9qfjcn4kk2j54ndkmoi5f2mfhq7mk.apps.googleusercontent.com'
      };

    case 'homolog':
      return {
        API_URL: 'https://devapiv2.oori.me',
        COOKIE_DOMAIN: 'https://h.oori.me',
        PAGARME_KEY: 'ek_test_BfVelX0VagGJydiqLcmEUzzLW1SKdL',
        GOOGLE_CLIENT_ID: '97688389230-0pp380bue3o6ac0v9r128bca1ojl60hc.apps.googleusercontent.com'
      };

    default:
      // Dev or anything which is not Homolog or Prod
      return {
        API_URL: `http://localhost:3000/dev`,
        COOKIE_DOMAIN: '',
        PAGARME_KEY: 'ek_test_BfVelX0VagGJydiqLcmEUzzLW1SKdL',
        GOOGLE_CLIENT_ID: '97688389230-0pp380bue3o6ac0v9r128bca1ojl60hc.apps.googleusercontent.com'
      };
  }
})(CONFIG_ENV);

export const BASE_URL = CONFIG.API_URL;
export const PAGARME_KEY = CONFIG.PAGARME_KEY;
export const GOOGLE_CLIENT_ID = CONFIG.GOOGLE_CLIENT_ID;
