import { IStudent, StudentTransaction, RetryPayment } from "../../types/Student";
import { axiosClient } from "../axiosHandler";
import { Options, ResponseAPI } from "../axiosTypes";
import { BASE_URL } from "../configClient";

interface StudentFilter {
  email?: string;
  checkDebts?: Boolean;
}

interface IStudentClient {
  create(data: any): Promise<any>;
  login(email: string, password: string): Promise<any>;
  googleAuth(token: string): Promise<any>;
  recoverPassword(email: string): Promise<void>;
  resetPassword(email: string, password: string, resetPasswordToken: string): Promise<void>;
  confirmEmail(toke: string): Promise<void>;
  update(id: string, data: any): Promise<any>;
  remove(id: string): Promise<any>;
  findById(id: string): Promise<any>;
  findAll(filter?: StudentFilter): Promise<any>;
  findAllWithDebts(filter?: StudentFilter): Promise<any>;
  registerCard(card: IStudent): Promise<any>;
  updateCard(card: IStudent): Promise<any>;
  getTransactions(filter?: any): Promise<ResponseAPI<StudentTransaction>>;
  getDebts(id: string): Promise<ResponseAPI<any>>;
  retryPayment(retryPayment: RetryPayment): Promise<ResponseAPI<any>>;
  addAddress(id: string, address: any): Promise<any>;
  updateAddress(id: string, address: any): Promise<any>;
  getAddress(id: string): Promise<any>
}

const StudentClient = (baseURL: string, options?: Options): IStudentClient => {
  const client = axiosClient(baseURL, options);

  return {
    create,
    login,
    googleAuth,
    recoverPassword,
    resetPassword,
    confirmEmail,
    update,
    remove,
    findById,
    findAll,
    registerCard,
    updateCard,
    getTransactions,
    getDebts,
    retryPayment,
    addAddress,
    updateAddress,
    getAddress,
    findAllWithDebts
  };

  async function create(student: any): Promise<any> {

    if (student.email) {
      student.email = student.email.trim().toLowerCase()
    }

    const { data } = await client({
      method: "post",
      url: "/student",
      data: student,
    });

    return data;
  }

  async function login(email: string, password: string): Promise<any> {
    const { data } = await client({
      method: "post",
      url: "/student/login",
      data: {
        email: email ? email.trim().toLowerCase() : '',
        password,
      },
    });

    return data;
  }

  async function googleAuth(token: string): Promise<any> {
    const { data } = await client({
      method: "post",
      url: "/student/auth/google",
      data: {
        token
      },
    });

    return data;
  }

  async function recoverPassword(email: string): Promise<void> {
    await client({
      method: "post",
      url: "/student/recoverPassword",
      data: {
        email: email ? email.trim().toLowerCase() : null,
      },
    });
  }

  async function resetPassword(email: string, password: string, resetPasswordToken: string): Promise<void>{
    await client({
      method: "post",
      url: "/student/redefinePassword",
      data: {
        email: email ? email.trim().toLowerCase() : null,
        password,
        resetPasswordToken
      },
    });
  }

  async function confirmEmail(emailConfirmationToken: string): Promise<void>{
    await client({
      method: "post",
      url: "/student/confirmEmail",
      data: {
        emailConfirmationToken
      },
    });
  }

  async function registerCard(card: IStudent): Promise<any> {
    const { data } = await client({
      method: "post",
      url: "/creditCard",
      data: card,
    });

    return data;
  }

  async function updateCard(card: IStudent): Promise<any> {
    const { data } = await client({
      method: "put",
      url: "/creditCard",
      data: card,
    });

    return data;
  }

  async function update(id: string, student: any): Promise<any> {

    if (student.email) {
      student.email = student.email.trim().toLowerCase()
    }

    const { data } = await client({
      method: "put",
      url: "/student",
      data: {
        ...student,
        id,
      },
    });

    return data;
  }

  async function addAddress(id: string, address: any): Promise<any> {
    const { data } = await client({
      method: "post",
      url: "/address",
      data: {
        ...address,
        studentId: id,
      },
    });

    return data;
  }

  async function updateAddress(id: string, address: any): Promise<any> {
    const { data } = await client({
      method: "put",
      url: "/address",
      data: {
        ...address,
        studentId: id,
      },
    });

    return data;
  }

  async function getAddress(id: string): Promise<any> {
    const { data } = await client({
      method: "get",
      url: `/address`,
      params: {
        studentId: id
      }
    });

    return data;
  }

  async function remove(id: string): Promise<any> {
    const { data } = await client({
      method: "delete",
      url: `/student/${id}`,
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: "get",
      url: `/student/${id}`,
    });

    return data;
  }

  async function findAll(filter?: StudentFilter): Promise<any> {
    const { data } = await client({
      method: "get",
      url: "/student",
      params: {
        ...filter,
      },
    });

    return data;
  }

  async function findAllWithDebts(filter?: StudentFilter): Promise<any> {
    const { data } = await client({
      method: "get",
      url: "/student/checkDebts",
      params: {
        ...filter,
      },
    });

    return data;
  }

  async function getTransactions(filter?: any): Promise<ResponseAPI<StudentTransaction>> {
    const { data } = await client({
      method: 'get',
      url: `/transaction`,
      params: {
        ...filter
      }
    });

    return data;
  }

  async function getDebts(id: string): Promise<ResponseAPI<any>> {
    const { data } = await client({
      method: 'get',
      url: `/student/debts/${id}`,
    });

    return data;
  }

  async function retryPayment(retryPayment: RetryPayment): Promise<ResponseAPI<any>> {
    const { data } = await client({
      method: 'post',
      url: `/payment/retry`,
      data: {
        ...retryPayment
      }
    });

    return data;
  }
};

export const studentClient = StudentClient(BASE_URL);
