import * as React from 'react';
import {
  Avatar,
  Divider
} from 'antd';
import { addMinutes } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';

import defaultAvatar from "../../assets/defaultAvatar.jpeg";

import { IClass } from '../../types/Class';

import style from './ClassDetailHeader.module.scss';

interface IClassDetailHeader {
  currClass: IClass;
}

const ClassDetailHeader: React.FC<IClassDetailHeader> = ({
  currClass
}) => {

  const date = currClass.scheduledOnUtc;
  const formatedDate = formatToTimeZone(date, 'DD/MM/YY', { timeZone: 'America/Recife' });

const from = formatToTimeZone(date, 'HH:mm', { timeZone: 'America/Recife'});
  const to = formatToTimeZone(addMinutes(new Date(date).getTime(), currClass.scheduledDuration*60), 'HH:mm', { timeZone: 'America/Recife'});


  const className = currClass.subject?.name;
  const teacherAvatar = currClass.teacher?.profileImageUrl;

  return (
    <div className={style.classDetailHeader}>
      <div className={style.content}>
        <strong>{className}</strong>
        <span>{formatedDate}</span>
        <span>{from} às {to}</span>
      </div>
      <Divider />
      <Avatar src={teacherAvatar || defaultAvatar} size={window.innerWidth < 600 ? 48 : 64} />
    </div>
  );
}

export default ClassDetailHeader;
