import * as React from 'react';
import { useState, useEffect } from 'react';
import { Alert, message } from 'antd';

import { studentClient } from '../../clients/student/StudentClient';
import { useAuth } from '../../hooks/AuthHook';

import style from './StudentIncompleteProfile.module.scss';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict } from '../../utils/CompanyConfigUtils';
import { Link } from 'react-router-dom';

const StudentIncompleteProfile: React.FC<any> = () => {

  const auth = useAuth();
  const [missingBasicProfile, setMissingBasicProfile] = useState(false)
  const [missingAddress, setMissingAddress] = useState(false)
  const [missingPaymentInfo, setMissingPaymentInfo] = useState(false)
  const [missingEmailConfirmation, setMissingEmailConfirmation] = useState(false)
  const [displayIncompleteProfileBanner, setDisplayIncompleteProfileBanner] = useState(false)
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    loadStudent();
  //eslint-disable-next-line
  }, [])

  return (
    <div className={style.studentIncompleteProfile}>
      {displayIncompleteProfileBanner &&
      <>
        <Alert 
          type='warning'
          message={<>
            <div className={style.title}>Opa! Você ainda não completou seu perfil</div>
            <br />
            <div>Para poder agendar {dict.atendimento.plural} você precisa cumprir os todos os itens abaixo:</div>
            <div>
              <span className={!missingBasicProfile ? style.visible : style.hidden} role="img" aria-label="green check mark">✅ &nbsp;</span>
              {<Link to={`/perfil`}>Complete as informações básicas do seu perfil</Link>}
            </div>
            <div>
              <span className={!missingAddress ? style.visible : style.hidden} role="img" aria-label="green check mark">✅ &nbsp;</span>
              {<Link to={`/perfil?tab=2`}>Adicione um endereço</Link>}
            </div>
            <div>
              <span className={!missingPaymentInfo ? style.visible : style.hidden} role="img" aria-label="green check mark">✅ &nbsp;</span>
              {<Link to={`/carteira`}>Adicione um método de pagamento</Link>}
            </div>
            <div>
              <span className={!missingEmailConfirmation ? style.visible : style.hidden} role="img" aria-label="green check mark">✅ &nbsp;</span>
              Confirme seu e-mail
            </div>
            </>
          }
        />
      </>
      }
    </div>
  );

  async function loadStudent() {

    try {
      const studentRes = await studentClient.findById(auth?.user?.id)
  
      if (!studentRes?.data?.birthDate || !studentRes?.data?.cpf || !studentRes?.data?.name || !studentRes?.data?.phone) {
        setMissingBasicProfile(true)
        setDisplayIncompleteProfileBanner(true)
       }

      if (!studentRes?.data?.address?.id) {
        setMissingAddress(true)
        setDisplayIncompleteProfileBanner(true)
      }

      if (!studentRes?.data?.paymentMethod) {
        setMissingPaymentInfo(true)
        setDisplayIncompleteProfileBanner(true)
      }

      if (!studentRes?.data?.emailConfirmed) {
        setMissingEmailConfirmation(true)
        setDisplayIncompleteProfileBanner(true)
      }
      
    } catch (err) {
      console.error("Error fetching student", err);
      message.error("Ops, tivemos um erro ao carregar suas informações");
    }

  }
}

export default StudentIncompleteProfile;
