export type TransactionStatus = "processing" |  "authorized" | "paid" | "refunded" | "waiting_payment" | "pending_refund" | "refused" | "cancelled" | "error";

export const TransactionStatusLabel = {
  "processing": 'Processando',
  "authorized": 'Autorizado',
  "paid": 'Pago', 
  "refunded": 'Estornado',
  "waiting_payment": 'Aguardando Pagamento',
  "pending_refund": 'Estorno Pendente', 
  "refused": 'Recusado',
  "cancelled": 'Cancelado',
  "error": 'Falha na transação'
}

export const PaymentMethodLabel = {
  'boleto': 'Boleto',
  'credit_card': 'Cartão de Crédito',
}