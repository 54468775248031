import React, { useEffect, useRef, useState } from "react";

import style from "./PaymentMethod.module.scss";

interface IPaymentMethod {
  img: string;
  title: string;
  value: string;
  description: string;
  initialValue?: boolean;
  setPaymentMethod: (string) => void;
}

const PaymentMethod: React.FC<IPaymentMethod> = ({ img, title, value, description, initialValue = false, setPaymentMethod }) => {
  const radioRef = useRef<HTMLInputElement | null>(null);

  const handleCheckRadio = () => {
    if (radioRef.current) {
      radioRef.current.checked = true;
      setPaymentMethod(value);
    }
  };

  useEffect(() => {
    if (radioRef.current) {
      radioRef.current.checked = initialValue;
    }
  }, [initialValue]);

  return (
    <div className={style.card} onClick={handleCheckRadio} id={`${title}-payment-card`}>
      <div className={style.radio}>
        <input type="radio" name="payment" ref={radioRef} />
      </div>
      <div className={style.radio_text}>
        <span>
          <img src={img} alt="Payment Image" />
        </span>
        <div>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
