import * as React from 'react';

import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import HeaderNavigation from '../../components/HeaderNavigation';
import BackButton from '../../components/BackButton'; 

import style from './ForgotPassword.module.scss';

const ForgotPassword: React.FC = () => {
  return (
    <div>
      <div className={style.headerNavigation}>
        <BackButton />
        <HeaderNavigation  />
      </div>
      <div className={style.forgotPasswordPage}>
        <ForgotPasswordForm />
        <div className={style.footer} />
      </div>
    </div>
  );
}

export default ForgotPassword;