import { CameraOutlined } from "@ant-design/icons";
import { Avatar, Form, message, Skeleton, Upload, Tabs } from "antd";
import * as React from "react";
import queryString from "query-string";
import { useEffect, useState } from "react";
import defaultAvatar from "../../assets/defaultAvatar.jpeg";
import { tutorClient } from "../../clients/tutor/TutorClient";
import { Button } from "antd";
import { useAuth } from "../../hooks/AuthHook";
import style from "./TutorProfilePage.module.scss";
import ProfileForm from "../../componentsTutor/ProfileForm";
import PaymentForm from "../../componentsTutor/TutorPaymentForm";
import { ITeacher } from "../../types/Teacher";
import TutorBalance from "../../componentsTutor/TutorBalance";
import TutorTransactions from "../../componentsTutor/TutorTransactions";
import { format, parse } from "date-fns";
import Header from "../../components/Header";
import { BASE_URL } from "../../clients/configClient";
import { useLocation } from "react-router-dom";

const { TabPane } = Tabs;

const TutorProfilePage: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [teacher, setTeacher] = useState<ITeacher>();
  const [avatarUrl, setAvatarUrl] = useState();

  const { tab }: any = queryString.parse(location.search);

  // const secondaryColor = getSecondaryColor()
  // const primaryColor = getPrimaryColor()
  // const highlightColor = getHighlightColor()

  useEffect(() => {
    loadTeacher();
    // var bodyStyles = document.body.style;
    // bodyStyles.setProperty('--primary-color', primaryColor);
    // bodyStyles.setProperty('--secondary-color', secondaryColor);
    // bodyStyles.setProperty('--highlight-color', highlightColor);
    // eslint-disable-next-line
  }, []);

  const props = {
    name: "file",
    action: `${BASE_URL}/asset`,
    showUploadList: false,
    onChange: onChangePicture,
  };

  return (
    <div className={style.profile}>
      <Header />
      {loading && !teacher && <Skeleton active />}
      {!loading && teacher && (
        <>
          <Tabs type="card" size="small" defaultActiveKey={tab || "1"}>
            <TabPane tab="Perfil" key="1">
              <div className={style.avatarSection}>
                <Avatar size={64} src={avatarUrl || defaultAvatar} />
                <Upload {...props}>
                  <Button loading={loadingAvatar}>
                    <CameraOutlined /> Trocar foto
                  </Button>
                </Upload>
              </div>
              <ProfileForm afterSave={loadTeacher} tutor={teacher} />
            </TabPane>
            <TabPane tab="Dados de Pagamento" key="2">
              <PaymentForm afterSave={loadTeacher} teacher={teacher} />
            </TabPane>
            <TabPane tab="Saldo" key="3">
              <TutorBalance />
            </TabPane>
            <TabPane tab="Transações" key="4">
              <TutorTransactions />
            </TabPane>
          </Tabs>
        </>
      )}
    </div>
  );

  async function loadTeacher() {
    try {
      const { data, error } = await tutorClient.findById(auth.user.id);

      if (error) {
        message.error(error.message || "Falha ao carregar perfil");
        return;
      }

      setTeacher({
        ...data,
        bankAccount: data.bankAccount,
        phone: data.phone ? data.phone.slice(3) : '',
        birthDate: data.birthDate ? format(parse(data.birthDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '',
      });
      setAvatarUrl(data.profileImageUrl || "");
      form.setFieldsValue(data);
    } catch (err) {
      message.error(err?.response?.data?.error?.message || "Falha ao carregar perfil");
    } finally {
      setLoading(false);
    }
  }

  async function onChangePicture(info) {
    setLoadingAvatar(true);
    if (info.file.status === "done") {
      setAvatarUrl(info.file.response.data.url);
      const response = await tutorClient.update(auth.user.id, {
        profileImageUrl: info.file.response.data.url,
      });

      if (response) {
        message.success("Salvo com sucesso");
        setLoadingAvatar(false);
      } else {
        message.error("Falha ao salvar avatar");
        setLoadingAvatar(false);
      }
    } else if (info.file.status === "error") {
      message.error(`Falha ao fazer o upload.`);
      setLoadingAvatar(false);
    }
  }
};

export default TutorProfilePage;
