import { CalendarOutlined, ClockCircleOutlined, MessageOutlined, LeftCircleTwoTone, WarningOutlined, CheckOutlined } from "@ant-design/icons";
import { Alert, Col, Divider, Form, message, Row, Skeleton, Tabs, Result, Modal } from "antd";
import { differenceInMinutes, isBefore } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import * as React from "react";
import { useEffect, useState } from "react";
import Timer from "react-compound-timer";
import { useHistory, useParams, Link } from "react-router-dom";
import { messageClient } from "../../clients/message/MessageClient";
import { studentClassClient } from "../../clients/student/StudentClassClient";
import { tutorClassClient } from "../../clients/tutor/TutorClassClient";
import OoriButton from "../../components/Button";
import { Button } from 'antd'
import ClassDetailHeader from "../../components/ClassDetailHeader";
import { useAuth } from "../../hooks/AuthHook";
import { ClassStatus, IClass, UserStatus } from "../../types/Class";
import { capitalize } from '../../utils/StrUtils'
import { CompanyDict } from "../../types/CompanyDict";
import style from "./ClassDetail.module.scss";
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from "date-fns/locale";
import { getCompanyDict, getCompanyId, getSecondaryColor } from '../../utils/CompanyConfigUtils';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const ClassDetail: React.FC = () => {
  const auth = useAuth();
  const params = useParams<any>();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [cancelConfirmationLoading, setCancelConfirmationLoading] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [currClass, setCurrClass] = useState<IClass>();
  const [cancelDisclaimer, setCancelDisclaimer] = useState('');
  const [cancelDisclaimerLoading, setCancelDisclaimerLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [showResheculeClass, setShowRescheduleClass] = useState(false);
  const [rescheduleClassLoading, setRescheduleClassLoading] = useState(false);
  const [checkStatusLoading, setCheckStatusLoading] = useState(false);
  const userStatus = (currClass?.usersStatus || []).find((userStatus: UserStatus) => userStatus.studentId === auth.user.id);
  const notAcceptedList = (currClass?.usersStatus || []).filter((userStatus: UserStatus) => !userStatus.statusId);
  const [classDate, setClassDate] = useState(new Date());
  const [classTime, setClassTime] = useState(new Date());
  const dict: CompanyDict = getCompanyDict()

  const handleClassDateChange = (date: any) => {
    setClassDate(date);
  };

  const handleClassTimeChange = (time: any) => {
    setClassTime(time);
  };

  useEffect(() => {
    loadClass();

    if (currClass?.statusId && currClass.statusId === ClassStatus.SCHEDULED && userStatus?.statusId) {
      const interval = setInterval(() => {
        if (notAcceptedList.length) {
          loadClass();
        }
      }, 5000);

      return () => clearInterval(interval);
    }

    return;

    // eslint-disable-next-line
  }, [currClass?.statusId, userStatus?.statusId]);

  const limitToSchedule = currClass?.limitToScheduleUtc
    ? currClass.limitToScheduleUtc
    : undefined;
  const formatedLimit = limitToSchedule
    ? formatToTimeZone(limitToSchedule, "DD/MM", {
      timeZone: "America/Recife",
    })
    : undefined;

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div className={style.classDetail}>
      <LeftCircleTwoTone
        twoToneColor={ getSecondaryColor() }
        style={{ fontSize: 24 }}
        className={style.goBackBtn}
        onClick={goBack}
      />
      {currClass && currClass.scheduledOnUtc && currClass.statusId !== ClassStatus.REFUSED && (
        <>
          {currClass && <ClassDetailHeader currClass={currClass} />}
          <div className={style.content}>
            {currClass.statusId === ClassStatus.ACTIVE && !userStatus?.statusId && (
              <Alert
                type='warning'
                message={'Você ainda não marcou presença!'}
              />
            )}
            {userStatus?.statusId && userStatus.statusId === ClassStatus.ACCEPTED && (
                  <Alert
                    type='success'
                    message={'Você marcou presença!'}
                  />
            )}
            {currClass.statusId === ClassStatus.CANCELED && (
              <Alert
                type='error'
                message={`O atendimento foi cancelado. 😢`}
              />
            )}
            {currClass.statusId === ClassStatus.SCHEDULED && (
              <Alert
                type='info'
                message={`O atendimento está confirmado mas não começou ainda. Quando o ${dict.profissional.singular} inciar o atendimento, você precisará marcar presença nessa mesma tela.`}
              />
            )}
            <br />
                <div className={style.actions}>
                  <OoriButton
                    type='primary'
                    icon={<MessageOutlined />}
                    onClick={goToChat}
                    loading={chatLoading}
                  >Mensagem</OoriButton>
                  {currClass.statusId === ClassStatus.ACTIVE && !userStatus?.statusId && (
                    <Button
                      icon={<CheckOutlined />}
                      loading={startLoading}
                      onClick={startClass}
                    >
                      Marcar presença
                    </Button>
                  )}
                </div>

                <br />
                {currClass.statusId === ClassStatus.ACTIVE &&
                  <Result
                    status="success"
                    title="O atendimento está rolando 📚"
                    subTitle={
                      <>
                        <p>Atendimento iniciado às: {formatToTimeZone(currClass.startOnUtc, "HH:mm [em] DD/MM", { timeZone: "America/Recife", })}</p>
                        <p>
                          Duração do atendimento:{' '}
                          <Timer initialTime={new Date().getTime() - new Date(currClass.startOnUtc).getTime()}>
                            <Timer.Hours />h
                          <Timer.Minutes />m
                          <Timer.Seconds />s
                        </Timer>
                        </p>
                      </>
                    }
                  />
                }

                {currClass.statusId === ClassStatus.DONE &&
                  <Result
                    status="success"
                    title="Finalizado com sucesso! 🤙"
                    subTitle={
                      <>
                        <p>Início: {formatToTimeZone(currClass.startOnUtc, "HH:mm [em] DD/MM", { timeZone: "America/Recife", })}</p>
                        <p>Fim: {formatToTimeZone(currClass.endOnUtc, "HH:mm [em] DD/MM", { timeZone: "America/Recife", })}</p>
                        <Alert
                          type='info'
                          style={{ marginTop: '16px' }}
                          message={<>
                            Você pode ver a cobrança deste atendimento na seção de transações dentro do seu perfil.
                          <br />{<Link to={`/carteira?tab=2`}>Clique aqui para ver as transações</Link>}</>
                          }
                        />
                      </>
                    }
                  />
                }

                <p className="preFormatedParagraph">
                  {currClass?.subject.description}
                </p>
                <Divider orientation="left">{capitalize(dict.cliente.plural)}</Divider>
                <ul>
                  {currClass?.students.map((student) => (
                    <li style={{ marginBottom: '8px' }} key={student.id}><span>{student.name}</span>
                    {isPresent(student.id) && (
                      <span>: presente <span role="img" aria-label="green check mark">✅</span></span>
                    )}</li>
                    
                  ))}
                </ul>
              </div>
              {![ClassStatus.ACTIVE, ClassStatus.DONE, ClassStatus.CANCELED].includes(currClass.statusId) && (
                <>
                  <Divider />
                  {!cancelDisclaimer &&
                    <Row justify='center'>
                      <Button
                        onClick={getCancelClassDisclaimer}
                      >Cancelar {dict.atendimento.singular}</Button>
                    </Row>
                  }
                  {cancelDisclaimer &&
                    <>
                      {/* <Alert
                        type='warning'
                        message={`${cancelDisclaimer}`}
                      /> */}
                      <p>Você deseja reagendar ou cancelar este atendimento?</p>
                      <br />
                      <Row justify='space-around'>
                        <OoriButton
                          onClick={() => setShowRescheduleClass(true)}
                          type='primary'
                        >Reagendar</OoriButton>
                        <br /><br />
                        <Button
                          onClick={displayCancelConfirmationModal}
                          // loading={cancelLoading}
                        >Cancelar</Button>
                      </Row>
                    </>
                  }
                  
                  <Modal
                    title='Tem certeza que quer cancelar este atendimento?'
                    visible={showCancelConfirmationModal}
                    width={window.innerWidth < 600 ? '100%' : '50%'}
                    onOk={cancelClass}
                    onCancel={closeCancelConfirmationModal}
                    okText='Cancelar Atendimento'
                    cancelText='Voltar'
                    okButtonProps={{
                      loading: cancelLoading
                    }}
                  >
                    <p>Ao cancelar o atendimento você não poderá mais alterá-lo ou reagendá-lo.</p>
                  </Modal>
                </>
              )}

              {showResheculeClass &&
                <div className={style.rescheduleClass}>
                <p style={{ marginTop: '16px', marginBottom: '16px' }}>Selecione o dia e horário para qual você deseja reagendar seu atendimento</p>
                  <Form onFinish={rescheduleClass} layout='vertical'>
                    <Row gutter={8}>
                      <Col span={24}>
                        <Form.Item
                          label="Dia"
                          name="date"
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                            <KeyboardDatePicker
                              fullWidth
                              format="dd/MM/yyyy"
                              minDate={new Date()}
                              value={classDate}
                              onChange={handleClassDateChange}
                            />
                          </MuiPickersUtilsProvider>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Horário"
                          name="time"
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                              fullWidth
                              ampm={false}
                              value={classTime}
                              onChange={handleClassTimeChange}
                            />
                          </MuiPickersUtilsProvider>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Alert
                      type='info'
                      style={{ marginTop: '16px' }}
                      message={<>
                        Ao reagender o atendimento, será gerada uma nova solicitação para o {`${dict.profissional.singular}`}.
                      </>
                      }
                    />
                    <br />
                    <Button
                      icon={<CalendarOutlined />}
                      type="primary"
                      loading={rescheduleClassLoading}
                      htmlType="submit"
                    >
                      {" "}
                      Confirmar reagendamento{" "}
                    </Button>
                  </Form>
                </div>
              }
        </>
      )}
      {currClass && currClass.statusId === ClassStatus.REFUSED &&
        <div className={style.scheduleClass}>
          <WarningOutlined style={{ fontSize: 64, marginBottom: '24px' }} />
          <p>Solicitação de agendamento recusada</p>
          <p>Motivo: {currClass.refuseJustification}</p>
          <br />
          <p>Mas não se preocupe, você pode tentar regendar o atendimento:</p>
        <Form onFinish={save} style={{marginTop: '16px'}}>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item
                  label="Dia"
                  name="date"
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <KeyboardDatePicker
                      fullWidth
                      format="dd/MM/yyyy"
                      minDate={new Date()}
                      value={classDate}
                      onChange={handleClassDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Horário"
                  name="time"
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      fullWidth
                      ampm={false}
                      value={classTime}
                      onChange={handleClassTimeChange}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Item>
              </Col>
            </Row>
            <Button
            icon={<CalendarOutlined />}
            type="primary"
            loading={saveLoading}
            htmlType="submit"
            style={{marginTop: '16px'}}
            >
              {" "}
              Reagendar{" "}
            </Button>
          </Form>
        </div>
      }

      {!currClass?.scheduledOnUtc && (
        <div className={style.scheduleClass}>
          <ClockCircleOutlined style={{ fontSize: 64 }} />
          <p style={{ marginTop: '16px' }}>
            Você ainda não agendou este atendimento.
          </p>
          <p style={{ marginTop: '16px', marginBottom: '16px' }}>
            <strong>Agende seu atendimento agora:</strong>
          </p>

          <Form onFinish={save} layout='vertical'>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item
                  label="Dia"
                  name="date"
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <KeyboardDatePicker
                      fullWidth
                      format="dd/MM/yyyy"
                      minDate={new Date()}
                      value={classDate}
                      onChange={handleClassDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Horário"
                  name="time"
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      fullWidth
                      ampm={false}
                      value={classTime}
                      onChange={handleClassTimeChange}
                    />
                  </MuiPickersUtilsProvider>
                </Form.Item>
              </Col>
            </Row>
            <Button
              icon={<CalendarOutlined />}
              type="primary"
              loading={saveLoading}
              htmlType="submit"
            >
              {" "}
              Agendar{" "}
            </Button>
          </Form>

          {/* <Alert
            type="warning"
            message={`Você tem até o dia ${formatedLimit} para agendar.`}
          /> */}
        </div>
      )}
    </div>
  );

  // async function checkClassStatus() {
  //   const { id } = params;

  //   try {
  //     setCheckStatusLoading(true);
  //     const { data, error } = await studentClassClient.findById(id);

  //     if (error) {
  //       message.error(error.message || "Falha ao carregar");
  //       return;
  //     }

  //     if (data.statusId === ClassStatus.ACTIVE) {
  //       message.info(capitalize(dict.profissional.singular) + ' ainda não finalizou o atendimento, aguarde-o finalizar.', 5);
  //     }

  //     setCurrClass(data);
  //   } finally {
  //     setCheckStatusLoading(false);
  //   }
  // }

  function displayCancelConfirmationModal() {
    setShowCancelConfirmationModal(true)
  }

  function closeCancelConfirmationModal() {
    setShowCancelConfirmationModal(false)
  }

  async function loadClass() {
    const { id } = params;

    try {
      const { data, error } = await studentClassClient.findById(id);

      if (error) {
        message.error(error.message || "Falha ao carregar");
        return;
      }

      setCurrClass(data);
    } finally {
      setLoading(false);
    }
  }

  async function save() {
    const hour = classTime.getHours();
    const minute = classTime.getMinutes();
    const year = classDate.getFullYear();
    const month = classDate.getMonth();
    const day = classDate.getDate();

    const scheduledOnUtc = new Date(year, month, day, hour, minute);

    if (isBefore(new Date(year, month, day, hour, minute, 0, 0), new Date())) {
      message.error('O horário selecionado já passou');
      return;
    }

    if (differenceInMinutes(new Date(year, month, day, hour, minute, 0, 0), new Date()) < 15) {
      message.error('Por favor, agende seu atendimento com pelo menos 15 minutos de antecedência');
      return;
    }

    if (currClass) {
      setSaveLoading(true);

      try {
        const { error } = await studentClassClient.update(currClass.id, {
          scheduledOnUtc,
          statusId: ClassStatus.REQUESTED
        });

        if (error) {
          message.error(error.message || "Falha ao salvar");
          return;
        }

        message.success("Atendimento agendado com sucesso!");
        history.push(`/`);
      } finally {
        setSaveLoading(false);
      }
    }
  }

  async function getCancelClassDisclaimer() {

    // NOTE Deprecated code once used to get the cancellation fee from API.
    // Leaving it here because it might be required again in the future
    // setCancelDisclaimerLoading(true);
    // if (currClass) {
    //   try {
    //     const { data } = await studentClassClient.getCancelDisclaimer(currClass.id, auth.user.id);
    //     setCancelDisclaimer(`${data.message} ${data.action === 'fee' ? `(R$ ${data.amount})` : ''}`);
    //   } finally {
    //     setCancelDisclaimerLoading(false);
    //   }
    // }

    setCancelDisclaimer('mock')
    return true
  }

  async function cancelClass() {
    if (currClass) {
      setCancelLoading(true);
      try {
        const { error } = await studentClassClient.cancel(currClass.id, auth.user.id);
        if (error) {
          message.error(error.message || "Falha ao salvar");
          return;
        }

        message.success("Atendimento cancelado com sucesso!");
        setShowCancelConfirmationModal(false)
        loadClass();
      } catch (err) {
        console.log(err);
        message.error(err.message || "Falha ao salvar");
      } finally {
        setCancelLoading(false);
      }
    }
  }

  async function rescheduleClass() {
    const hour = classTime.getHours();
    const minute = classTime.getMinutes();
    const year = classDate.getFullYear();
    const month = classDate.getMonth();
    const day = classDate.getDate();

      const scheduledOnUtc = new Date(year, month, day, hour, minute);

      if (isBefore(new Date(year, month, day, hour, minute, 0, 0), new Date())) {
        message.error('O horário selecionado já passou');
        return;
      }

      if (differenceInMinutes(new Date(year, month, day, hour, minute, 0, 0), new Date()) < 15) {
        message.error('Por favor, agende seu atendimento com pelo menos 15 minutos de antecedência');
        return;
      }

      if (currClass) {
        setRescheduleClassLoading(true);

        try {
          const { error } = await studentClassClient.update(currClass.id, {
            scheduledOnUtc,
            statusId: ClassStatus.REQUESTED,
          });

          if (error) {
            message.error(error.message || "Falha ao salvar");
            return;
          }

          message.success("Atendimento reagendado com sucesso!");
          setShowRescheduleClass(false)
          loadClass();
        } catch (err) {
          console.log(err);
          message.error(err.message || "Falha ao salvar");
        } finally {
          setRescheduleClassLoading(false);
        }
      }
  }

  function goBack() {
    history.goBack();
  }

  async function startClass() {
    setStartLoading(true);

    if (currClass) {
      try {
        const { error } =
          auth.user.userType === "student"
            ? await studentClassClient.startClass(
              currClass.id,
              auth?.user?.id
            )
            : await tutorClassClient.startClass(
              currClass.id,
              auth?.user?.id
            );

        if (error) {
          message.error(error.message || "Falha ao iniciar " + dict.atendimento.singular);
          return;
        }
      } finally {
        loadClass();
        setStartLoading(false);
      }
    }

  }

  async function goToChat() {

    setChatLoading(true);

    try {
      const companyId = getCompanyId()

      const { results } = await messageClient.getChats({
        studentId: auth.user.id,
        teacherId: currClass?.subject.teacherId,
        companyId: parseInt(companyId)
      });

      if(results.length > 0) {
        history.push(`/mensagens?teacherId=${currClass?.subject.teacherId}`);
        return;
      }

      const { error } = await messageClient.createChat({
        studentId: auth.user.id,
        teacherId: currClass?.subject.teacherId,
        companyId: parseInt(companyId)
      });

      if (!error) {
        history.push(`/mensagens?teacherId=${currClass?.subject.teacherId}`);
        return;
      }

      message.error('Oops, não foi possível iniciar uma conversa. Por favor, tente novamente mais tarde');
    } finally {
      setChatLoading(false);
    }
  }

  function isPresent(studentId) {
    const studentStatusObj = (currClass?.usersStatus || []).find((user) => {
      return user.studentId == studentId
    })

    return studentStatusObj ? studentStatusObj.statusId == 20 : false
  }
};

export default ClassDetail;
