import * as React from 'react';
import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';

import { ITeacher } from '../../types/Teacher';
import { studentTeacherClient } from '../../clients/student/StudentTeacherClient';
import TeacherProfile from '../../componentsStudent/TeacherProfile';
import style from './TeacherProfilePage.module.scss';

const TeacherProfilePage: React.FC = () => {

  const params = useParams<any>();

  const [loading, setLoading] = useState(true);
  const [teacher, setTeacher] = useState<ITeacher>();

  useEffect(() => {
    loadTeacher();

    //eslint-disable-next-line
  }, []);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div className={style.teacherProfilePage}>
      {teacher && <TeacherProfile teacher={teacher} />}
    </div>
  );

  async function loadTeacher () {
    const { id } = params;

    try {
      const { data } = await studentTeacherClient.findById(id);
      setTeacher(data);
    } finally {
      setLoading(false);
    }



  }
}

export default TeacherProfilePage;
