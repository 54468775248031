import * as React from 'react';
import { useState, useEffect } from 'react';
import { Alert, message } from 'antd';

import { tutorClient } from '../../clients/tutor/TutorClient';
import { useAuth } from '../../hooks/AuthHook';

import style from './TutorIncompleteProfile.module.scss';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict } from '../../utils/CompanyConfigUtils';
import { Link } from 'react-router-dom';

const TutorIncompleteProfile: React.FC<any> = () => {

  const auth = useAuth();
  const [missingBasicProfile, setMissingBasicProfile] = useState(false)
  const [missingPaymentInfo, setMissingPaymentInfo] = useState(false)
  const [missingEmailConfirmation, setMissingEmailConfirmation] = useState(false)
  const [displayIncompleteProfileBanner, setDisplayIncompleteProfileBanner] = useState(false)
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    loadStudent();
  //eslint-disable-next-line
  }, [])

  return (
    <div className={style.tutorIncompleteProfile}>
      {displayIncompleteProfileBanner &&
      <>
        <Alert 
          type='warning'
          message={<>
            <div className={style.title}>Opa! Você ainda não completou seu perfil</div>
            <br />
            <div>Para poder cadastrar {dict.especialidade.plural} você precisa cumprir os todos os itens abaixo:</div>
            <div>
              <span className={!missingBasicProfile ? style.visible : style.hidden} role="img" aria-label="green check mark">✅ &nbsp;</span>
              {<Link to={`/profissional/perfil`}>Complete as informações básicas do seu perfil</Link>}
            </div>
            <div>
              <span className={!missingPaymentInfo ? style.visible : style.hidden} role="img" aria-label="green check mark">✅ &nbsp;</span>
              {<Link to={`/profissional/perfil?tab=2`}>Adicione seus dados bancários para recebimento</Link>}
            </div>
            <div>
              <span className={!missingEmailConfirmation ? style.visible : style.hidden} role="img" aria-label="green check mark">✅ &nbsp;</span>
              Confirme seu e-mail
            </div>
            </>
          }
        />
      </>
      }
    </div>
  );

  async function loadStudent() {

    try {
      const teacherRes = await tutorClient.findById(auth?.user?.id)
  
      if (!teacherRes?.data?.name || !teacherRes?.data?.phone) {
        setMissingBasicProfile(true)
        setDisplayIncompleteProfileBanner(true)
       }

      if (!teacherRes?.data?.pagarmeRecipientId) {
        setMissingPaymentInfo(true)
        setDisplayIncompleteProfileBanner(true)
      }

      if (!teacherRes?.data?.emailConfirmed) {
        setMissingEmailConfirmation(true)
        setDisplayIncompleteProfileBanner(true)
      }
      
    } catch (err) {
      console.error("Error fetching teacher", err);
      message.error("Ops, tivemos um erro ao carregar suas informações");
    }

  }
}

export default TutorIncompleteProfile;
