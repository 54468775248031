import * as React from 'react';
import {
  Switch,
  Route,
  RouteProps
} from 'react-router-dom';
import { Redirect, useLocation } from 'react-router';
import { ConfigProvider, Layout } from 'antd';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/locale/pt_BR';
import { AuthProvider, useAuth } from './hooks/AuthHook';
import { setUpCompanyTheme, getPrimaryColor, getSecondaryColor, getHighlightColor, getBtnTextColor, getCompanyDict } from './utils/CompanyConfigUtils';
import { capitalize } from './utils/StrUtils';

import {
  BarChartOutlined,
  BookOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  IdcardOutlined,
  MessageOutlined,
  TagOutlined,
  WalletOutlined,
  SettingOutlined
} from '@ant-design/icons'

//--> Common pages
import Login from './pages/Login';
import TutorLogin from './pages/TutorLogin';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import TutorForgotPassword from './pages/TutorForgotPassword';
import ResetPassword from './pages/ResetPassword';
import TutorResetPassword from './pages/ResetPassword';
import ConfirmPassword from './pages/ConfirmEmail'

// import ChatPage from './pages/ChatPage';

//--> User pages
import Home from './pagesUser/Home';

import ClassesPage from './pagesStudent/ClassesPage';
import SubjectListPage from './pagesStudent/SubjectListPage';
import SubjectPage from './pagesStudent/SubjectPage';
import StudentMessagesPage from './pagesStudent/StudentMessagesPage';
import StudentProfilePage from './pagesStudent/StudentProfilePage';
import StudentWalletPage from './pagesStudent/StudentWalletPage';
import StudentClassDetailPage from './pagesStudent/ClassDetail';
import TeacherProfilePage from './pagesStudent/TeacherProfilePage';

//--> Tutor pages
import TutorSubjectListPage from './pagesTutor/TutorSubjectListPage';
import TutorHomePage from './pagesTutor/Home';
import TutorClassListPage from './pagesTutor/TutorClassListPage';
import TutorMessagesPage from './pagesTutor/TutorMessagesPage';
import TutorProfilePage from './pagesTutor/TutorProfilePage';
import TutorClassDetailPage from './pagesTutor/ClassDetail';

//--> Admin pages
import AdminHomePage from './pagesAdmin/Home';
import AdminLoginPage from './pagesAdmin/AdminLoginPage';
import AdminTutorListPage from './pagesAdmin/AdminTutorListPage';
import AdminStudentListPage from './pagesAdmin/AdminStudentListPage';
import AdminClassListPage from './pagesAdmin/AdminClassListPage';
import AdminClassDetailPage from './pagesAdmin/ClassDetail';
import AdminThemeSettingsPage from './pagesAdmin/AdminThemeSettingsPage';
import AdminWalletPage from './pagesAdmin/AdminWalletPage';

import Menu from './components/Menu';
import ConfirmEmail from './pages/ConfirmEmail';
import LoadingOverlay from './components/LoadingOverlay';
// import { useEffect } from 'react';

moment.locale('pt-br');

const { 
  Content
} = Layout;

const LoggedIn: React.FC<any> = ({ children }) => {
  // const auth = useAuth();
  // const location = useLocation();

  // const login = location.pathname.includes('profissional')
  //   ? 'profissional/login'
  //   : location.pathname.includes('admin') 
  //     ? 'admin/login'
  //     : 'login';

  // if (!auth.authToken) {
  //   return (
  //     <Redirect
  //       to={
  //         `/${login}?redirectTo=` + encodeURIComponent(location.pathname)
  //       }
  //     />
  //   );
  // }
  return children;
};

interface  ILoggedInRoute extends RouteProps {
  component?: any 
}

const LoggedInRoute: React.FC<ILoggedInRoute> = ({
  component: Component,
  ...rest
}) => {

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          // <LoggedIn {...props}>
            <Component />
          // </LoggedIn>
        )
      }}
    />
  ) as any;
}
  
const StudentRoute: React.FC<{ component?: any }> = ({
  component: Component,
}) => {

  const location = useLocation();
  const auth = useAuth();
  const dict = getCompanyDict();

  const menuList = [
    {icon: <HomeOutlined />, title: 'Home', link: '/', key: '1'},
    {icon: <FolderOpenOutlined />, title: capitalize(dict.especialidade.plural), link: '/busca', key: '2'},
    {icon: <BookOutlined />, title: capitalize(dict.atendimento.plural), link: '/atendimentos', key: '3'},
    {icon: <MessageOutlined />, title: 'Mensagens', link: '/mensagens', key: '4'},
    {icon: <WalletOutlined />, title: 'Carteira', link: '/carteira', key: '5'},
  ];

  if (auth?.user?.userType === 'student') {
    return (
      <Layout style={{ minHeight: '100%' }}>
        <Menu menuList={menuList}/>
        <Layout className="layout">
          <Content
            style={{
              background: '#fff',
            }}
          >
              <Component />
          </Content>
        </Layout>
      </Layout>
    )
  } else {
    return <Redirect to={`/login?redirectTo=${encodeURIComponent(location.pathname)}`} />
  }

}

const TutorRoute: React.FC<{ component?: any }> = ({
  component: Component,
  ...rest
}) => {

  const auth = useAuth();
  const location = useLocation();
  const dict = getCompanyDict();

  const menuList = [
    {icon: <HomeOutlined />, title: 'Home', link: '/profissional', key: '1'},
    {icon: <BookOutlined />, title: capitalize(dict.atendimento.plural), link: '/profissional/atendimentos', key: '2'},
    {icon: <FolderOpenOutlined />, title: capitalize(dict.especialidade.plural), link: '/profissional/especialidades', key: '3'},
    {icon: <MessageOutlined />, title: 'Mensagens', link: '/profissional/mensagens', key: '4'},
  ];

  if (auth?.user?.userType === 'teacher') {
    return (
      <Layout style={{ minHeight: '100%' }}>
        <Menu menuList={menuList}/>
        <Layout className="layout">
          <Content
            style={{
              background: '#fff',
            }}
          >
              <Component />
          </Content>
        </Layout>
      </Layout>
    );
  } else {
    return <Redirect to={`/profissional/login?redirectTo=${encodeURIComponent(location.pathname)}`} />
  }

}

const AdminRoute: React.FC<RouteProps & { component?: any }> = ({
  component: Component,
  ...rest
}) => {

  const auth = useAuth();
  const location = useLocation();
  const dict = getCompanyDict()


  const menuList = [
    {icon: <BarChartOutlined />, title: 'Dashboard', link: '/admin', key: '1'},
    {icon: <IdcardOutlined />, title: capitalize(dict.profissional.plural), link: '/admin/profissionais', key: '2'},
    {icon: <BookOutlined />, title: capitalize(dict.atendimento.plural), link: '/admin/atendimentos', key: '4'},    
    {icon: <SettingOutlined />, title: 'Personalização', link: '/admin/personalizacao', key: '6'},
    {icon: <WalletOutlined />, title: 'Carteira', link: '/admin/carteira', key: '7'},
  ];

  if (auth?.user?.userType === 'admin') {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout style={{ minHeight: '100%' }}>
            <Menu menuList={menuList} />
            <Layout className="layout">
              <Content
                style={{
                  background: '#fff',
                }}
              >
                  <Component {...props} />
              </Content>
            </Layout>
          </Layout>
        )}
      />
    );
  } else {
    return <Redirect to={`/admin/login?redirectTo=${encodeURIComponent(location.pathname)}`} />
  }
}

const StudentHome: React.FC = () => (<StudentRoute component={Home} />);
const StudentClasses: React.FC = () => (<StudentRoute component={ClassesPage} />);
const StudentSubjectSearch: React.FC = () => (<StudentRoute component={SubjectListPage} />);
const StudentSubjectPage: React.FC = () => (<StudentRoute component={SubjectPage} />);
const StudentClassDetail: React.FC = () => (<StudentRoute component={StudentClassDetailPage} />);
const StudentMessages: React.FC = () => (<StudentRoute component={StudentMessagesPage} />);
const StudentProfile: React.FC = () => (<StudentRoute component={StudentProfilePage} />);
const StudentWallet: React.FC = () => (<StudentRoute component={StudentWalletPage} />);
const StudentTeacherProfile: React.FC = () => (<StudentRoute component={TeacherProfilePage} />);


const TutorHome: React.FC = () => (<TutorRoute component={TutorHomePage} />);
const TutorSubjectList: React.FC = () => (<TutorRoute component={TutorSubjectListPage} />);
const TutorMessages: React.FC = () => (<TutorRoute component={TutorMessagesPage} />);
const TutorClassDetail: React.FC = () => (<TutorRoute component={TutorClassDetailPage} />);
const TutorProfile: React.FC = () => (<TutorRoute component={TutorProfilePage} />);
const TutorClassList: React.FC = () => (<TutorRoute component={TutorClassListPage} />);

const AdminHome: React.FC = () => (<AdminRoute component={AdminHomePage} />);
const AdminTutorList: React.FC = () => (<AdminRoute component={AdminTutorListPage} />);
const AdminStudentList: React.FC = () => (<AdminRoute component={AdminStudentListPage} />);
const AdminClassList: React.FC = () => (<AdminRoute component={AdminClassListPage} />);
const AdminClassDetail: React.FC = () => (<AdminRoute component={AdminClassDetailPage} />);
const AdminThemeSettings: React.FC = () => (<AdminRoute component={AdminThemeSettingsPage} />);
const AdminWallet: React.FC = () => (<AdminRoute component={AdminWalletPage} />);

function App() {

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function getCompanyTheme() {

      await setUpCompanyTheme()
  
      const bodyStyles = document.body.style
      const primaryColor = getPrimaryColor()
      const btnTextColor = getBtnTextColor()
      const secondaryColor = getSecondaryColor()
      const highlightColor = getHighlightColor()
  
      bodyStyles.setProperty('--primary-color', primaryColor)
      bodyStyles.setProperty('--btn-text-color', btnTextColor)
      bodyStyles.setProperty('--secondary-color', secondaryColor)
      bodyStyles.setProperty('--highlight-color', highlightColor)

      setLoading(false)
    }

    getCompanyTheme()
  //eslint-disable-next-line
  }, []);

  return (
    <div>
      {loading &&
      <>
        <LoadingOverlay />
      </>
    }
    { !loading &&
      <ConfigProvider locale={locale}>
      <Switch>

          <Route path="/profissional/login" exact component={() => <AuthProvider authToken="oori_teacher_token">
            <TutorLogin/>
            </AuthProvider>} />
          <Route path="/profissional/recuperar-senha" exact component={() => <AuthProvider authToken="oori_teacher_token">
            <TutorForgotPassword/>
            </AuthProvider>} />
          <Route path="/profissional/redefinir-senha" exact component={() => <AuthProvider authToken="oori_teacher_token">
            <TutorResetPassword/>
            </AuthProvider>} />
          <Route path="/profissional/confirmar-email" exact component={() => <AuthProvider authToken="oori_teacher_token">
            <ConfirmEmail/>
            </AuthProvider>} />
        
          <LoggedInRoute path="/profissional" exact component={() => <AuthProvider authToken="oori_teacher_token">
            <TutorHome/>
            </AuthProvider>} />
          <LoggedInRoute path="/profissional/especialidades" exact component={() => <AuthProvider authToken="oori_teacher_token">
            <TutorSubjectList/>
            </AuthProvider>} />
          <LoggedInRoute path="/profissional/atendimentos" exact component={() => <AuthProvider authToken="oori_teacher_token">
            <TutorClassList/>
            </AuthProvider>} />
          <LoggedInRoute path="/profissional/atendimento/:id" exact component={() => <AuthProvider authToken="oori_teacher_token">
            <TutorClassDetail/>
            </AuthProvider>} />
          <LoggedInRoute path="/profissional/mensagens" exact component={() => <AuthProvider authToken="oori_teacher_token">
            <TutorMessages/>
            </AuthProvider>} />
          <LoggedInRoute path="/profissional/perfil" exact component={() => <AuthProvider authToken="oori_teacher_token">
            <TutorProfile/>
            </AuthProvider>} />

          <Route path="/login" exact component={() => <AuthProvider authToken="oori_student_token">
            <Login />
          </AuthProvider>} />
          <Route path="/cadastro" exact component={() => <AuthProvider authToken="oori_student_token">
            <Register />
          </AuthProvider>} />
          <Route path="/recuperar-senha" exact component={() => <AuthProvider authToken="oori_student_token">
            <ForgotPassword />
          </AuthProvider>} />
          <Route path="/redefinir-senha" exact component={() => <AuthProvider authToken="oori_student_token">
            <ResetPassword />
          </AuthProvider>} />
          <Route path="/confirmar-email" exact component={() => <AuthProvider authToken="oori_student_token">
            <ConfirmEmail />
          </AuthProvider>} />    

          <LoggedInRoute 
            path="/" exact
            component={() => <AuthProvider authToken="oori_student_token"><StudentHome /></AuthProvider>}
          />
          <LoggedInRoute 
            path="/atendimentos" exact
            component={() => <AuthProvider authToken="oori_student_token"><StudentClasses /></AuthProvider>}
          />
          <LoggedInRoute 
            path="/busca" exact
            component={() => <AuthProvider authToken="oori_student_token"><StudentSubjectSearch /></AuthProvider>}
          />
          <LoggedInRoute 
            path="/cliente/especialidade/:id" exact
            component={() => <AuthProvider authToken="oori_student_token"><StudentSubjectPage /></AuthProvider>}
          />
          <LoggedInRoute 
            path="/atendimento/:id" exact
            component={() => <AuthProvider authToken="oori_student_token"><StudentClassDetail /></AuthProvider>}
          />
          <LoggedInRoute 
            path="/mensagens" exact
            component={() => <AuthProvider authToken="oori_student_token"><StudentMessages /></AuthProvider>}
          />
          <LoggedInRoute 
            path="/perfil" exact
            component={() => <AuthProvider authToken="oori_student_token"><StudentProfile /></AuthProvider>}
          />
          <LoggedInRoute 
            path="/carteira" exact
            component={() => <AuthProvider authToken="oori_student_token"><StudentWallet /></AuthProvider>}
          />
          <LoggedInRoute 
            path="/profissional/:id" exact
            component={() => <AuthProvider authToken="oori_student_token"><StudentTeacherProfile /></AuthProvider>}
          />
            
          <Route path="/admin/login" exact component={() => <AuthProvider authToken="oori_admin_token"><AdminLoginPage /></AuthProvider>} />

          <LoggedInRoute path="/admin" exact component={() => <AuthProvider authToken="oori_admin_token"><AdminHome /></AuthProvider>} />
          <LoggedInRoute path="/admin/profissionais" exact component={() => <AuthProvider authToken="oori_admin_token"><AdminTutorList /></AuthProvider>} />
          <LoggedInRoute path="/admin/clientes" exact component={() => <AuthProvider authToken="oori_admin_token"><AdminStudentList /></AuthProvider>} />
          <LoggedInRoute path="/admin/atendimentos" exact component={() => <AuthProvider authToken="oori_admin_token"><AdminClassList /></AuthProvider>} />
          <LoggedInRoute path="/admin/atendimento/:id" exact component={() => <AuthProvider authToken="oori_admin_token"><AdminClassDetail /></AuthProvider>} />
          <LoggedInRoute path="/admin/personalizacao" exact component={() => <AuthProvider authToken="oori_admin_token"><AdminThemeSettings /></AuthProvider>} />
          <LoggedInRoute path="/admin/carteira" exact component={() => <AuthProvider authToken="oori_admin_token"><AdminWallet /></AuthProvider>} />

        <Route path="*">
            <Redirect to="/" />
        </Route>
      </Switch>
    </ConfigProvider>
      
    }  
    </div>
  );
}

export default App;
