import { extractSubdomain } from "./StrUtils"

const subdomain = extractSubdomain(window.location.host)
const companyStorageKey = `${subdomain}/company`

export function setCompany(company: any) {
  
  if(company != null){
    localStorage.setItem(companyStorageKey, JSON.stringify(company))
  }
}

export function deleteCompany(){
  localStorage.removeItem(companyStorageKey)
}

export function getCompany(): string | null{
  return localStorage.getItem(companyStorageKey)
}
