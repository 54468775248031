
import { useState } from 'react';

/* From: https://github.com/devhammed/use-cookie/blob/master/src/index.tsx */
export const useCookie = (
  key: string,
  defaultValue?: any
): [any, (key: string, value: any, options?: object) => void, () => void] => {

  const [value, setValue] = useState(() => {
    const match = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    const value = match ? match[2] : defaultValue;
    try {
      return JSON.parse(value);
    } catch (_) {
      return value;
    }
  });

  const setCookie = (
    key: string,
    value: any,
    options?: { [key: string]: any }
  ): void => {

    const cookieOptions = {
      expires: 0,
      domain: '',
      path: '',
      secure: false,
      httpOnly: false,
      maxAge: 0,
      sameSite: '',
      ...options,
    };
    setValue(value);

    // if value is an array or a plain object, convert to JSON
    if (
      Array.isArray(value) ||
      Object.prototype.toString.call(value) === '[object Object]'
    ) {
      value = JSON.stringify(value);
    }
    let cookie: string = `${key}=${value}`;
    if (cookieOptions.expires) {
      cookie += `; Expires=${new Date(cookieOptions.expires).toUTCString()}`;
    }
    if (cookieOptions.path) {
      cookie += `; Path=${cookieOptions.path}`;
    }
    if (cookieOptions.domain) {
      cookie += `; Domain=${cookieOptions.domain}`;
    }
    if (cookieOptions.maxAge) {
      cookie += `; Max-Age=${cookieOptions.maxAge}`;
    }
    if (cookieOptions.sameSite) {
      cookie += `; SameSite=${cookieOptions.sameSite}`;
    }
    if (cookieOptions.secure) {
      cookie += '; Secure';
    }
    if (cookieOptions.httpOnly) {
      cookie += '; HttpOnly';
    }
    document.cookie = cookie;
  };

  const clearCookie = () => {
    setCookie(key, '', { expires: -3600 });
    setValue(defaultValue);
  };

  return [value, setCookie, clearCookie];
};
