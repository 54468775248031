import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Skeleton, Avatar, Alert } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import defaultAvatar from '../../assets/defaultAvatar.jpeg';
import { messageClient } from '../../clients/message/MessageClient';
import NewMessageForm from '../../components/NewMessageForm';
import { useAuth } from '../../hooks/AuthHook';
import { IMessage, IChat } from '../../types/Chat';

import style from './ChatPage.module.scss';
import { getFirstName } from '../../utils/StrUtils';
import { formatToTimeZone } from 'date-fns-timezone';

interface IChatPage {
  chat: IChat;
  closeChat: () => void
}

const ChatPage: React.FC<IChatPage> = ({
  chat,
  closeChat
}) => {

  const { id, studentId, teacherId, toUser } = chat;

  const auth = useAuth();
  const location = useLocation();
  const msgListRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);

  useEffect(() => {

    setFirstLoading(true);
    loadMessages();

    const interval = setInterval(() => {
      if (!loading) {
        loadMessages();
      }
    }, 5000);

    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [id, studentId, teacherId]);

  return (
    <div className={style.chatPage}>
      {!firstLoading && 
        <div className={style.header} >
          <ArrowLeftOutlined onClick={closeChat} />
          <Avatar src={toUser.avatar || defaultAvatar} size={32} />  
          <h3><strong>{getFirstName(toUser.name)}</strong></h3>
        </div>
      }
      <div className={style.messages} ref={msgListRef}>
        {loading && firstLoading && <Skeleton active />}
        {!firstLoading && messages.map((msg: IMessage, index: number) => (
          <MessageBox 
            message={msg} 
            fromMe={auth.user.id === msg.fromUserId}
            key={index}
          />
        ))}
        {!firstLoading && !messages.length && 
          <Alert 
            message={`Envie a primeira mensagem para ${getFirstName(toUser.name)}`} 
            type='warning'
          />
        }
      </div>
      <div className={style.form}>
        <NewMessageForm 
          studentId={studentId}
          teacherId={teacherId}
          chatId={id}
          afterSend={loadMessages}
        />
      </div>
    </div>
  );

  async function loadMessages () {
    setLoading(true);

    const fromId = location.pathname.includes('profissional') ? teacherId : studentId

    const { results } = await messageClient.getMessages(id, { userId: fromId });

    setMessages(results
      .filter((msg: IMessage) => msg.body)
    );

    setLoading(false);
    setFirstLoading(false);
    if (msgListRef && msgListRef.current) {
      msgListRef.current.scrollTop = msgListRef.current.scrollHeight - msgListRef.current.clientHeight;
    }
  }
}

interface IMessageBox {
  message: IMessage;
  fromMe: boolean;
}

const MessageBox: React.FC<IMessageBox> = ({
  message,
  fromMe
}) => {

  const formatedDate = formatToTimeZone(new Date(message.createdAt), 'DD/MM [às] HH:mm', { timeZone: 'America/Recife' });

  return (
    <div className={fromMe ? [style.message, style.fromMe].join(' ') : style.message}>
      <p className='preFormatedParagraph'>{message.body}</p>
      <span>{formatedDate}</span>
    </div>
  );
}

export default ChatPage;
