import { axiosClient } from '../axiosHandler';
import { Options } from '../axiosTypes';
import { BASE_URL } from '../configClient';

interface IStudentTeacherClient {
  findById(id: string): Promise<any>;
}

const StudentTeacherClient = (
  baseURL: string,
  options?: Options
): IStudentTeacherClient => {

  const client = axiosClient(baseURL, options);

  return {
    findById
  };

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/teacher/${id}`,
    });

    return data;
  }


}

export const studentTeacherClient = StudentTeacherClient(BASE_URL);