import { ClockCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Divider, message, Result, Skeleton, Tabs } from "antd";
import { formatToTimeZone } from "date-fns-timezone";
import * as React from "react";
import { useEffect, useState } from "react";
import Timer from "react-compound-timer";
import { useHistory, useParams } from "react-router-dom";
import { studentClassClient } from "../../clients/student/StudentClassClient";
import ClassDetailHeader from "../../components/ClassDetailHeader";
import PostClassForm from "../../componentsTutor/PostClassForm";
import PreClassForm from "../../componentsTutor/PreClassForm";
import { useAuth } from "../../hooks/AuthHook";
import { ClassStatus, IClass, UserStatus } from "../../types/Class";
import { CompanyDict } from "../../types/CompanyDict";
import { getCompanyDict, getSecondaryColor } from "../../utils/CompanyConfigUtils";
import { capitalize } from "../../utils/StrUtils";
import style from "./ClassDetail.module.scss";
const { TabPane } = Tabs;

const ClassDetail: React.FC = () => {
  const auth = useAuth();
  const params = useParams<any>();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [currClass, setCurrClass] = useState<IClass>();
  const dict: CompanyDict = getCompanyDict()

  const userStatus = (currClass?.usersStatus || []).find((userStatus: UserStatus) => userStatus.userId === auth.user.id);
  useEffect(() => {
    loadClass();
  // eslint-disable-next-line
}, [currClass?.statusId, userStatus?.statusId]);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div className={style.classDetail}>
      <LeftCircleOutlined
        twoToneColor={ getSecondaryColor() }
        style={{ fontSize: 24 }}
        className={style.goBackBtn}
        onClick={goBack}
      />
      {currClass && currClass?.scheduledOnUtc && (
        <>
          {currClass && <ClassDetailHeader currClass={currClass} />}
          <Tabs type="card" size="small">
            <TabPane tab="Detalhes" key="1">
              <div className={style.content}>
                {currClass.statusId === ClassStatus.ACTIVE &&
                  <Result
                  status="success"
                  title="O atendimento está rolando :)"
                  subTitle={
                    <>
                      <p>Atendimento iniciado às: {formatToTimeZone(currClass.startOnUtc, "HH:mm [em] DD/MM", {timeZone: "America/Recife",})}</p>
                      <p>
                        Duração do atendimento:{' '}
                        <Timer initialTime={new Date().getTime() - currClass.startOnUtc.getTime()}>
                          <Timer.Hours />h
                          <Timer.Minutes />m
                          <Timer.Seconds />s
                        </Timer>
                      </p>
                    </>
                  }
                />
                }

                {currClass.statusId === ClassStatus.DONE &&
                  <Result
                  status="success"
                  title="O atendimento foi finalizado! 🤙"
                  subTitle={
                    <>
                      <p>Atendimento iniciado às: {formatToTimeZone(currClass.startOnUtc, "HH:mm [em] DD/MM", {timeZone: "America/Recife",})}</p>
                      <p>Atendimento finalizado às: {formatToTimeZone(currClass.endOnUtc, "HH:mm [em] DD/MM", {timeZone: "America/Recife",})}</p>
                    </>
                  }
                />
                }
                <p className="preFormatedParagraph">
                  {currClass?.subject.description}
                </p>
                <Divider orientation="left">{capitalize(dict.cliente.plural)}</Divider>
                <ul>
                  {currClass?.students.map((student) => (
                    <li key={student.id}>
                      {student.name}
                    </li>
                  ))}
                </ul>
              </div>
            </TabPane>
            <TabPane tab="Pré-aula" key="2">
              <PreClassForm classId={currClass.id} preClass={currClass.preClass} />
            </TabPane>
            <TabPane tab="Pós-aula" key="3">
              <PostClassForm classId={currClass.id} postClass={currClass.postClass} />
            </TabPane>
          </Tabs>
        </>
      )}

      {!currClass?.scheduledOnUtc && (
        <div className={style.scheduleClass}>
          <ClockCircleOutlined style={{ fontSize: 64 }} />
          <p>
            {capitalize(dict.atendimento.singular)} não agendada <br />
          </p>
        </div>
      )}
    </div>
  );

  async function loadClass() {
    const { id } = params;

    try {
      const { data, error } = await studentClassClient.findById(id);

      if (error) {
        message.error(error.message || "Falha ao carregar");
        return;
      }

      setCurrClass(data);
    } finally {
      setLoading(false);
    }
  }

  function goBack() {
    history.goBack();
  }
};

export default ClassDetail;
