import * as React from 'react';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Form,
  Input,
  Divider,
  message
} from 'antd';
import { Store } from 'antd/lib/form/interface';

import { adminClient } from '../../clients/admin/AdminClient';
import { studentClient } from '../../clients/student/StudentClient';
import { tutorClient } from '../../clients/tutor/TutorClient';
import { GOOGLE_CLIENT_ID } from '../../clients/configClient';
import { GoogleLogin } from 'react-google-login';
import { useAuth } from '../../hooks/AuthHook';
import { getCompanyDict, getBtnTextColor, getLogoPath, getPrimaryColor } from '../../utils/CompanyConfigUtils'
import {Button} from 'antd';

import style from './LoginForm.module.scss';
import { CompanyDict } from '../../types/CompanyDict';

const LoginForm: React.FC = () => {

  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const logoPath = getLogoPath()
  const [loading, setLoading] = useState(false);
  const dict : CompanyDict = getCompanyDict()

  return (
    <Form
      onFinish={login}
      layout='vertical'
      className={style.logingForm}
    >
      <img src={logoPath} alt=''/>

      <Divider />
      {location.pathname.includes('profissional') &&
        <h2 style={{ marginBottom: '16px' }}>Portal de {dict.profissional.plural}</h2>
      }

      {location.pathname.includes('admin') &&
        <h2 style={{ marginBottom: '16px' }}>Portal de admin</h2>
      }
      <div className={style.loginFormContainer}>
        <h1><strong>Já tem conta?</strong></h1>
        <h2>Faça seu login</h2>
        <Form.Item
          label='E-mail'
          name='email'
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Senha'
          name='password'
        >
          <Input type='password' />
        </Form.Item>

        <Button
        style={{ marginBottom: '5px', 
        backgroundColor: getPrimaryColor(),
        borderColor: getPrimaryColor(),
        color: getBtnTextColor()
      }}
        type='primary'
        htmlType='submit'
        loading={loading}
      >Entrar</Button>

      {!location.pathname.includes('profissional') &&
        !location.pathname.includes('admin') &&
        <>
          <p style={{ marginBottom: '5px' }}>ou</p>
          <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              buttonText="Login com Google"
              onSuccess={googleAuth}
              onFailure={googleAuth}
              theme="dark"
              className="ct-button ct-button--secondary"
              cookiePolicy="single_host_origin"
          />
        </>
      }

      {!location.pathname.includes('profissional') && !location.pathname.includes('admin') &&
        <a style={{ color: getPrimaryColor() }} className={style.link} href='/recuperar-senha'>Esqueceu sua senha?</a>
      }
      {location.pathname.includes('profissional') &&
        <a style={{ color: getPrimaryColor() }} className={style.link} href='/profissional/recuperar-senha'>Esqueceu sua senha?</a>
      }
      </div>

      {!location.pathname.includes('profissional') &&
        !location.pathname.includes('admin') &&
        <>
        <div className={style.registerContainer}>
          <h1><strong>Ainda não tem conta?</strong></h1>
          <br />
          <Button 
          type='primary' 
          onClick={goToRegister}
          style={{
            backgroundColor: getPrimaryColor(),
            borderColor: getPrimaryColor(),
            color: getBtnTextColor()
          }}
          >Cadastre-se</Button>
          </div>
        
        </>
      }
    </Form>
  );

  function goToRegister () {
    history.push('/cadastro');
  }

  async function login (values: Store) {
    const { email, password } = values;

    setLoading(true);

    try {
      const { data } = location.pathname.includes('profissional')
       ? await tutorClient.login(email, password)
       : location.pathname.includes('admin')
         ? await adminClient.login(email, password)
         : await studentClient.login(email, password);

      if (data.token) {

        const token = location.pathname.includes('profissional')
        ? 'oori_teacher_token'
        : location.pathname.includes('admin')
          ? 'oori_admin_token'
          : 'oori_student_token';
        
        auth.signin(data.token, token);
        const redirectTo = new URLSearchParams(history.location.search).get('redirectTo');

        const pushTo = redirectTo
          ? redirectTo
          : location.pathname.includes('profissional')
            ? '/profissional'
            : location.pathname.includes('admin')
              ? '/admin'
              : '/';

        history.push(decodeURIComponent(pushTo));
        return;
      }
      message.error('Usuário e/ou senha errados.');
    } catch (err) {
      console.log(err);
      
      message.error('Usuário e/ou senha errados.');
    } finally {
      setLoading(false);
    }
  }

  async function googleAuth (values: Store){
    setLoading(true);

    try {
      const token = values.tokenId
      const { data } = await studentClient.googleAuth(token);

      auth.signin(data.token, 'oori_student_token');

      history.push(decodeURIComponent('/'));
    }catch(err) {
      message.error('Usuário e/ou senha errados.');
    } finally {
      setLoading(false);
    }
  }
}

export default LoginForm;
