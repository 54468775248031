import { Avatar, Tag } from "antd";
import { addMinutes } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import defaultAvatar from "../../assets/defaultAvatar.jpeg";
import { ClassStatusLabel, IClass, ClassStatus } from "../../types/Class";
import { CompanyDict } from "../../types/CompanyDict";
import { getCompanyDict } from "../../utils/CompanyConfigUtils";
import { getFirstName } from "../../utils/StrUtils";
import Button from "../Button";
import style from "./ClassCard.module.scss";

interface IClassCard {
  currClass: IClass;
  unscheduledClasses?: number;
}

const ClassCard: React.FC<IClassCard> = ({ currClass, unscheduledClasses }) => {
  const history = useHistory();
  const location = useLocation();
  const dict: CompanyDict = getCompanyDict();

  const date = currClass.scheduledOnUtc ? currClass.scheduledOnUtc : undefined;
  const formatedDate = date ? formatToTimeZone(date, "DD/MM/YY", { timeZone: "America/Recife" }) : undefined;

  const from = date ? formatToTimeZone(date, "HH:mm", { timeZone: "America/Recife" }) : undefined;
  const to = date
    ? formatToTimeZone(addMinutes(new Date(date).getTime(), currClass.scheduledDuration * 60), "HH:mm", {
        timeZone: "America/Recife",
      })
    : undefined;

  const limitToSchedule = currClass.limitToScheduleUtc ? currClass.limitToScheduleUtc : undefined;
  const formatedLimit = limitToSchedule
    ? formatToTimeZone(limitToSchedule, "DD/MM", {
        timeZone: "America/Recife",
      })
    : undefined;

  const className = currClass.subject?.name;
  const teacherName = getFirstName(currClass.teacher?.name);
  const teacherAvatar = currClass.teacher?.profileImageUrl;

  return (
    <div className={style.classCard}>
      <div className={style.content}>
        <div className={style.info}>
          <strong>
            {className} com {teacherName}
          </strong>
          {currClass.status !== ClassStatus.REFUSED && (
            <>
              {formatedDate && <span className={style.date}>{formatedDate}</span>}
              {from && to && (
                <span className={style.time}>
                  {from} às {to}
                </span>
              )}
              {!formatedDate && unscheduledClasses && (
                <span className={style.notScheduled}>
                  {unscheduledClasses > 1 ? `Existem ${unscheduledClasses} atendimentos não agendados` : `Existe um atendimento não agendado`}
                </span>
              )}
              {!formatedDate && formatedLimit && <span className={style.limitToSchedule}> Data limite para agendar: {formatedLimit}</span>}
            </>
          )}
          {formatedDate && (
            <span className={style.status}>
              <Tag color={getStatusColor()}>{ClassStatusLabel[currClass.statusId]}</Tag>
            </span>
          )}
          {currClass.status === ClassStatus.REFUSED && <p className={style.justification}>Justificativa: {currClass.refuseJustification}</p>}
        </div>
        <Avatar src={teacherAvatar || defaultAvatar} size={64} />
      </div>
      <div className={style.footerActions}>
        {currClass.status !== ClassStatus.REFUSED && (
          <Button type="primary" onClick={goToClass}>
            {unscheduledClasses ? `Agendar ` + dict.atendimento.singular : `Ver ` + dict.atendimento.singular}
          </Button>
        )}
        {currClass.status === ClassStatus.REFUSED && <Button onClick={goToClass}>Reagendar {dict.atendimento.singular}</Button>}
      </div>
    </div>
  );

  function goToClass() {
    if (location.pathname.includes("profissional")) {
      history.push(`/profissional/atendimento/${currClass.id}`);
      return;
    }

    if (location.pathname.includes("admin")) {
      history.push(`/admin/atendimento/${currClass.id}`);
      return;
    }

    history.push(`/atendimento/${currClass.id}`);
  }

  function getStatusColor() {
    const GREEN_STATUS = [ClassStatus.SCHEDULED];
    const BLUE_STATUS = [ClassStatus.ACTIVE, ClassStatus.DONE];
    const ORANGE_STATUS = [ClassStatus.REQUESTED];
    const RED_STATUS = [ClassStatus.REFUSED, ClassStatus.CANCELED];

    if (GREEN_STATUS.includes(currClass.statusId)) {
      return "green";
    }

    if (BLUE_STATUS.includes(currClass.statusId)) {
      return "blue";
    }

    if (ORANGE_STATUS.includes(currClass.statusId)) {
      return "orange";
    }

    if (RED_STATUS.includes(currClass.statusId)) {
      return "red";
    }

    return "";
  }
};

export default ClassCard;
