import { axiosClient } from '../axiosHandler';
import { Options, ResponseAPI } from '../axiosTypes';
import { BASE_URL } from '../configClient';
import { IPaymentInfo, Balance, TutorTransaction, BalanceOperation } from '../../types/Teacher';

interface ITutorClient {
  login(email: string, password: string): Promise<any>;
  findById(id: string): Promise<any>;
  recoverPassword(email: string): Promise<void>;
  resetPassword(email: string, password: string, resetPasswordToken: string): Promise<void>;
  confirmEmail(toke: string): Promise<void>;
  update(id: string, data: any): Promise<any>;
  setPaymentInfo(paymentInfo: Partial<IPaymentInfo>): Promise<any>;
  getPaymentInfo(tutorId: string): Promise<any>;
  updatePaymentInfo(paymentInfo: Partial<IPaymentInfo>): Promise<any>;
  getBalance(teacherId: string): Promise<ResponseAPI<Balance>>;
  getTransactions(filter?: any): Promise<ResponseAPI<TutorTransaction>>;
  getBalanceOperations(teacherId: string): Promise<ResponseAPI<BalanceOperation>>;
}

const TutorClient = (
  baseURL: string,
  options?: Options
): ITutorClient => {

  const client = axiosClient(baseURL, options);

  return {
    login,
    resetPassword,
    recoverPassword,
    confirmEmail,
    setPaymentInfo,
    getPaymentInfo,
    updatePaymentInfo,
    findById,
    update,
    getBalance,
    getTransactions,
    getBalanceOperations
  };


  async function login(
    email: string,
    password: string
  ): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: '/teacher/login',
      data: {
        email: email ? email.trim().toLowerCase() : '',
        password
      },
    });

    return data;
  }

  async function recoverPassword(email: string): Promise<void> {
    await client({
      method: "post",
      url: "/teacher/recoverPassword",
      data: {
        email
      },
    });
  }

  async function resetPassword(email: string, password: string, resetPasswordToken: string): Promise<void>{
    await client({
      method: "post",
      url: "/teacher/redefinePassword",
      data: {
        email,
        password,
        resetPasswordToken
      },
    });
  }

  async function confirmEmail(emailConfirmationToken: string): Promise<void>{
    await client({
      method: "post",
      url: "/teacher/confirmEmail",
      data: {
        emailConfirmationToken
      },
    });
  }

  async function setPaymentInfo(
    paymentInfo: Partial<IPaymentInfo>,
  ): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: '/teacher/payment-info',
      data: paymentInfo,
    });

    return data;
  }

  async function getPaymentInfo(
    id: string
  ): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: '/bankAccount',
      params: {
        teacherId: id
      }
    });

    return data;
  }

  async function updatePaymentInfo(
    paymentInfo: Partial<IPaymentInfo>,
  ): Promise<any> {
    const { data } = await client({
      method: 'put',
      url: '/teacher/payment-info',
      data: paymentInfo,
    });

    return data;
  }

  async function update(id: string, tutor: any): Promise<any> {

    if (tutor.email) {
      tutor.email = tutor.email.trim().toLowerCase()
    }

    const { data } = await client({
      method: 'put',
      url: '/teacher',
      data: {
        ...tutor,
        id,
      },
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/teacher/${id}`,
    });

    return data;
  }

  async function getBalance(id: string): Promise<ResponseAPI<Balance>> {
    const { data } = await client({
      method: 'get',
      url: `/teacher/balance/${id}`,
    });

    return data;
  }

  async function getTransactions(filter?: any): Promise<ResponseAPI<TutorTransaction>> {
    const { data } = await client({
      method: 'get',
      url: `/transaction`,
      params: {
        ...filter
      }
    });

    return data;
  }

  async function getBalanceOperations(id: string): Promise<ResponseAPI<BalanceOperation>> {
    const { data } = await client({
      method: 'get',
      url: `/teacher/balanceOperations/${id}`,
    });

    return data;
  }

}

export const tutorClient = TutorClient(BASE_URL);
