import * as React from 'react';
import { useState, useEffect } from 'react';
import { Alert, message } from 'antd';

import { adminCompanyClient } from '../../clients/admin/AdminCompanyClient';

import style from './AdminIncompleteProfile.module.scss';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';
import { Link } from 'react-router-dom';

const AdminIncompleteProfile: React.FC<any> = () => {

  const [missingBasicProfile, setMissingBasicProfile] = useState(false)
  const [missingPaymentInfo, setMissingPaymentInfo] = useState(false)
  const [displayIncompleteProfileBanner, setDisplayIncompleteProfileBanner] = useState(false)
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    loadStudent();
  //eslint-disable-next-line
  }, [])

  return (
    <div className={style.adminIncompleteProfile}>
      {displayIncompleteProfileBanner &&
      <>
        <Alert 
          type='warning'
          message={<>
            <div className={style.title}>Opa! Você ainda não completou seu perfil</div>
            <br />
            <div>Para poder cadastrar {dict.especialidade.plural} você precisa cumprir os todos os itens abaixo:</div>
            <div>
              <span className={!missingPaymentInfo ? style.visible : style.hidden} role="img" aria-label="green check mark">✅ &nbsp;</span>
              {<Link to={`/admin/carteira`}>Adicione seus dados bancários para recebimento</Link>}
            </div>
            <div>
              <span className={!missingBasicProfile ? style.visible : style.hidden} role="img" aria-label="green check mark">✅ &nbsp;</span>
              {<Link to={`/admin/carteira?tab=2`}>Configure os valores de divisão dos pagamentos</Link>}
            </div>
            </>
          }
        />
      </>
      }
    </div>
  );

  async function loadStudent() {

    try {
      const companyId = getCompanyId()
      const companyRes = await adminCompanyClient.findById(companyId)

      if (!companyRes?.data?.pagarmeRecipientId) {
        setMissingPaymentInfo(true)
        setDisplayIncompleteProfileBanner(true)
      }

      if (!companyRes?.data?.adminSplitShare && !companyRes?.data?.teacherSplitShare) {
        setMissingBasicProfile(true)
        setDisplayIncompleteProfileBanner(true)
      }
      
    } catch (err) {
      console.error("Error fetching company", err);
      message.error("Ops, tivemos um erro ao carregar suas informações");
    }

  }
}

export default AdminIncompleteProfile;
