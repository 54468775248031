import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  message
} from 'antd';
import {
  SaveOutlined
} from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import MaskedInput from 'antd-mask-input';

import { adminStudentClient } from '../../clients/admin/AdminStudentClient';

import Button from '../../components/Button';
import { validateCPF, removeNonNumbers } from '../../utils/StrUtils';
import { format, parse } from 'date-fns';

interface IStudentForm {
  student: any;
  afterSave: () => void;
}

const StudentForm: React.FC<IStudentForm> = ({
  student,
  afterSave
}) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (student) {
      form.setFieldsValue(student);
    }
  // eslint-disable-next-line
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={save}
    >
      <Row gutter={8}>
        <Col span={8}>
          <Form.Item 
            name='name'
            label='Nome'
            rules={[{
              required: true,
              message: 'Nome é obrigatório'
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item 
            name='email'
            label='E-mail'
            rules={[
              {
                required: true,
                message: 'Nome é obrigatório'
              },{
              type: 'email',
              message: 'Digite um e-mail válido'
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item 
            name='phone'
            label='WhatsApp'
          >
            <MaskedInput mask='(11) 11111-1111'/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={8}>
          <Form.Item
            name='cpf'
            label='CPF'
          >
            <MaskedInput mask='111.111.111-11'/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name='birthday'
            label='Data de Nascimento'
          >
            <MaskedInput mask='11/11/1111'/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item 
            name='cancellationCredits'
            label='Tookens'
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Button 
        icon={<SaveOutlined />} 
        type='primary'
        htmlType='submit'
        loading={loading}
      >Salvar</Button>
    </Form>
  );

  async function save (values: Store) {
    if (form.getFieldValue('cpf')) {
      const isValidate = validateCPF(
        form.getFieldValue('cpf').replace(/\D/g, '')
      );
      if (!isValidate) {
        return message.error('O CPF informado é inválido.');
      }
    }

    setLoading(true);

    try {

      const _values = {
        ...values,
        cpf: values.cpf ? removeNonNumbers(values.cpf) : '',
        phone: values.phone ? '+55' + removeNonNumbers(values.phone) : '',
        birthday: values.birthday ? format(parse(values.birthday, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') : '',
        cancellationCredits: Number(values.cancellationCredits)
      }

      const response = student 
        ? await adminStudentClient.update(student.id, _values)
        : await adminStudentClient.create(_values);

      if (response) {
        message.success('Aluno salvo com sucesso');
      } else {
        message.error('Falha ao salvar aluno');
      }
    } catch (err) {
      message.error(err?.response?.data?.error?.message || 'Falha ao salvar aluno');
    } finally {
      setLoading(false);
      afterSave();
    }
  }
}

export default StudentForm;
