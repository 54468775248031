import * as React from 'react';
import { useState } from 'react';
import { Input, message, Form } from 'antd';
import Button from '../../components/Button';
import { tutorClassClient } from '../../clients/tutor/TutorClassClient';

interface IPreClassForm {
  classId: string;
  preClass: string;
}

const PreClassForm: React.FC<IPreClassForm> = ({
  classId,
  preClass = ''
}) => {

  const [preClassInput, setPreClassInput] = useState(preClass);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Form layout='vertical'>
        <Form.Item label='Anotações'>
          <Input.TextArea 
            rows={6} 
            value={preClassInput}
            onChange={(e) => setPreClassInput(e.target.value)}
          /> 
        </Form.Item>

        <Button 
          type='primary'
          onClick={savePreClass}
          loading={loading}>
          Salvar
        </Button>
      </Form>
    </>
  );

  async function savePreClass () {

    setLoading(true);

    try {
      const { error } = await tutorClassClient.update(classId, {
        preClass: preClassInput
      });

      if (error) {
        message.error(error.message || 'Falha ao salvar');
      }

      message.success('Anotações salvas com sucesso');

    } finally {
      setLoading(false);
    }

  }

}

export default PreClassForm;
