import * as React from 'react';
import { useState, useEffect } from 'react';
import { Tabs, message, Skeleton } from 'antd';

import { tutorClassClient } from '../../clients/tutor/TutorClassClient';
import { useAuth } from '../../hooks/AuthHook';
import { IClass, ClassStatus, ClassStatusLabel } from '../../types/Class';

import style from './TutorClassListPage.module.scss';
import TutorClassCard from '../../componentsTutor/TutorClassCard';
import Header from '../../components/Header';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';

const { TabPane } = Tabs;

const TutorClassListPage: React.FC = () => {

  const auth = useAuth();
  const [classes, setClasses] = useState<IClass[]>([]);
  const [loading, setLoading] = useState(true);
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    loadClasses();
  // eslint-disable-next-line
  }, [auth.user]);

  return (
    <div className={style.classListPage}>
      <Header />
      <Tabs 
        defaultActiveKey='requested' 
        type='card' 
        size='small'
      >
        <TabPane tab='Solicitações' key='requested'>
          <ClassList afterSave={loadClasses} status={ClassStatus.REQUESTED} classes={classes} />
        </TabPane>
        <TabPane tab='Agendamentos' key='scheduled'>
          {loading && <Skeleton active />}
          {!loading &&
            <ClassList afterSave={loadClasses} status={ClassStatus.SCHEDULED} classes={classes} />
          }
        </TabPane>
        <TabPane tab='Concluídos' key='done'>
          <ClassList afterSave={loadClasses} status={ClassStatus.DONE} classes={classes} />
        </TabPane>
        <TabPane tab='Cancelamentos' key='canceled'>
          <ClassList afterSave={loadClasses} status={ClassStatus.CANCELED} classes={classes} />
        </TabPane>
        <TabPane tab='Reagendar' key='refused'>
          <ClassList afterSave={loadClasses} status={ClassStatus.REFUSED} classes={classes} />
        </TabPane>
      </Tabs>
    </div>
  );

  async function loadClasses () {

    console.log(auth);

    try {
      const companyId = getCompanyId()
      const { results, error } = await tutorClassClient.findAll({
        teacherId: auth?.user?.id,
        companyId
      });
  
      if (error) {
        message.error(error.message || 'Falha ao carregar lista de ' + dict.atendimento.singular);
        return;
      }

      const withSchedule = results.filter((c: IClass) => c.scheduledOnUtc);

  
      setClasses(results);
    } finally {
      setLoading(false);
    }
  }
}

interface IClassList {
  status: number;
  classes: IClass[];
  afterSave: () => void;
}

const ClassList: React.FC<IClassList> = ({
  status,
  classes = [],
  afterSave
}) => {

  const filteredClasses = classes.filter((currClass) => currClass.statusId === status);

  return (
    <div className={style.list}>
      {filteredClasses
        .map((currClass) => (
        <TutorClassCard 
          currClass={currClass}
          afterSave={afterSave}
        />
      ))}
      {!filteredClasses.length &&
        <p>Não há nenhum atendimento {ClassStatusLabel[status]} no momento </p>
      }
    </div>
  );
}

export default TutorClassListPage;
