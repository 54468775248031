import { addHours } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import React from "react";
import { scheduleOption } from "../../types/Schedule";
import { getBtnTextColor, getSecondaryColor } from "../../utils/CompanyConfigUtils";
import { getDayOfWeekText, getMonthText } from "../../utils/DateUtils";
import { CloseOutlined } from "@ant-design/icons";

import style from "./ScheduleSelectedOptionsPreview.module.scss";

interface IScheduleSelectedOptionsPreview {
  selectedScheduleOptions: scheduleOption[];
  handleClick: (dateIndex, timeIndex) => void;
}

const ScheduleSelectedOptionsPreview: React.FC<IScheduleSelectedOptionsPreview> = ({ selectedScheduleOptions, handleClick }) => {
  return (
    <div id={"preview-list"} className={style.selectedScheduleOptions}>
      {selectedScheduleOptions.map((scheduleOption, dateIndex) => {
        return (
          <>
            {scheduleOption.times.map((time, timeIndex) => {
              const formatedFromTime = formatToTimeZone(time, "HH:mm", { timeZone: "America/Recife" });
              const formatedToTime = formatToTimeZone(addHours(time, 1), "HH:mm", { timeZone: "America/Recife" });
              return (
                <div className={style.scheduleOption} style={{ backgroundColor: getSecondaryColor(), color: getBtnTextColor() }}>
                  <CloseOutlined className={style.cancelSchedule} onClick={() => handleClick(dateIndex, timeIndex)} />
                  <p className={style.date}>
                    {scheduleOption.date.getDate()} de {getMonthText(scheduleOption.date.getMonth())},{" "}
                    {getDayOfWeekText(scheduleOption.date.getDay())}
                  </p>
                  <p className={style.hour}>
                    {formatedFromTime} até {formatedToTime}
                  </p>
                </div>
              );
            })}
          </>
        );
      })}
    </div>
  );
};

export default ScheduleSelectedOptionsPreview;
