export function getDayOfWeekText(dayOfWeek: number) {
  return ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"][dayOfWeek];
}

export function getDayOfWeekAbreviattedText(dayOfWeek: number) {
  return [
    { desktop: "Dom.", mobile: "D" },
    { desktop: "Seg.", mobile: "S" },
    { desktop: "Ter.", mobile: "T" },
    { desktop: "Qua.", mobile: "Q" },
    { desktop: "Qui.", mobile: "Q" },
    { desktop: "Sex.", mobile: "S" },
    { desktop: "Sáb.", mobile: "S" },
  ][dayOfWeek];
}

export function getMonthText(month: number) {
  return ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][month];
}
