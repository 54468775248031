import PaymentForm from "../../componentsTutor/TutorPaymentForm";
import queryString from "query-string";
import style from "./AdminWalletPage.module.scss";
import { adminClient } from "../../clients/admin/AdminClient";
import { message, Tabs, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/AuthHook";
import { useLocation } from "react-router-dom";
import * as React from "react";
import Header from "../../components/Header";
import AdminBalance from "../../componentsAdmin/AdminBalance";
import SplitForm from "../../componentsAdmin/SplitForm";
import { CompanyDict } from "../../types/CompanyDict";
import { getCompanyDict } from "../../utils/CompanyConfigUtils";

const { TabPane } = Tabs;

const AdminWalletPage: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [admin, setAdmin] = useState<any>();
  const [avatarUrl, setAvatarUrl] = useState();
  const dict: CompanyDict = getCompanyDict() 

  const { tab }: any = queryString.parse(location.search);

  
  useEffect(() => {
    loadAdmin();
    // eslint-disable-next-line
  }, [])
  
  const props = {
    name: "file",
    action: "https://rk7k040i61.execute-api.us-east-1.amazonaws.com/dev/asset",
    showUploadList: false,
    onChange: onChangePicture,
  }

    return (
      <div className={style.wallet}>
            <Header />
            {loading && !admin && <Skeleton active />}
            {!loading && admin && (
            <>
                <Tabs type="card" size="small" defaultActiveKey={tab || "1"}>
                <TabPane tab="Dados de recebimento" key="1">
                    <PaymentForm 
                        afterSave={loadAdmin} 
                        teacher={admin}
                        isAdmin={true}
                    />
                </TabPane>
                <TabPane tab="Divisão do pagamento" key="2">
                    <SplitForm 
                        afterSave={loadAdmin} 
                        admin={admin}
                    />
                </TabPane>
                <TabPane tab="Saldo OOri" key="3">
                  <AdminBalance />
                </TabPane>
                </Tabs>
            </>
            )}
        </div>
    );

    async function loadAdmin() {
        try {
            const { data, error } = await adminClient.findById(auth.user.id);
    
            if (error) {
                message.error(error.message || "Falha ao carregar informações.");
                return;
            }
            setAdmin(data);
        } catch {
            message.error("Falha ao carregar informações.");
        } finally {
            setLoading(false);
        }
    }
  
    async function onChangePicture(info) {
      setLoadingAvatar(true);
      if (info.file.status === "done") {
        setAvatarUrl(info.file.response.data.url);
        const response = await adminClient.update(auth.user.id, {
          avatar: info.file.response.data.url,
        });
  
        if (response) {
          message.success("Salvo com sucesso");
          setLoadingAvatar(false);
        } else {
          message.error("Falha ao salvar avatar");
          setLoadingAvatar(false);
        }
      } else if (info.file.status === "error") {
        message.error(`Falha ao fazer o upload.`);
        setLoadingAvatar(false);
      }
    }
};

export default AdminWalletPage;
