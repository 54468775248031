import * as React from 'react';
import ExitButton from '../../components/ExitButton/ExitButton';

import ClassTableList from '../../componentsAdmin/ClassTableList';
import style from './AdminClassListPage.module.scss';

const AdminClassListPage: React.FC = () => {
  return (
    <div className={style.classListPage}>
      <ExitButton />
      <ClassTableList />
    </div>
  );
}

export default AdminClassListPage;
