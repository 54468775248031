import { IPaymentInfo } from "../../types/Teacher";
import { axiosClient } from "../axiosHandler";
import { Options } from "../axiosTypes";
import { BASE_URL } from "../configClient";

interface IAdminClient {
  login(email: string, password: string): Promise<any>;
  refreshToken(token: string): Promise<any>;
  getDashboard(companyId: string): Promise<any>;
  findById(id: string): Promise<any>;
  update(id: string, admin: any): Promise<any>;
}

const AdminClient = (baseURL: string, options?: Options): IAdminClient => {
  const client = axiosClient(baseURL, options);

  return {
    login,
    refreshToken,
    getDashboard,
    findById,
    update
  };
  async function login(email: string, password: string): Promise<any> {
    const { data } = await client({
      method: "post",
      url: "/admin/login",
      data: {
        email: email ? email.trim().toLowerCase() : '',
        password,
      },
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/admin/${id}`,
    });

    return data;
  }

  async function update(id: string, admin: any): Promise<any> {
    const { data } = await client({
      method: 'put',
      url: '/admin',
      data: {
        ...admin,
        id,
      },
    });

    return data;
  }

  async function refreshToken(token: string): Promise<any> {
    const { data } = await client({
      method: "get",
      url: "/admin/auth",
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    return data;
  }

  async function getDashboard(companyId: string): Promise<any> {
    const { data } = await client({
      method: "get",
      url: `/admin/dashboard/${companyId}`,
    });

    return data;
  }
};

export const adminClient = AdminClient(BASE_URL);
