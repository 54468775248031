import * as React from 'react';
import { 
    Form,
    Input,
    Divider,
    message,
    Result, Alert
  } from 'antd';
import style from './ResetPasswordForm.module.scss';
import Button from '../Button';
import { useLocation, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import { studentClient } from '../../clients/student/StudentClient';
import { tutorClient } from '../../clients/tutor/TutorClient';
import { getCompanyDict, getLogoPath } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils'
import { CompanyDict } from '../../types/CompanyDict';

const ResetPasswordForm: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const logoPath = getLogoPath()
    const [passwordReset, setPasswordReset] = useState(false);
    const [loading, setLoading] = useState(false);
    const dict: CompanyDict = getCompanyDict()

    return (
        <Form 
          onFinish={resetPassword}
          layout='vertical'
          className={style.inputPasswordForm}>
          <img src={logoPath} alt=''/>

          {location.pathname.includes('profissional') &&
            <h2>{capitalize(dict.profissional.singular)}</h2>
          }

          <Divider />

          <Result
          style={{display: passwordReset ? 'block' : 'none' }}
            status="success"
            title="Senha redefinida! 🤙"
            subTitle={
              <>
                <Alert
                  type='info'
                  message={<>
                  Sua senha foi redefinida com sucesso!
                  <br />
                    {location.pathname.includes('profissional') &&
                     <a href="/profissional/login">Acesse o login</a>
                    }
                    {!location.pathname.includes('profissional') &&
                      <a href="/login">Acesse o login</a>
                    }
                  </>
                  }
                />
              </>
            }
          />
         

          <div 
            className={style.inputPasswordFormContainer} 
            style={{display: passwordReset ? 'none' : 'flex' }}>
            <h1><strong>Redefina sua senha</strong></h1>
              <Form.Item 
                label='E-mail'
                name='email'>
                <Input />
              </Form.Item>

              <Form.Item name='password' label='Nova senha'>
                <Input type='password'/>
              </Form.Item>
              <Form.Item name='confirm_password' label='Confirmar a nova senha'>
                <Input type='password'/>
              </Form.Item>
              
              <Button 
              type='primary' 
              htmlType='submit'
              loading={loading}>
                Enviar
              </Button>
          </div>
        </Form>
    );

    async function resetPassword (values: Store) {
      const token = new URLSearchParams(history.location.search).get('code');
      const { email, password, confirm_password } = values;
      if (password !== confirm_password) {
        message.error('As senhas digitadas não coincidem');
        return;
      }
      setLoading(true);
      try{
        if(location.pathname.includes('profissional')){
          await tutorClient.resetPassword(email, password, token ? token : "")
        }else{
          await studentClient.resetPassword(email, password, token ? token : "")
        }
        setPasswordReset(true)
      }catch{
        message.error('Falha ao redefinir senha');
      }finally{
        setLoading(false);
      }
      
    }
};

export default ResetPasswordForm;