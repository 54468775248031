import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, message, Divider, Checkbox } from "antd";
import { Store } from "antd/lib/form/interface";
import { GOOGLE_CLIENT_ID } from "../../clients/configClient";
import { GoogleLogin } from "react-google-login";

import { studentClient } from "../../clients/student/StudentClient";
import { useAuth } from "../../hooks/AuthHook";
import style from "./RegisterForm.module.scss";
import { getBtnTextColor, getLogoPath, getPrimaryColor } from "../../utils/CompanyConfigUtils";

import { Button } from "antd";

const RegisterForm: React.FC = () => {
  const history = useHistory();
  const logoPath = getLogoPath();
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  return (
    <Form layout="vertical" onFinish={register} className={style.registerForm}>
      <img src={logoPath} alt="" />

      <Divider />

      <h1 style={{ marginBottom: "20px" }}>
        <strong>Comece seu cadastro na OOri</strong>
      </h1>

      <Form.Item name="name" label="Nome">
        <Input />
      </Form.Item>
      <Form.Item name="email" label="E-mail">
        <Input />
      </Form.Item>
      <Form.Item name="password" label="Senha">
        <Input type="password" />
      </Form.Item>
      <Form.Item name="confirm_password" label="Confirmar Senha">
        <Input type="password" />
      </Form.Item>

      <p>
        Ao clicar em cadastrar, concordo com os
        <a
          href="https://docs.google.com/document/d/1ycOyxD9VSe29pe3n8CKSZMLDJ7SWDPzqYMmwH5s_V0I/edit?usp=sharing"
          rel="noreferrer noopener"
          target="_blank"
        >
          &nbsp;Termos de Uso
        </a>
        ,
        <a
          href="https://docs.google.com/document/d/1vKM8rbkBqO0DnyNMSIMr5NCFl0Fh74IKUhi_sb8_XL0/edit?usp=sharing"
          rel="noreferrer noopener"
          target="_blank"
        >
          &nbsp;Política de Privacidade&nbsp;
        </a>
        e
        <a
          href="https://docs.google.com/document/d/165GDVOi3m8EC26m2HsYvlrQfPktqX7SmkH0sXW8y5nY/edit?usp=sharing"
          rel="noreferrer noopener"
          target="_blank"
        >
          &nbsp;Política de Cancelamento.
        </a>
      </p>

      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        style={{
          marginTop: "5px",
          backgroundColor: getPrimaryColor(),
          borderColor: getPrimaryColor(),
          color: getBtnTextColor(),
        }}
      >
        Cadastrar
      </Button>

      <p style={{ marginBottom: "8px", marginTop: "8px" }}>ou</p>

      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        buttonText="Cadastrar com Google"
        onSuccess={googleAuth}
        onFailure={googleAuth}
        theme="dark"
        className="ct-button ct-button--secondary"
        cookiePolicy="single_host_origin"
      />

      <br />
    </Form>
  );

  async function register(values: Store) {
    const { email, password, confirm_password, terms_and_conditions, ...otherValues } = values;

    if (password !== confirm_password) {
      message.error("As senhas digitadas não coincidem");
      return;
    }

    setLoading(true);

    try {
      const response = await studentClient.create({
        ...otherValues,
        password,
        email,
      });

      if (!response) {
        message.error("Falha ao cadastrar");
        return;
      }

      await login({
        email,
        password,
      });
    } catch {
      message.error("Falha ao cadastrar");
      setLoading(false);
    }
  }

  async function login(values: Store) {
    const { email, password } = values;
    const { data } = await studentClient.login(email, password);
    auth.signin(data.token, "oori_student_token");
    history.push(decodeURIComponent("/"));
    setLoading(false);
  }

  async function googleAuth(values: Store) {
    setLoading(true);

    try {
      const token = values.tokenId;
      const { data } = await studentClient.googleAuth(token);

      auth.signin(data.token, "oori_student_token");

      history.push(decodeURIComponent("/"));
    } catch (err) {
      console.log(err);
      message.error("Algo deu errado! Se o erro persistir, por favor contate o suporte.");
    } finally {
      setLoading(false);
    }
  }
};

export default RegisterForm;
