import * as React from "react";
import style from "./HeaderNavigation.module.scss";
import { useHistory, useLocation } from 'react-router-dom';
import { CompanyDict } from '../../types/CompanyDict'
import { getCompanyDict } from '../../utils/CompanyConfigUtils'

const HeaderNavigation: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dict : CompanyDict = getCompanyDict()

  return (
    <div className={style.header}>
      <div 
        className={style.customHeaderItem}
        onClick={navigateToStudentLogin}>
        <div className={style.customHeaderText}>
          Portal de {dict.cliente.plural}
        </div>
        {!location.pathname.includes('profissional') &&
          <div className={style.customHeaderUnderline}/>
        }
      </div>
      <div 
        className={style.customHeaderItem}
        onClick={navigateToTeacherLogin}>
        <div className={style.customHeaderText}>
          Portal de {dict.profissional.plural}
        </div>
        {location.pathname.includes('profissional') &&
          <div className={style.customHeaderUnderline}/>
        }
      </div>
    </div>
  );

  function navigateToStudentLogin(){
    history.push("/login");
  }

  function navigateToTeacherLogin(){
    history.push("/profissional");
  }
};

export default HeaderNavigation;