import React from "react";

import style from "./ScheduleDescription.module.scss";
import avatar from "../../assets/avatar.jpeg";

interface iScheduleDescription {
  value: string;
  title: string;
  teacherAvatar: string;
  teacherName: string;
  serviceSlug: string;
}

const ScheduleDescription: React.FC<iScheduleDescription> = ({ value, title, teacherAvatar, teacherName, serviceSlug }) => {
  return (
    <div className={style.description}>
      <div className={style.info}>
        <h2>{title}</h2>
        <p>
          {value} por {serviceSlug}.
        </p>
      </div>
      <div className={style.teacher}>
        <img src={teacherAvatar || avatar} alt="Teacher Avatar" />
        <span>{teacherName}</span>
      </div>
    </div>
  );
};

export default ScheduleDescription;
