import * as React from 'react';
import { useState, useEffect } from "react";
import { Form, Input, Col, Row, message, Select } from 'antd';
import { Store } from "antd/lib/form/interface";
import MaskedInput from 'antd-mask-input';

import { useAuth } from "../../hooks/AuthHook";
import Button from "../../components/Button";
import { studentClient } from "../../clients/student/StudentClient";
import { SaveOutlined } from '@ant-design/icons';
import { STATES } from '../../utils/Constants';
import { removeNonNumbers } from '../../utils/StrUtils';

interface IAddressForm {
  afterSave: () => void;
  student: any;
}

const AddressForm: React.FC<IAddressForm> = ({ 
  afterSave,
  student
 }) => {

  const auth = useAuth();
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  const [address, setAddress] = useState({
    id: -1
  });
  
  useEffect(() => {
    loadAddress();
    // eslint-disable-next-line
  }, []);

  return (
    <Form 
      form={form}
      layout='vertical'
      onFinish={save}
    >
      <Row>
        <Col span={24}>
          <Form.Item 
            label='CEP' 
            name='zipCode'
            rules={[{required: true, message: 'CEP é obrigatório'}]}
          >
            <MaskedInput mask='11111-111'/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={18}>
          <Form.Item 
            label='Rua' 
            name='street'
            rules={[{required: true, message: 'Rua é obrigatório'}]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item 
            label='Número' 
            name='streetNumber'
            rules={[{required: true, message: 'Nr é obrigatório'}]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item 
            label='Complemento' 
            name='complement'
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            label='Bairro' 
            name='neighborhood'
            rules={[{required: true, message: 'Bairro é obrigatório'}]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item 
            label='Cidade'
            name='city'
            rules={[{required: true, message: 'Cidade é obrigatório'}]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item 
            label='Estado' 
            name='state'
            rules={[{required: true, message: 'Estado é obrigatório'}]}
          >
            <Select showSearch optionFilterProp='children'>
              {STATES.map((state: string) => {
                return (
                  <Select.Option key={state} value={state}>
                    {state.toUpperCase()}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Button
        icon={<SaveOutlined />}
        type="primary"
        htmlType="submit"
        loading={saveLoading}
      >
        Salvar
      </Button>
    </Form>
  );

  async function loadAddress () {
    const { results: [data] } = await studentClient.getAddress(auth.user.id);
    form.setFieldsValue(data);
    setAddress(data)
  }

  async function save(values: Store) {
    setSaveLoading(true);

    try {

      var response;
      if (address && address.id != -1) {
        response = await studentClient.updateAddress(auth.user.id, {
          ...values,
          zipcode: removeNonNumbers(values.zipcode),
          id: address.id
        });
      } else {
        response = await studentClient.addAddress(auth.user.id, {
          ...values,
          zipcode: removeNonNumbers(values.zipcode),
          country: 'Brasil'
        });
      }
      

      if (response) {
        message.success("Salvo com sucesso");
      } else {
        message.error("Falha ao salvar o endereço");
      }
    } catch {
      message.error("Falha ao salvar o endereço");
    } finally {
      setSaveLoading(false);
      loadAddress();
      afterSave();
    }
  }

}

export default AddressForm;
