import * as React from 'react';
import { useState, useEffect } from 'react';
import { BASE_URL } from "../../clients/configClient";

import {
  Form,
  Input,
  Row,
  Col,
  message,
  Avatar,
  Upload,
  Divider,
  Button,
  Tabs
} from 'antd';
import OoriButton from '../../components/Button';
import { SaveOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import { adminCompanyClient } from '../../clients/admin/AdminCompanyClient';
import { setCompany } from '../../utils/LocalStorageUtils'
import { invalidateConfigCache } from '../../utils/CompanyConfigUtils';
import style from "./ThemeSettingsForm.module.scss";

interface IThemeSettingsForm {
  config: any
}

const ThemeSettingsForm: React.FC<IThemeSettingsForm> = ({
  config
}) => {
  
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState();
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [primaryColor, setPrimaryColor] = useState(config.primaryColorHexCode);
  const [btnTextColor, setBtnTextColor] = useState(config.btnTextColorHexCode);
  var bodyStyles = document.body.style;

  useEffect(() => {
    if (config) {
      form.setFieldsValue({
        logoUrl: config.logoUrl,
        primaryColorHexCode: config.primaryColorHexCode,
        btnTextColorHexCode: config.btnTextColorHexCode,
        secondaryColorHexCode: config.secondaryColorHexCode,
        highlightColorHexCode: config.highlightColorHexCode
      });
      
      // bodyStyles.setProperty('--primary-color', config.primaryColorHexCode);
      // bodyStyles.setProperty('--secondary-color', config.secondaryColorHexCode);
      // bodyStyles.setProperty('--highlight-color', config.highlightColorHexCode);
    }
  // eslint-disable-next-line
  }, []);

  const props = {
    name: "file",
    action: `${BASE_URL}/asset`,
    showUploadList: false,
    onChange: onChangeLogo,
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={updateTheme}>
      {window.innerWidth > 500 &&
        <Row>
          <Col span={12}>
            <h1 className={style.title}>Personalização</h1>
            <h2 className={style.subtitle}>Deixe a Oori com a sua cara</h2>
          </Col>
          <Col span={12}>
            <OoriButton 
              icon={<SaveOutlined />} 
              type='primary'
              htmlType='submit'
              className={style.saveButton}
              loading={loading}>
              Salvar alterações
            </OoriButton>
          </Col>
        </Row>
      }

      {window.innerWidth < 500 &&
        <>
          <Row>
            <Col span={12}>
              <h1 className={style.title}>Personalização</h1>
              <h2 className={style.subtitle}>Deixe a Oori com a sua cara</h2>
            </Col>
          </Row>
          <OoriButton 
            icon={<SaveOutlined />} 
            type='primary'
            htmlType='submit'
            className={style.saveButtonMobile}
            loading={loading}>
            Salvar alterações
          </OoriButton>
        </>
      }
      <Divider className={style.sectionDivider} orientation='left'>
        <p className={style.sectionTitle}>Logo</p>
      </Divider>
      <p className={style.sectionDescription}>Escolha a imagem que será exibida no menu da esquerda</p>
      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
        <img src={avatarUrl || config.logoUrl} alt='' style={{ maxWidth: '160px', maxHeight: '80px', marginRight: '24px' }}/>
        <Upload {...props}>
          <OoriButton type='primary' loading={loadingAvatar} >
            Trocar logo
          </OoriButton>
        </Upload>
      </div>
      <Divider className={style.sectionDivider} orientation='left'>
        <p className={style.sectionTitle}>Cor principal</p>
      </Divider>
      <p className={style.sectionDescription}>Usada como fundo do menu da esquerda (só no computador) e nos botões</p>
      <Row>
        <Col span={window.innerWidth < 500 ? 12 : 6}>
          <Form.Item 
              name='primaryColorHexCode'
              label='Cor principal'
              rules={[{
                required: true,
                message: 'Cor principal é obrigatória'
              }]}
            >
              <Input type='color' onChange={(e) => changePrimaryColor(e.target.value)}/>
            </Form.Item>
            <Form.Item 
              name='btnTextColorHexCode'
              label='Cor do texto do botão'
              rules={[{
                required: true,
                message: 'Cor do texto do botão é obrigatória'
              }]}
            >
              <Input type='color' onChange={(e) => changeBtnTextColor(e.target.value)}/>
            </Form.Item>
        </Col>
      {window.innerWidth < 500 &&
        <Col style={{marginTop: '20px'}} span={6} offset={1}>
          <Button type='primary' style={{backgroundColor: primaryColor, borderColor: primaryColor, color: btnTextColor}}>Exemplo</Button>
        </Col>
      }
      {window.innerWidth > 500 &&
        <>
          <Col style={{marginTop: '18px'}} span={2} offset={2}>
              <p>Exemplo:</p>
          </Col>
          <Col style={{marginTop: '14px'}} span={6}>
            <Button type='primary' style={{backgroundColor: primaryColor, borderColor: primaryColor, color: btnTextColor}}>Botão exemplo</Button>
          </Col>
        </>
      }
      </Row>
      <Divider className={style.sectionDivider} orientation='left'>
        <p className={style.sectionTitle}>Cor secundária</p>
      </Divider>
      <p className={style.sectionDescription}>Usada como cor do texto no menu do celular e das tabs em diversas páginas</p>
      <Row>
        <Col span={window.innerWidth < 500 ? 12 : 6}>
          <Form.Item 
            name='secondaryColorHexCode'
            label='Cor secundária'
            rules={[{
              required: true,
              message: 'Cor secundária é obrigatória'
            }]}
          >
            <Input type='color' onChange={(e) => changeSecondaryColor(e.target.value)}/>
          </Form.Item>
        </Col>
        {window.innerWidth < 500 &&
          <Col style={{marginTop: '20px'}} span={10} offset={1}>
            <Tabs type='card'>
                <Tabs.TabPane tab='Exemplo' key='tab1'/>
                <Tabs.TabPane tab='Tab' key='tab2' />
            </Tabs>
          </Col>
        }
        {window.innerWidth > 500 &&
          <>
            <Col style={{marginTop: '18px'}} span={2} offset={2}>
                <p>Exemplo:</p>
            </Col>
            <Col style={{marginTop: '14px'}} span={3}>
              <Tabs type='card'>
                <Tabs.TabPane tab='Tab 1' key='tab1'/>
                <Tabs.TabPane tab='Tab 2' key='tab2' />
              </Tabs>
            </Col>
          </>
        }
      </Row>
      <Divider className={style.sectionDivider} orientation='left'>
        <p className={style.sectionTitle}>Cor de destaque do menu</p>
      </Divider>
      <p className={style.sectionDescription}>Usada nos textos e no fundo do item destacado do menu da esquerda</p>
      <Col span={window.innerWidth < 500 ? 12 : 6}>
        <Form.Item 
          name='highlightColorHexCode'
          label='Cor de destaque'
          rules={[{
            required: true,
            message: 'Cor de destaque é obrigatória'
          }]}
        >
          <Input type='color'/>
        </Form.Item>
      </Col>
    </Form>
  );

  async function updateTheme(values: Store){
    setLoading(true);

    try{
      const request ={
        logoUrl: avatarUrl || config.logoUrl,
        ...values
      }

      const response = await adminCompanyClient.updateCompany(request) 
      if (response) {
        message.success('Configuração salva com sucesso');
        refreshPage(response)
      } else {
        message.error('Falha ao salvar configuração');
      }
    }catch (err) {
      message.error(err?.response?.data?.error?.message || 'Falha ao salvar configuração');
    } finally {
      setLoading(false);
    }
  }

  function refreshPage(response: any){
    setCompany(response.data)
    invalidateConfigCache()
    window.location.reload();
  }

  function changePrimaryColor(selectedColor: any){
    setPrimaryColor(selectedColor)
  }

  function changeBtnTextColor(selectedColor: any){
    setBtnTextColor(selectedColor)
  }

  function changeSecondaryColor(selectedColor: any){
    bodyStyles.setProperty('--secondary-color', selectedColor);
  }

  async function onChangeLogo(info) {
    setLoadingAvatar(true);
    if (info.file.status === "done") {
      setAvatarUrl(info.file.response.data.url);
      setLoadingAvatar(false);
    }
  }
}

export default ThemeSettingsForm;
