import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Table, Button, message, Popconfirm
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EyeFilled, DeleteOutlined } from '@ant-design/icons';
import queryString from 'query-string';

import { tutorSubjectClient } from '../../clients/tutor/TutorSubjectClient';
import { useAuth } from '../../hooks/AuthHook';

import SubjectViewDrawer from '../SubjectViewDrawer'
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';

interface ISubjectListTable {
  updateView: boolean;
}

const SubjectListTable: React.FC<ISubjectListTable> = ({
  updateView
}) => {

  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const dict: CompanyDict = getCompanyDict()

  const [showEditForm, setShowEditForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState<any[]>([]);

  useEffect(() => {
    loadSubjects();
  // eslint-disable-next-line
  }, [updateView]);


  const columns: ColumnsType<{}> = [
    {
    title: 'Nome',
    dataIndex: 'name'
    },
    {
      dataIndex: 'id',
      render: (id: string) => (
        <>
          <Button 
            icon={<EyeFilled />} 
            shape='circle' 
            onClick={() => openSubject(id)} 
            size='small'
          />
          <Popconfirm 
            title={'Tem certeza que deseja deletar esta ' + dict.especialidade.singular + '?'}
            onConfirm={() => deleteSubject(id)}>
            <Button 
              icon={<DeleteOutlined />} 
              danger 
              shape='circle' 
              size='small'
              style={{marginLeft: 8}}
            />
          </Popconfirm>
        </>
      ),
    }
  ];

  return (
    <>
      <Table
        dataSource={subjects}
        columns={columns}
        rowKey={((r) => r.subjectId)}
        loading={loading}
      />
      {showEditForm && <SubjectViewDrawer 
        showEditForm={showEditForm}
        closeEditForm={closeEditForm}
      />}
    </>
  );

  async function loadSubjects () {
    const companyId = getCompanyId()
    const { results } = await tutorSubjectClient.findAll({
      teacherId: auth?.user?.id,
      companyId: companyId
    });
    setSubjects(results);
    setLoading(false);
  }

  function openSubject (subjectId: string) {
    const queryValues = queryString.parse(location.search);

    history.push({
      search: new URLSearchParams(
        queryString.stringify({ ...queryValues, subjectId })
      ).toString(),
    });

    setShowEditForm(true);
  }

  async function deleteSubject(subjectId: string) {
    
    return

    // NOTE Removido temporariamente até arrumarmos o front para tratar subjects deletadas
    // const { error } = await tutorSubjectClient.remove(subjectId);

    // if (!error) {
    //   loadSubjects();
    //   return;
    // }

    // message.error(error.message || 'Não foi possível deletar ' + dict.especialidade.singular);
  }

  function closeEditForm() {
    setLoading(true);
    loadSubjects();

    history.push({
      search: new URLSearchParams(
        queryString.stringify({})
      ).toString(),
    });

    setShowEditForm(false);
  }
}

export default SubjectListTable;
