import * as React from 'react';
import { Avatar } from 'antd';


import defaultAvatar from '../../assets/defaultAvatar.jpeg';
import { IChat } from '../../types/Chat';

import style from './ChatCard.module.scss';

interface IChatCard {
  chat: IChat;
  setChat: (chat: IChat) => void;
  selected: boolean;
}

const ChatCard: React.FC<IChatCard> = ({
  chat,
  setChat,
  selected
}) => {

  const { toUser: { name, avatar } } = chat;

  return (
    <div 
      className={selected ? [style.chatCard, style.selected].join(' ') : style.chatCard} 
      onClick={goToChat}
    >
      <Avatar src={avatar || defaultAvatar} size={64} />
      <p>{name}</p>
    </div>
  );

  function goToChat () {
    setChat(chat);
  }

}

export default ChatCard;
