import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  message,
  Alert,
  Divider,
  Spin
} from 'antd';
import {
  SaveOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';

import { adminTutorClient } from '../../clients/admin/AdminTutorClient';

import Button from '../../components/Button';
import { CompanyDict } from '../../types/CompanyDict';
import { capitalize } from '@material-ui/core';
import AdminIncompleteProfile from '../AdminIncompleteProfile';
import { adminCompanyClient } from '../../clients/admin/AdminCompanyClient';

interface ITutorForm {
  tutor?: any;
  afterSave: () => void;
}

const TutorForm: React.FC<ITutorForm> = ({
  tutor,
  afterSave
}) => {

  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [emailSearched, setEmailSearched] = useState(false)
  const [showAddTeacherForm, setShowAddTeacherForm] = useState(false)
  const [searchedTeacherId, setSearchedTeacherId] = useState(null)
  const [loading, setLoading] = useState(false);
  const [pwdLoading, setPwdLoading] = useState(false);
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    if (tutor) {
      form.setFieldsValue(tutor);
    }
    loadCompany()
  // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <Spin tip='Carregando' />}
      <AdminIncompleteProfile />
      {!tutor && !emailSearched && !loading && showAddTeacherForm && 
      <Form
        layout='vertical'
        onFinish={search}>
       <Row gutter={12}>
        <Col span={12}>
          <Form.Item 
            name='email'
            label='Email'
            rules={[{
              required: true,
              message: 'Email é obrigatório'
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Button 
          icon={<SearchOutlined />} 
          type='primary'
          htmlType='submit'
          loading={loading}
        >Buscar</Button>
      </ Form>
      }
      {!tutor && emailSearched && !loading && 
      <Form
        form={form}  
        layout='vertical'
        onFinish={addCompany}>
          <Row gutter={12}>
          <Col span={12}>
            <Form.Item 
              name='email'
              label='Email'
              rules={[{
                required: true,
                message: 'Email é obrigatório'
              }]}>
              <Input />
            </Form.Item>
            {!searchedTeacherId &&
              <Form.Item 
                name='password'
                label='Senha'
                rules={[
                  {
                    required: true,
                    message: 'Senha é obrigatória'
                  }]}>
                <Input type='password'/>
              </Form.Item>
            }
          </Col>
        </Row>
        {searchedTeacherId &&
          <Alert
            style={{ marginBottom: '12px'}}
            type='info'
            message='Profissional já cadastrado na OOri. Ao continuar você irá adicionar este profissional ao seu ambiente Oori.'/>
        }
        {!searchedTeacherId &&
          <Alert
            style={{ marginBottom: '12px'}}
            type='info'
            message='Este profissional ainda não está cadastrado na Oori. Ao continuar você irá criar uma conta e adicioná-lo ao seu ambiente Oori. Obs: você deve informar o profissional a senha cadastrada.'/>
        }
        <Button 
          style={{marginTop: '12px'}}
          icon={<SaveOutlined />} 
          type='primary'
          htmlType='submit'
          loading={loading}
        >Adicionar</Button>
      </Form>
      }
      {tutor && !loading &&
        <div>
        <Divider orientation='left'>
          Informações básicas
        </Divider>
        <Form
          form={form}
          layout='vertical'
          onFinish={save}
          style={{textAlign: 'right'}}
        >
            <Form.Item 
              name='name'
              label='Nome'
            >
              <Input />
            </Form.Item>
            <Form.Item 
              name='email'
              label='E-mail'
              rules={[
              {
                type: 'email',
                message: 'Digite um e-mail válido'
              }]}
            >
              <Input />
            </Form.Item>
          
          <Button 
            icon={<SaveOutlined />} 
            type='primary'
            htmlType='submit'
            loading={loading}
          >Salvar</Button>      
        </Form>

        <Divider orientation='left'>
          Redefinir Senha
        </Divider>
        <Form
          form={passwordForm}
          layout='vertical'
          onFinish={updatePassword}
          style={{textAlign: 'right'}}
        >
        
          <Form.Item 
            name='password'
            label='Senha'>
            <Input type='password'/>
          </Form.Item>

          <Button 
            icon={<SaveOutlined />} 
            type='primary'
            htmlType='submit'
            loading={pwdLoading}
          >Atualizar senha</Button>  
          
        </Form>
        </div>
      }
    </>
  );

  async function loadCompany() {
    setLoading(true);

    try {
      const companyId = getCompanyId()
      const companyRes = await adminCompanyClient.findById(companyId)

      var completeProfile = true

      if (!companyRes?.data?.pagarmeRecipientId) {
        completeProfile = false
      }

      if (!companyRes?.data?.adminSplitShare && !companyRes?.data?.teacherSplitShare) {
        completeProfile = false
      }

      setShowAddTeacherForm(completeProfile)
      
    } catch (err) {
      console.error("Error fetching company", err);
      message.error("Ops, tivemos um erro ao carregar suas informações");
    }

    setLoading(false);
  }

  async function search (values: Store){
    setLoading(true)
    try{
      const { results } = await adminTutorClient.findByEmail(values.email)
      
      if(results.length > 0){
        form.setFieldsValue(results[0])
        setSearchedTeacherId(results[0].id)
      } else{
        form.setFieldsValue(values)
      }
      setEmailSearched(true)
    }catch (err) {
      message.error(err?.response?.data?.error?.message || 'Falha ao buscar ' + dict.profissional.singular);
    } finally {
      setLoading(false);
    }
  }

  async function addCompany(values: Store) {
    setLoading(true)
    try{
      const companyId = getCompanyId()

      const _values = {
        ...values,
        companyId: parseInt(companyId)
      }
      
      const response = searchedTeacherId ?
        await adminTutorClient.addCompany(parseInt(companyId), searchedTeacherId)
       : await adminTutorClient.create(_values);
       
      if (response) {
        message.success(capitalize(dict.profissional.singular) + ' adicionado com sucesso');
        afterSave();
      } else {
        message.error('Falha ao adicionar ' + dict.profissional.singular);
      }
    }catch (err) {
      message.error(err?.response?.data?.error?.message || 'Falha ao adicionar ' + dict.profissional.singular);
    } finally {
      setLoading(false);
    }
  }

  async function updatePassword(values: Store) {

    setPwdLoading(true)

    try {

      const response = await adminTutorClient.update(tutor.id, values)

      if (response) {
        message.success('Senha atualizada com sucesso');
        afterSave();
      } else {
        message.error('Falha ao atualizar senha');
      }

    } catch (err) {
      message.error(err?.response?.data?.error?.message || 'Falha ao atualizar senha');
    } finally {
      setPwdLoading(false);
    }

  }

  async function save (values: Store) {

    // if (form.getFieldValue('cpf')) {
    //   const isValidate = validateCPF(
    //     form.getFieldValue('cpf').replace(/\D/g, '')
    //   );
    //   if (!isValidate) {
    //     return message.error('O CPF informado é inválido.');
    //   }
    // }

    setLoading(true);

    try {

      const companyId = getCompanyId()

      const _values = { 
        ...values,
        companyId: tutor ? undefined : parseInt(companyId)
      }

      const response = tutor 
        ? await adminTutorClient.update(tutor.id, _values)
        : await adminTutorClient.create(_values);

      if (response) {
        message.success(capitalize(dict.profissional.singular) + ' salvo com sucesso');
        afterSave();
      } else {
        message.error('Falha ao salvar ' + dict.profissional.singular);
      }
    } catch (err) {
      message.error(err?.response?.data?.error?.message || 'Falha ao salvar ' + dict.profissional.singular);
    } finally {
      setLoading(false);
    }
  }
}

export default TutorForm;
