import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Divider,
  message,
  Skeleton,
  Tabs
} from 'antd';

import { studentClassClient } from '../../clients/student/StudentClassClient';
import { tutorClient } from '../../clients/tutor/TutorClient';
import TutorClassCard from '../../componentsTutor/TutorClassCard';
import { Balance } from '../../types/Teacher';
import Header from '../../components/Header';
import { useAuth } from '../../hooks/AuthHook';
import { IClass, ClassStatus } from '../../types/Class';
import { formatCurrency } from '../../utils/CurrencyUtils';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import style from './Home.module.scss';
import Calendar from '../../components/Calendar/Calendar';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';
import TutorIncompleteProfile from '../../componentsTutor/TutorIncompleteProfile';

const Home: React.FC = () => {

  const auth = useAuth();
  const [classes, setClasses] = useState<IClass[]>([]);
  const [loadingClasses, setLoadingClasses] = useState(true);
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [balance, setBalance] = useState<Balance>();
  const [upcomingClassesAsc, setUpcomingClassesAsc] = useState(false)
  const [requestedClassesAsc, setRequestedClassesAsc] = useState(false)
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    loadClasses();
    loadBalance();
  // eslint-disable-next-line
  }, []);


  return (
    <div className={style.homePage}>
      <Header />
      <TutorIncompleteProfile />
      <Tabs type='card'>
        <Tabs.TabPane tab='Lista' key='1'>
         {loadingBalance &&
            <>
              <Skeleton active />
            </>
          } 
          {!loadingBalance && balance &&
            <>
              <h1>
                Seu saldo na OOri:<br />
                <span className={style.balanceText}>
                  {formatCurrency(balance.available, 2)}
                </span>
              </h1>
            </>
          }
          <section className={style.classes}>
            <section className={style.classesSection}>
              <Divider orientation='left'>
                Agendamentos
                { upcomingClassesAsc &&
                  <>
                    <CaretUpOutlined style={{ marginLeft: '8px' }} onClick={sortUpcomingClasses}/>
                  </>
                }
                { !upcomingClassesAsc &&
                  <>
                    <CaretDownOutlined style={{ marginLeft: '8px' }} onClick={sortUpcomingClasses}/>
                  </>
                }
              </Divider>
              <div className={style.classList}>
                {loadingClasses &&
                  <>
                    <Skeleton active />
                    <Skeleton active />
                  </>
                }
                {!loadingClasses &&
                  classes
                    .filter((c: IClass) => [ClassStatus.SCHEDULED, ClassStatus.ACCEPTED, ClassStatus.ACTIVE].includes(c.statusId))
                    .sort((a,b)=> (upcomingClassesAsc ? a.scheduledOnUtc > b.scheduledOnUtc : a.scheduledOnUtc < b.scheduledOnUtc) ? 1 : -1)
                    .map((c: IClass) => (
                      <TutorClassCard
                        key={c.id}
                        currClass={c}
                        afterSave={loadClasses}
                      />
                ))}
                {!loadingClasses && classes
                  .filter((c: IClass) =>
                    [ClassStatus.SCHEDULED, ClassStatus.ACCEPTED, ClassStatus.ACTIVE].includes(c.statusId))
                  .length === 0
                  && <p>Não há agendamentos.</p>
                }
              </div>
            </section>
            <section className={style.classesSection}>
              <Divider orientation='left'>
                Solicitações
                { requestedClassesAsc &&
                  <>
                    <CaretUpOutlined style={{ marginLeft: '8px' }} onClick={sortRequestedClasses}/>
                  </>
                }
                { !requestedClassesAsc &&
                  <>
                    <CaretDownOutlined style={{ marginLeft: '8px' }} onClick={sortRequestedClasses}/>
                  </>
                }
              </Divider>
              <div className={style.classList}>
                {loadingClasses &&
                  <>
                    <Skeleton active />
                    <Skeleton active />
                  </>
                }
                {!loadingClasses &&
                  classes
                    .filter((c: IClass) => [ClassStatus.REQUESTED].includes(c.statusId))
                    .sort((a,b)=> (requestedClassesAsc ? a.scheduledOnUtc > b.scheduledOnUtc : a.scheduledOnUtc < b.scheduledOnUtc) ? 1 : -1)
                    .map((c: IClass) => (
                      <TutorClassCard
                        key={c.id}
                        currClass={c}
                        afterSave={loadClasses}
                      />
                ))}
                {!loadingClasses && classes
                  .filter((c: IClass) =>
                    [ClassStatus.REQUESTED].includes(c.statusId))
                  .length === 0
                  && <p>Não há solicitações.</p>
                }
              </div>
            </section>
          </section>
        </Tabs.TabPane>
        <Tabs.TabPane tab='Calendário' key='2'>
          <section className={style.calendar}>
            <Calendar classes={classes} />
          </section>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );

  async function loadClasses () {
    try {
      const companyId = getCompanyId()
      const { results, error } = await studentClassClient.findAll({
        teacherId: auth?.user?.id,
        companyId: companyId
      });

      if (error) {
        message.error(error.message || 'Falha ao carregar ' + dict.atendimento.plural);
        return;
      }

      setClasses(results.filter((c: IClass) => c.scheduledOnUtc));
    } finally {
      setLoadingClasses(false);
    }
  }

  async function loadBalance () {
    try {
      const { data } = await tutorClient.getBalance(auth.user.id);
      console.log(data)
      setBalance(data);
    } finally {
      setLoadingBalance(false)
    }
  }

  function sortUpcomingClasses(){
    setUpcomingClassesAsc(!upcomingClassesAsc)
  }

  function sortRequestedClasses(){
    setRequestedClassesAsc(!requestedClassesAsc)
  }

}

export default Home;
