import * as React from 'react';
import { useState } from 'react';
import { Input, message, Form } from 'antd';
import Button from '../../components/Button';
import { tutorClassClient } from '../../clients/tutor/TutorClassClient';

interface IPostClassForm {
  classId: string;
  postClass: string;
}

const PostClassForm: React.FC<IPostClassForm> = ({
  classId,
  postClass = ''
}) => {

  const [postClassInput, setPostClassInput] = useState(postClass);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Form layout='vertical'>
        <Form.Item label='Detalhes da pós-aula'>
          <Input.TextArea 
            rows={6} 
            value={postClassInput}
            onChange={(e) => setPostClassInput(e.target.value)}
          /> 
        </Form.Item>
        <Button 
          type='primary'
          onClick={savePostClass}
          loading={loading}>
          Salvar
        </Button>
      </Form>
    </>
  );

  async function savePostClass () {

    setLoading(true);

    try {
      const { error } = await tutorClassClient.update(classId, {
        postClass: postClassInput
      });

      if (error) {
        message.error(error.message || 'Falha ao salvar');
      }

      message.success('Pré aula salva com sucesso');

    } finally {
      setLoading(false);
    }

  }

}

export default PostClassForm;
