import * as React from "react";
import { useState, useEffect } from "react";
import { Tabs, message, Skeleton } from "antd";

import { studentClassClient } from "../../clients/student/StudentClassClient";
import { useAuth } from "../../hooks/AuthHook";
import { IClass, ClassStatus, ClassStatusLabel } from "../../types/Class";
// import { getPrimaryColor, getSecondaryColor, getHighlightColor } from '../../utils/CompanyConfigUtils';

import ClassCard from "../../components/ClassCard";

import style from "./ClassesPage.module.scss";
import Header from "../../components/Header";
import { CompanyDict } from "../../types/CompanyDict";
import { getCompanyDict, getCompanyId } from "../../utils/CompanyConfigUtils";

const { TabPane } = Tabs;

const StudentClassListPage: React.FC = () => {
  const auth = useAuth();
  const [classes, setClasses] = useState<IClass[]>([]);
  const [loading, setLoading] = useState(true);
  const dict: CompanyDict = getCompanyDict();

  useEffect(() => {
    loadClasses();
    // eslint-disable-next-line
  }, [auth.user]);

  return (
    <div className={style.classListPage}>
      <Header />
      <Tabs defaultActiveKey="scheduled" type="card" size="small">
        <TabPane tab="Agendamentos" key="scheduled">
          {loading && <Skeleton active />}
          {!loading && <ClassList status={ClassStatus.SCHEDULED} classes={classes} />}
        </TabPane>
        <TabPane tab="Concluído" key="done">
          <ClassList status={ClassStatus.DONE} classes={classes} />
        </TabPane>
        <TabPane tab="Solicitações" key="requested">
          <ClassList status={ClassStatus.REQUESTED} classes={classes} />
        </TabPane>
        <TabPane tab="Cancelamentos" key="canceled">
          <ClassList status={ClassStatus.CANCELED} classes={classes} />
        </TabPane>
        <TabPane tab="Reagendar" key="refused">
          <ClassList status={ClassStatus.REFUSED} classes={classes} />
        </TabPane>
      </Tabs>
    </div>
  );

  async function loadClasses() {
    try {
      const companyId = getCompanyId();
      const { results, error } = await studentClassClient.findAll({
        studentId: auth?.user?.id,
        companyId: companyId,
      });

      if (error) {
        message.error(error.message || "Falha ao carregar lista de " + dict.atendimento.singular);
        return;
      }

      const withSchedule = results.filter((c: IClass) => c.scheduledOnUtc);

      setClasses(withSchedule);
    } finally {
      setLoading(false);
    }
  }
};

interface IClassList {
  status: number;
  classes: IClass[];
}

const ClassList: React.FC<IClassList> = ({ status, classes = [] }) => {
  const filteredClasses = classes.filter((currClass) => currClass.statusId === status);

  return (
    <div className={style.list}>
      {filteredClasses.map((currClass) => (
        <ClassCard currClass={currClass} />
      ))}
      {!filteredClasses.length && <p>Não há nenhum atendimento {ClassStatusLabel[status]} no momento</p>}
    </div>
  );
};

export default StudentClassListPage;
