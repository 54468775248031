import { Spin } from 'antd';
import * as React from 'react';

import style from './LoadingOverlay.module.scss';

const LoadingOverlay: React.FC = () => {
  return (
    <div className={style.loaderOverlay}>
      <Spin tip='Carregando...' />
    </div>
  );
}

export default LoadingOverlay;
