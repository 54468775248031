import { axiosClient } from '../axiosHandler';
import { Options } from '../axiosTypes';
import { BASE_URL } from '../configClient';
import { AreaFilter } from '../../types/Area';

interface IAdminAreaClient {
  create(data: any): Promise<any>;
  update(id: string, data: any): Promise<any>;
  remove(id: string): Promise<any>;
  findById(id: string): Promise<any>;
  findAll(filter?: AreaFilter): Promise<any>;
  findByCompany(companyId: string): Promise<any>
}

const AdminAreaClient = (
  baseURL: string,
  options?: Options
): IAdminAreaClient => {

  const client = axiosClient(baseURL, options);

  return {
    create,
    update,
    remove,
    findById,
    findAll,
    findByCompany
  };

  async function create(area: any): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: '/area',
      data: area,
    });

    return data;
  }

  async function update(id: string, area: any): Promise<any> {
    const { data } = await client({
      method: 'put',
      url: '/area',
      data: {
        ...area,
        id,
      },
    });

    return data;
  }

  async function remove(id: string): Promise<any> {
    const { data } = await client({
      method: 'delete',
      url: `/area`,
      data: {
        id
      }
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/area/${id}`,
    });

    return data;
  }

  async function findAll(filter?: AreaFilter): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: '/area',
      params: filter || {}
    });

    return data;
  }

  async function findByCompany(companyId: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/area/company/${companyId}`,
    });

    return data;
  }


}

export const adminAreaClient = AdminAreaClient(BASE_URL);