import { axiosClient } from '../axiosHandler';
import { Options } from '../axiosTypes';
import { BASE_URL } from '../configClient';
import { AreaFilter } from '../../types/Area';

interface IStudentAreaClient {
  findById(id: string): Promise<any>;
  findAll(filter?: AreaFilter): Promise<any>;
  findBigAreaByCompany(companyId: string): Promise<any>;
  findAreaByCompany(companyId: string): Promise<any>;
}

const StudentAreaClient = (
  baseURL: string,
  options?: Options
): IStudentAreaClient => {

  const client = axiosClient(baseURL, options);

  return {
    findById,
    findAll,
    findBigAreaByCompany,
    findAreaByCompany
  };
  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/area/${id}`,
    });

    return data;
  }

  async function findAll(filter?: AreaFilter): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: '/area',
      params: filter || {}
    });

    return data;
  }

  async function findBigAreaByCompany(companyId: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/greatArea/company/${companyId}`
    });

    return data;
  }

  async function findAreaByCompany(companyId: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/area/company/${companyId}`
    });

    return data;
  }
}

export const studentAreaClient = StudentAreaClient(BASE_URL);