import { axiosClient } from '../axiosHandler';
import { Options } from '../axiosTypes';
import { BASE_URL } from '../configClient';
import { ISubjectFilter } from '../../types/Subject';

interface ITutorSubjectClient {
  create(data: any): Promise<any>;
  update(id: string, data: any): Promise<any>;
  remove(id: string): Promise<any>;
  findById(id: string): Promise<any>;
  findAll(filter?: ISubjectFilter): Promise<any>;
}

const TutorSubjectClient = (
  baseURL: string,
  options?: Options
): ITutorSubjectClient => {

  const client = axiosClient(baseURL, options);

  return {
    create,
    update,
    remove,
    findById,
    findAll
  };

  async function create(subject: any): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: '/subject',
      data: subject,
    });

    return data;
  }

  async function update(id: string, subject: any): Promise<any> {
    const { data } = await client({
      method: 'put',
      url: '/subject',
      data: {
        ...subject,
        id: id,
      },
    });

    return data;
  }

  async function remove(id: string): Promise<any> {
    const { data } = await client({
      method: 'delete',
      url: `/subject`,
      data: {
        id
      }
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/subject/${id}`,
    });

    return data;
  }

  async function findAll(filter?: ISubjectFilter): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: '/subject',
      params: {
        ...filter
      }
    });

    return data;
  }


}

export const tutorSubjectClient = TutorSubjectClient(BASE_URL);