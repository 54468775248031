import * as React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { getBtnTextColor, getPrimaryColor } from '../../utils/CompanyConfigUtils';

import './Button.scss';

const OoriButton: React.FC<ButtonProps> = (props) => {

  return (
    <Button 
      {...props}
      style = {{
        backgroundColor: getPrimaryColor(),
        borderColor: getPrimaryColor(),
        color: getBtnTextColor()
      }}
    />
  );
}

export default OoriButton;