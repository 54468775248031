import PaymentForm from "../../componentsStudent/PaymentForm";
import StudentTransactions from "../../componentsStudent/StudentTransactions";
import queryString from "query-string";
import style from "./StudentWalletPage.module.scss";
import { studentClient } from "../../clients/student/StudentClient";
import { message, Tabs, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/AuthHook";
import { useLocation } from "react-router-dom";
import * as React from "react";
import Header from "../../components/Header";

const { TabPane } = Tabs;

const StudentWalletPage: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState<any>();

  const { tab }: any = queryString.parse(location.search);

  useEffect(() => {
    loadStudent();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.wallet}>
      <Header />
      {loading && !student && <Skeleton active />}
      {!loading && student && (
        <>
          <Tabs type="card" size="small" defaultActiveKey={tab || "1"}>
            <TabPane tab="Dados de pagamento" key="1">
              <PaymentForm afterSave={loadStudent} student={student} />
            </TabPane>
            <TabPane tab="Transações" key="2">
              <StudentTransactions />
            </TabPane>
          </Tabs>
        </>
      )}
    </div>
  );

  async function loadStudent() {
    try {
      const { data, error } = await studentClient.findById(auth.user.id);

      if (error) {
        message.error(error.message || "Falha ao carregar informações.");
        return;
      }
      setStudent(data);
    } catch {
      message.error("Falha ao carregar informações.");
    } finally {
      setLoading(false);
    }
  }
};

export default StudentWalletPage;
