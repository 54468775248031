import { BASE_URL } from "../configClient";
import { axiosClient } from "../axiosHandler";
import { Options, ResponseAPI } from "../axiosTypes";
import { Balance, BalanceOperation, IPaymentInfo } from "../../types/Teacher";

interface IAdminCompany {
  findById(id: string): Promise<any>
  updateCompany(config: any): Promise<any>
  getTheme(): Promise<any>
  getPaymentInfo(id: string): Promise<any>
  setPaymentInfo(paymentInfo: Partial<IPaymentInfo>): Promise<any>
  updatePaymentInfo(paymentInfo: Partial<IPaymentInfo>): Promise<any>
  getBalance(id: string): Promise<ResponseAPI<Balance>>
  getBalanceOperations(id: string): Promise<ResponseAPI<BalanceOperation>>
}

const AdminCompanyClient = (baseURL: string, options?: Options): IAdminCompany => {
  const client = axiosClient(baseURL, options);

  return {
    findById,
    updateCompany,
    getTheme,
    getPaymentInfo,
    setPaymentInfo,
    updatePaymentInfo,
    getBalance,
    getBalanceOperations
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/company/${id}`,
    });

    return data;
  }

  async function updateCompany(config: any): Promise<any> {
    const { data } = await client({
      method: "put",
      url: "/company",
      data: {
        ...config
      }
    })
    return data;
  }

  async function getTheme(): Promise<any> {
    const { data } = await client({
      method: "get",
      url: "/company/theme"
    })
    return data;
  }

   async function getPaymentInfo(
    id: string
  ): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/company/${id}`
    });
     
     console.log(data);
     

    return data;
  }

  async function setPaymentInfo(
    paymentInfo: Partial<IPaymentInfo>,
  ): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: '/company/payment-info',
      data: paymentInfo,
    });

    return data;
  }

  async function updatePaymentInfo(
    paymentInfo: Partial<IPaymentInfo>,
  ): Promise<any> {
    const { data } = await client({
      method: 'put',
      url: '/company/payment-info',
      data: paymentInfo,
    });

    return data;
  }

  async function getBalance(id: string): Promise<ResponseAPI<Balance>> {
    const { data } = await client({
      method: 'get',
      url: `/company/balance/${id}`,
    });

    return data;
  }

  async function getBalanceOperations(id: string): Promise<ResponseAPI<BalanceOperation>> {
    const { data } = await client({
      method: 'get',
      url: `/company/balanceOperations/${id}`,
    });

    return data;
  }
};

export const adminCompanyClient = AdminCompanyClient(BASE_URL)