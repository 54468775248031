import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Drawer,
  Tabs, 
  Spin
} from 'antd';
import queryString from 'query-string';

import { adminStudentClient } from '../../clients/admin/AdminStudentClient';

import StudentForm from '../StudentForm';
import ClassTableList from '../ClassTableList';
import { format, parse } from 'date-fns';

interface IStudentViewDrawer {
  showEditForm: boolean;
  closeEditForm: () => void
}

const StudentViewDrawer: React.FC<IStudentViewDrawer> = ({
  showEditForm,
  closeEditForm
}) => {

  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState<any>();
  const { studentId }: any = queryString.parse(location.search);

  useEffect(() => {
    loadStudent();
  // eslint-disable-next-line
  }, []);


  return (
    <Drawer
        title='Aluno'
        visible={showEditForm}
        width={window.innerWidth < 600 ? '100%' : '60%'}
        destroyOnClose
        onClose={closeEditForm}
      >
        {loading && <Spin tip='Carregando' />}
        {!loading && student && 
          <Tabs defaultActiveKey='profile' type='card'>
            <Tabs.TabPane tab='Perfil' key='profile'>
              <StudentForm student={student} afterSave={loadStudent} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Histórico de Aulas' key='classes'>
              <ClassTableList userId={studentId} />
            </Tabs.TabPane>
          </Tabs>
        }
      </Drawer>
  );

  async function loadStudent () {
    const { data } = await adminStudentClient.findById(studentId);

    const _data = {
      ...data,
      phone: data.phone ? data.phone.slice(3) : '',
      birthday: data.birthday ? format(parse(data.birthday, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '',
    }


    setStudent(_data);
    setLoading(false);
  }
}

export default StudentViewDrawer;
