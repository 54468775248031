import { IClass, IClassFilter } from "../../types/Class";
import { axiosClient } from "../axiosHandler";
import { Options } from "../axiosTypes";
import { BASE_URL } from "../configClient";

interface IAdminClassClient {
  findAll(filter?: IClassFilter): Promise<any>;
  findById(id: string): Promise<any>;
  update(id: string, classPayload: Partial<IClass>): Promise<any>;
}

const AdminClassClient = (
  baseURL: string,
  options?: Options
): IAdminClassClient => {
  const client = axiosClient(baseURL, options);

  return {
    findAll,
    findById,
    update,
  };
  async function findAll(filter?: IClassFilter): Promise<any> {
    const { data } = await client({
      method: "get",
      url: "/class",
      params: {
        ...filter,
      },
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: "get",
      url: `/class/${id}`,
    });

    return data;
  }

  async function update(
    id: string,
    classPayload: Partial<IClass>
  ): Promise<any> {
    const { data } = await client({
      method: "put",
      url: `/class/${id}`,
      data: {
        ...classPayload,
        classId: id,
      },
    });

    return data;
  }
};

export const adminClassClient = AdminClassClient(BASE_URL);
