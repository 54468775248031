import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Drawer,
  Tabs,
  Spin
} from 'antd';
import queryString from 'query-string';

import TutorForm from '../TutorForm';

import { adminTutorClient } from '../../clients/admin/AdminTutorClient';
import ClassTableList from '../ClassTableList';
import { format, parse } from 'date-fns';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils';

interface ITutorViewDrawer {
  showEditForm: boolean;
  closeEditForm: () => void
}

const TutorViewDrawer: React.FC<ITutorViewDrawer> = ({
  showEditForm,
  closeEditForm
}) => {

  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [tutor, setTutor] = useState<any>();
  const { teacherId }: any = queryString.parse(location.search);
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    loadTutor();
  // eslint-disable-next-line
  }, []);

  return (
    <Drawer
      title={capitalize(dict.profissional.singular)}
      visible={showEditForm}
      width={window.innerWidth < 600 ? '100%' : '60%'}
      destroyOnClose
      onClose={closeEditForm}
    >
      {loading && <Spin tip='Carregando' />}
      {!loading && tutor &&
        <Tabs defaultActiveKey='profile' type='card'>
          <Tabs.TabPane tab='Perfil' key='profile'>
            <TutorForm tutor={tutor} afterSave={loadTutor} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Histórico de ' + dict.atendimento.plural} key='classes'>
          <ClassTableList userId={teacherId} userType={'teacher'} />
          </Tabs.TabPane>
        </Tabs>
      } 
    </Drawer>
  );

  async function loadTutor () {
    const { data }  = await adminTutorClient.findById(teacherId);

    const _data = {
      ...data,
      phone: data.phone ? data.phone.slice(3) : '',
      birthDate: data.birthDate ? format(parse(data.birthDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '',
    }

    setTutor(_data);
    setLoading(false);
  }

}

export default TutorViewDrawer;
