import * as React from "react";
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import style from './BackButton.module.scss';
import { useHistory, useLocation } from 'react-router-dom';

const BackButton: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    return(
        <div>
            {window.innerWidth < 500 &&
                <Button shape='circle' size='small' className={style.backButtonStyle} icon={<LeftOutlined />} onClick={goBack} />
            }
              {window.innerWidth > 500 &&
                <Button className={style.backButtonStyle} icon={<LeftOutlined />} onClick={goBack}>
                    Voltar
                </Button>
            }
        
        </div>
    );

    function goBack(){
        const pushTo = location.pathname.includes('profissional')
            ? '/profissional'
            : '/login'; 
        
        history.push(pushTo)
      }
};

export default BackButton;