import * as React from 'react';
import { 
    Form,
    Input,
    Divider,
    message,
    Result
  } from 'antd';
import style from './ForgotPasswordForm.module.scss';
import Button from '../Button';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import { studentClient } from '../../clients/student/StudentClient';
import { tutorClient } from '../../clients/tutor/TutorClient';
import { getCompanyDict, getLogoPath } from '../../utils/CompanyConfigUtils';
import { CompanyDict } from '../../types/CompanyDict';
import { capitalize } from '@material-ui/core';

const ForgotPasswordForm: React.FC = () => {
    const location = useLocation();
    const [emailSent, setEmailSent] = useState(false);
    const [emailAddress, setEmailAddress] = useState<string>()
    const [loading, setLoading] = useState(false);
    const logoPath = getLogoPath()
    const dict: CompanyDict = getCompanyDict()

    return (
        <Form 
        onFinish={sendEmail}
        layout='vertical'
        className={style.inputEmailForm}>
        <img src={logoPath} alt=''/>
  
        {location.pathname.includes('profissional') &&
          <h2>{capitalize(dict.profissional.singular)}</h2>
        }
  
        <Divider />

        <Result
          style={{display: emailSent ? 'block' : 'none' }}
          status="success"
          title="E-mail enviado! 🤙"
          subTitle={
            <>
              <p>Você receberá um e-mail em: </p>
              <strong> {emailAddress} </strong>
              <p>com as instruções para redefinição de senha.</p>
            </>
          }
        />

        <div 
          className={style.inputEmailFormContainer} 
          style={{display: emailSent ? 'none' : 'flex' }}>
          <h1><strong>Esqueceu sua senha?</strong></h1>
          <h2>Informe o e-mail cadastrado para envio do link de recuperação.</h2>
            <Form.Item 
              label='E-mail'
              name='email'>
              <Input />
            </Form.Item>
            
            <Button 
            type='primary' 
            htmlType='submit'
            loading={loading}>
              Enviar
            </Button>
        </div>
      </Form>
    );

    async function sendEmail (values: Store) {
      const { email } = values;
      setLoading(true);
      try{
        if(location.pathname.includes('profissional')){
          await tutorClient.recoverPassword(email)
        }else{
          await studentClient.recoverPassword(email)
        }
        setEmailAddress(email)
        setEmailSent(true)
      }catch{
        message.error('Erro no envio de e-mail.');
      }finally {
        setLoading(false);
      }
    }
};

export default ForgotPasswordForm;
