import * as React from 'react';
import { useState, useEffect } from 'react';
import { Divider, Result, Skeleton } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { messageClient } from '../../clients/message/MessageClient';
import { IChat } from '../../types/Chat';
import { useAuth } from '../../hooks/AuthHook';
import ChatCard from '../../components/ChatCard';

import style from './MessagesPage.module.scss';
import ChatPage from '../../pages/ChatPage';
import { SmileOutlined } from '@ant-design/icons';
import Header from '../../components/Header';
import { getCompanyId } from '../../utils/CompanyConfigUtils';

const TutorMessagesPage: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [currChat, setCurrChat] = useState<IChat>();
  const [chats, setChats] = useState<IChat[]>([]);
  const [loading, setLoading] = useState(true);

  const params = new URLSearchParams(location.search);
  const studentIdParams = params.get('studentId');

  useEffect(() => {
    loadChat();
    //eslint-disable-next-line
  }, []);

  return (
    <div className={style.messagesPage}>
      <Header />
      <Divider orientation='left'>Conversas</Divider>
      {loading && <Skeleton active />}
      {!loading && 
        <div className={style.body}>
          <div className={style.list}>
            {chats.map((chat: IChat) => (
              <ChatCard 
                key={chat.id} 
                chat={chat}
                setChat={updateChat}
                selected={chat.id === currChat?.id}
              />
            ))}
          </div>
          <div className={currChat ? [style.chat, style.visible].join(' ') : style.chat}>
            {!currChat && 
              <Result
                icon={<SmileOutlined />}
                title='Aqui você pode acompanhar todas suas conversas'
                subTitle='Selecione ao lado com quem você deseja conversar!'
              />
            }
            {currChat && 
              <ChatPage 
                chat={currChat} 
                closeChat={closeChat}
              />
            }
          </div>
        </div>
      }
      
    </div>
  );

  async function loadChat() {
    const companyId = getCompanyId()

    const { results } = await messageClient.getChats({
      teacherId: auth.user.id,
      companyId: parseInt(companyId)
    });

    setChats(results);

    if (studentIdParams) {
      const defaultOpenedChat = results.find((chat: IChat) => chat.studentId == studentIdParams);
      
      setCurrChat(defaultOpenedChat);
    }

    setLoading(false);
  }

  function updateChat (chat: IChat) {

    const queryValues = queryString.parse(location.search);
    delete queryValues.teacherId;
    history.push({
      search: new URLSearchParams(
        queryString.stringify(queryValues)
      ).toString(),
    });

    setCurrChat(chat);
  }

  function closeChat () {
    setCurrChat(undefined);
  }
}

export default TutorMessagesPage;
