import * as React from 'react';
import { useState } from 'react';
import { Avatar, Divider, message } from 'antd';
import { MessageOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import defaultAvatar from '../../assets/defaultAvatar.jpeg';
import { ITeacher } from '../../types/Teacher';
import style from './TeacherProfile.module.scss';
import { getFirstName } from '../../utils/StrUtils';
import Button from '../../components/Button';
import { messageClient } from '../../clients/message/MessageClient';
import { useAuth } from '../../hooks/AuthHook';
import { getCompanyId, getSecondaryColor } from '../../utils/CompanyConfigUtils';

interface ITeacherProfile {
  teacher: ITeacher;
}

const TeacherProfile: React.FC<ITeacherProfile> = ({
  teacher
}) => {

  const auth = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <div className={style.teacherProfile}>
      <div className={style.header}>
        <LeftCircleOutlined 
          twoToneColor={ getSecondaryColor() }
          style={{fontSize: 24}}
          onClick={goBack}
        />
        <Avatar src={teacher.profileImageUrl || defaultAvatar} size={64} />
        <h2>{teacher.name}</h2>
      </div>
      <div className={style.content}>
        <Divider orientation='left'>Biografia</Divider>
        <p>{teacher.bio}</p>
        <Divider orientation='left'>Certificado</Divider>
        <p>{teacher.certificates}</p>
      </div>
      <Button
        type='primary'
        icon={<MessageOutlined />}
        onClick={goToChat}
        loading={loading}
      >Falar com {getFirstName(teacher.name)}</Button>
    </div>
  );

  function goBack () {
    history.goBack();
  }

  async function goToChat () {

    setLoading(true);

    try {

      const companyId = getCompanyId()
     
      const { results } = await messageClient.getChats({
        studentId: auth.user.id,
        teacherId: teacher.id,
        companyId: parseInt(companyId)
      });

      if(results.length > 0) {
        history.push(`/mensagens?teacherId=${teacher.id}`);
        return;
      }

      const { error } = await messageClient.createChat({
        studentId: auth.user.id,
        teacherId: teacher.id,
        companyId: parseInt(companyId)
      });
  
      if (!error) {
        history.push(`/mensagens?teacherId=${teacher.id}`);
        return;
      }

      message.error('Oops, não foi possível iniciar uma conversa. Por favor, tente novamente mais tarde');
    } finally {
      setLoading(false);
    }
  }
}

export default TeacherProfile;
