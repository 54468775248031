import { CameraOutlined } from "@ant-design/icons";
import { Avatar, message, Skeleton, Tabs, Upload } from "antd";
import { format, parse } from "date-fns";
import queryString from "query-string";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import defaultAvatar from "../../assets/defaultAvatar.jpeg";
import { studentClient } from "../../clients/student/StudentClient";
import { Button } from "antd";
import Header from "../../components/Header";
import AddressForm from "../../componentsStudent/AddressForm";
import ProfileForm from "../../componentsStudent/ProfileForm";
import { useAuth } from "../../hooks/AuthHook";
import style from "./StudentProfilePage.module.scss";
import { BASE_URL } from "../../clients/configClient";

const { TabPane } = Tabs;

const StudentProfilePage: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState<any>();
  const [avatarUrl, setAvatarUrl] = useState();
  const [loadingAvatar, setLoadingAvatar] = useState(false);

  const { tab }: any = queryString.parse(location.search);

  useEffect(() => {
    loadStudent();
  // eslint-disable-next-line
  }, []);

  const props = {
    name: "file",
    action: `${BASE_URL}/asset`,
    showUploadList: false,
    onChange: onChangePicture,
  };

  return (
    <div className={style.profile}>
      <Header />
      {loading && !student && <Skeleton active />}
      {!loading && student && (
        <>
          <Tabs type="card" size="small" defaultActiveKey={tab || "1"}>
            <TabPane tab="Perfil" key="1">
              <div className={style.avatarSection}>
                <Avatar size={64} src={avatarUrl || defaultAvatar} />
                <Upload {...props}>
                  <Button loading={loadingAvatar}>
                    <CameraOutlined /> Trocar foto
                  </Button>
                </Upload>
              </div>
              <ProfileForm student={student} afterSave={loadStudent} />
            </TabPane>
            <TabPane tab="Endereço" key="2">
              <AddressForm student={student} afterSave={loadStudent} />
            </TabPane>
          </Tabs>
        </>
      )}
    </div>
  );

  async function loadStudent() {
    try {
      const { data, error } = await studentClient.findById(auth.user.id);

      if (error) {
        message.error(error.message || "Falha ao carregar informações.");
        return;
      }

      setStudent({
        ...data,
        phone: data.phone ? data.phone.slice(3) : '',
        birthDate: data.birthDate ? format(parse(data.birthDate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : '',
      });
      setAvatarUrl(data.profileImageUrl || "");
    } catch {
      message.error("Falha ao carregar informações.");
    } finally {
      setLoading(false);
    }
  }

  async function onChangePicture(info) {
    setLoadingAvatar(true);
    if (info.file.status === "done") {
      setAvatarUrl(info.file.response.data.url);
      const response = await studentClient.update(auth.user.id, {
        profileImageUrl: info.file.response.data.url,
      });

      if (response) {
        message.success("Salvo com sucesso");
        setLoadingAvatar(false);
      } else {
        message.error("Falha ao salvar avatar");
        setLoadingAvatar(false);
      }
    } else if (info.file.status === "error") {
      message.error(`Falha ao fazer o upload.`);
      setLoadingAvatar(false);
    }
  }
};

export default StudentProfilePage;
