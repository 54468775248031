import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Divider,
  Row,
  Col,
  Skeleton,
  message,
  Tabs
} from 'antd';

import { 
  IdcardOutlined,
  BookOutlined,
  WalletOutlined,
  SettingOutlined
} from '@ant-design/icons'

import Header from '../../components/Header';
import { useAuth } from '../../hooks/AuthHook';
import { IClass } from '../../types/Class';
import { studentClassClient } from '../../clients/student/StudentClassClient';
import { adminClient } from '../../clients/admin/AdminClient';

import style from './Home.module.scss';
import Calendar from '../../components/Calendar/Calendar';
import DashboardCardButton from '../../components/DashboardCardButton';
import DashboardStatCard from '../../components/DashboardStatCard';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils'
import { IDashboardData } from '../../types/Dashboard';
import { CompanyDict } from '../../types/CompanyDict';
import AdminIncompleteProfile from '../../componentsAdmin/AdminIncompleteProfile';

const Home: React.FC = () => {

  const auth = useAuth();
  const [dashboardData, setDashboardData] = useState<IDashboardData>()
  const [classes, setClasses] = useState<IClass[]>([]);
  const [loading, setLoading] = useState(true);
  const dict: CompanyDict = getCompanyDict();
  
  useEffect(() => {
    loadClasses()
    loadDashboard()
    // eslint-disable-next-line
  }, []);

  const teachersCardButton = {
    title: capitalize(dict.profissional.plural),
    description: "Gerencie a sua equipe",
    actionLink: "/admin/profissionais",
    icon: <IdcardOutlined style={{ fontSize: 20 }}/>
  }

  const classesCardButton = {
    title: capitalize(dict.atendimento.plural),
    description: "Veja detalhes dos atendimento da sua equipe",
    actionLink: "/admin/atendimentos",
    icon: <BookOutlined style={{ fontSize: 20 }}/>
  }

  const walletCardButton = {
    title: "Carteira",
    description: "Edite seus dados de pagamento e consulte seu extrato OOri",
    actionLink: "/admin/carteira",
    icon: <WalletOutlined style={{ fontSize: 20 }}/>
  }

  const themeSettingsCardButton = {
    title: "Personalização",
    description: "Deixe seu ambiente OOri com a sua cara",
    actionLink: "/admin/personalizacao",
    icon: <SettingOutlined style={{ fontSize: 20 }}/>
  }

  return (
    <div className={style.homePage}>
      <Header />
      <AdminIncompleteProfile />
      <Tabs type='card'>
        <Tabs.TabPane tab='Painel de controle' key='1'>
          <Divider orientation='left'>
            Visão geral - últimos 30 dias
          </Divider>
          {loading &&
            <>
              <Skeleton active />
            </>
          } 
          {!loading && dashboardData &&
            <>
            {window.innerWidth < 500 && 
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around'
              }}>
              {/* <Row gutter={[16, 40]}> */}
                <DashboardStatCard 
                  title="Solicitações"
                  content={dashboardData.requestedClasses.toString()} />
                <DashboardStatCard 
                  title="Agendamentos"
                  content={dashboardData.scheduledClasses.toString()} />
              {/* </Row> */}
              {/* <Row gutter={[16, 40]}> */}
                <DashboardStatCard 
                  title="Cancelamentos"
                  content={dashboardData.cancelledClasses.toString()} />
                <DashboardStatCard 
                  title="Concluídos"
                  content={dashboardData.doneClasses.toString()} />
              {/* </Row> */}
              {/* <Row gutter={[16, 40]}> */}
                <DashboardStatCard 
                  title="Taxa de conclusão"
                  content={dashboardData.doneRatio.toString().concat("%")} />
                <DashboardStatCard 
                  title="Taxa de cancelamento"
                  content={dashboardData.cancellationRatio.toString().concat("%")} />
              {/* </Row> */}
              </div>
            }

            {window.innerWidth >= 500 && 
              <>
                <Row gutter={[16, 40]}>
                  <Col span={8}>
                    <DashboardStatCard 
                      title="Solicitações"
                      content={dashboardData.requestedClasses.toString()} />
                  </Col>
                  <Col span={8}>
                    <DashboardStatCard 
                      title="Agendamentos"
                      content={dashboardData.scheduledClasses.toString()} />
                  </Col>
                  <Col span={8}>
                    <DashboardStatCard 
                      title="Cancelamentos"
                      content={dashboardData.cancelledClasses.toString()} />
                  </Col>
                </Row>
                <Row gutter={[16, 40]}>
                  <Col span={8}>
                    <DashboardStatCard 
                      title="Concluídos"
                      content={dashboardData.doneClasses.toString()} />
                  </Col>
                  <Col span={8}>
                    <DashboardStatCard 
                      title="Taxa de conclusão"
                      content={dashboardData.doneRatio.toString().concat("%")} />
                  </Col>
                  <Col span={8}>
                    <DashboardStatCard 
                      title="Taxa de cancelamento"
                      content={dashboardData.cancellationRatio.toString().concat("%")} />
                  </Col>
                </Row>
              </>
            }
            </>
          }
          <Divider orientation='left'>
            Detalhes
          </Divider>

          {window.innerWidth < 500 &&
            <div style={{ padding: '0 8px' }}>
            <DashboardCardButton 
              title={teachersCardButton.title}
              description={teachersCardButton.description}
              actionLink={teachersCardButton.actionLink}
              icon={teachersCardButton.icon} />
        
            <DashboardCardButton 
              title={classesCardButton.title}
              description={classesCardButton.description}
              actionLink={classesCardButton.actionLink}
              icon={classesCardButton.icon} />
          
            <DashboardCardButton 
              title={walletCardButton.title}
              description={walletCardButton.description}
              actionLink={walletCardButton.actionLink}
              icon={walletCardButton.icon} />
          
            <DashboardCardButton 
              title={themeSettingsCardButton.title}
              description={themeSettingsCardButton.description}
              actionLink={themeSettingsCardButton.actionLink}
              icon={themeSettingsCardButton.icon} />
          </div>
          }

          {window.innerWidth > 500 &&
          <>
            <Row gutter={[16, 40]}>
            <Col span={8}>
              <DashboardCardButton 
                title={teachersCardButton.title}
                description={teachersCardButton.description}
                actionLink={teachersCardButton.actionLink}
                icon={teachersCardButton.icon} />
            </Col>
            <Col span={8}>
              <DashboardCardButton 
                title={classesCardButton.title}
                description={classesCardButton.description}
                actionLink={classesCardButton.actionLink}
                icon={classesCardButton.icon} />
            </Col>
            <Col span={8}>
              <DashboardCardButton 
                title={walletCardButton.title}
                description={walletCardButton.description}
                actionLink={walletCardButton.actionLink}
                icon={walletCardButton.icon} />
            </Col>
          </Row>
    
          <Row gutter={[16, 40]}>
            <Col span={8}>
              <DashboardCardButton 
                title={themeSettingsCardButton.title}
                description={themeSettingsCardButton.description}
                actionLink={themeSettingsCardButton.actionLink}
                icon={themeSettingsCardButton.icon} />
            </Col>
          </Row>
          </>
        }

        </Tabs.TabPane>
        <Tabs.TabPane tab='Calendário' key='2'>
          <section className={style.calendar}>
            <Calendar classes={classes} />
          </section>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );

  async function loadDashboard(){
    try{
      const companyId = getCompanyId()
      if(companyId !== ""){
        const { data } = await adminClient.getDashboard(companyId)
        setDashboardData(data)
      }
    }finally {
      setLoading(false);
    }
  }

  async function loadClasses() {
    try {
      const companyId = getCompanyId()
      const { results, error } = await studentClassClient.findAll({
        companyId
      });

      if (error) {
        message.error(error.message || 'Falha ao carregar ' + dict.atendimento.plural);
        return;
      }

      setClasses(results);
    } finally {
      setLoading(false);
    }
  }

}

export default Home;