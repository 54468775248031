import { ClassStatus, IClass, IClassFilter } from "../../types/Class";
import { axiosClient } from "../axiosHandler";
import { Options } from "../axiosTypes";
import { BASE_URL } from "../configClient";

interface IStudentClassClient {
  schedule(classPayload: Partial<IClass>): Promise<any>;
  startClass(classId: string, studentId: string): Promise<any>;
  findAll(filter?: any): Promise<any>;
  findById(id: string): Promise<any>;
  update(id: string, classPayload: Partial<IClass>): Promise<any>;
  getCancelDisclaimer(classId: string, studentId: string): Promise<any>;
  cancel(id: string, studentId: string): Promise<any>;
}

const StudentClassClient = (baseURL: string, options?: Options): IStudentClassClient => {
  const client = axiosClient(baseURL, options);

  return {
    schedule,
    startClass,
    findAll,
    findById,
    update,
    getCancelDisclaimer,
    cancel,
  };

  async function schedule(classPayload: Partial<IClass>): Promise<any> {
    const { data } = await client({
      method: "post",
      url: `/class`,
      data: {
        ...classPayload,
      },
    });

    return data;
  }

  async function startClass(classId: string, studentId: string): Promise<any> {
    const { data } = await client({
      method: "put",
      url: `/class/status`,
      data: {
        classId,
        studentId,
        statusId: ClassStatus.ACCEPTED,
      },
    });

    return data;
  }

  async function findAll(filter?: any): Promise<any> {
    const { data } = await client({
      method: "get",
      url: "/class",
      params: {
        ...filter,
      },
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: "get",
      url: `/class/${id}`,
    });

    return data;
  }

  async function update(id: string, classPayload: Partial<IClass>): Promise<any> {
    const { data } = await client({
      method: "put",
      url: `/class`,
      data: {
        ...classPayload,
        id,
      },
    });

    return data;
  }

  async function getCancelDisclaimer(classId: string, studentId: string): Promise<any> {
    const { data } = await client({
      method: "get",
      url: `/class/cancel`,
      params: {
        classId,
        studentId,
      },
    });

    return data;
  }

  async function cancel(id: string, studentId: string): Promise<any> {
    // NOTE: studentId parameter was deprecated but left here because it might be needed in the future when logging which student requested the cancellation

    const { data } = await client({
      method: "put",
      url: `/class`,
      data: {
        statusId: ClassStatus.CANCELED,
        id: id,
      },
    });

    return data;
  }
};

export const studentClassClient = StudentClassClient(BASE_URL);
