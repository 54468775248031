import { axiosClient } from '../axiosHandler';
import { Options } from '../axiosTypes';
import { BASE_URL } from '../configClient';

import { IClass, IClassFilter, ClassStatus } from '../../types/Class';

interface ITutorClassClient {
  create(data: Partial<IClass>): Promise<any>;
  update(id: string, data: Partial<IClass>): Promise<any>;
  startClass(classId: string, teacherId: string): Promise<any>;
  remove(id: string): Promise<any>;
  findById(id: string): Promise<any>;
  findAll(filter?: any): Promise<any>;
  checkout(classId: string): Promise<any>;
  getCancelDisclaimer(
    classId: string,
    studentId: string,
  ): Promise<any>;
  cancel(
    id: string,
    teacherId: string,
  ): Promise<any>
}

const TutorClassClient = (
  baseURL: string,
  options?: Options
): ITutorClassClient => {

  const client = axiosClient(baseURL, options);

  return {
    create,
    update,
    startClass,
    remove,
    findById,
    findAll,
    checkout,
    getCancelDisclaimer,
    cancel
  };

  async function create(classPayload: any): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: '/class',
      data: classPayload,
    });

    return data;
  }

  async function update(id: string, classPayload: any): Promise<any> {
    const { data } = await client({
      method: 'put',
      url: '/class',
      data: {
        ...classPayload,
        id: id,
      },
    });

    return data;
  }

  async function startClass(classId: string, teacherId: string): Promise<any> {
    const { data } = await client({
      method: 'put',
      url: `/class/status`,
      data: {
        classId,
        teacherId,
        statusId: ClassStatus.ACCEPTED
      }
    });

    return data;
  }

  async function remove(id: string): Promise<any> {
    const { data } = await client({
      method: 'delete',
      url: `/class/${id}`,
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/class/${id}`,
    });

    return data;
  }

  async function findAll(filter?: any): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: '/class',
      params: {
        ...filter
      }
    });

    return data;
  }

  async function checkout(classId: string): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: '/payment/checkout',
      data: {
        classId
      }
    });

    return data;
  }

  async function getCancelDisclaimer(
    classId: string,
    teacherId: string,
  ): Promise<any> {
    const { data } = await client({
      method: "get",
      url: `/class/cancel`,
      params: {
        classId, teacherId
      }
    });

    return data;
  }

  async function cancel(
    id: string,
    teacherId: string,
  ): Promise<any> {
    const { data } = await client({
      method: "put",
      url: `/class`,
      data: {
        id,
        statusId: ClassStatus.CANCELED,
      },
    });

    return data;
  }

}

export const tutorClassClient = TutorClassClient(BASE_URL);
