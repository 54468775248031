import { SaveOutlined } from "@ant-design/icons";
import { Col, Form, Input, message, Row, Radio, Alert } from "antd";
import MaskedInput from "antd-mask-input";
import { Store } from "antd/lib/form/interface";
import pagarme from "pagarme/browser";
import * as React from "react";
import { useState, useEffect } from "react";
import { studentClient } from "../../clients/student/StudentClient";
import OoriButton from "../../components/Button";
import PaymentMethod from "../PaymentMethod";
import { Button } from "antd";
import { useAuth } from "../../hooks/AuthHook";
import { PAGARME_KEY } from "../../clients/configClient";
import creditCardImg from "../../assets/credit_card.jpeg";
import boletoImg from "../../assets/boleto.jpeg";
// import { getPrimaryColor, getSecondaryColor, getHighlightColor } from '../../utils/CompanyConfigUtils';

import style from "./PaymentForm.module.scss";
interface IPaymentForm {
  afterSave: () => void;
  student: any;
}

const PaymentForm: React.FC<IPaymentForm> = ({ afterSave, student }) => {
  const auth = useAuth();
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(student.paymentMethod || "none");
  const [showRegisterNewCard, setShowRegisterNewCard] = useState(false);

  const paymentOptions = [
    { label: "Cartão de crédito", value: "credit_card" },
    { label: "Boleto", value: "boleto" },
  ];

  useEffect(() => {
    if (student.paymentMethod) {
      setPaymentMethod(student.paymentMethod);
    }
  }, [student.paymentMethod]);

  useEffect(() => {
    if (paymentMethod === "boleto") {
      setShowRegisterNewCard(false);
    }
  }, [paymentMethod]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={save}
      initialValues={{
        paymentMethod,
      }}
      className={style.paymentForm}
    >
      <Row>
        <Col span={12}>
          <Form.Item label="Método de pagamento" name="paymentMethod">
            <div className={style.payment_method}>
              <PaymentMethod
                title="Cartão de crédito"
                description="Cadastre um cartão para realizar seu pagamento"
                value="credit_card"
                initialValue={paymentMethod === "credit_card"}
                img={creditCardImg}
                setPaymentMethod={setPaymentMethod}
              />

              <div className={paymentMethod === "credit_card" ? style.animated : "none"}>
                {paymentMethod === "credit_card" && (
                  <>
                    {student && student.creditCard ? (
                      <div className={style.card} id="Credit-card-view">
                        <strong>Cartão cadastrado</strong>
                        <ul>
                          <li>Nº do cartão: **** **** **** {student.creditCard.lastDigits}</li>
                          <li>{student.creditCard.brand.toUpperCase()}</li>
                        </ul>
                      </div>
                    ) : (
                      <div id="no-card-warnig" style={showRegisterNewCard ? { display: "none" } : {}}>
                        <Alert type="warning" message="Você não possui um cartão cadastro no momento." />{" "}
                      </div>
                    )}

                    {showRegisterNewCard && (
                      <div className={showRegisterNewCard ? style.animated : "none"}>
                        <div id="card-warning">
                          <Alert type="warning" message="Ao alterar os dados do seu cartão você perderá os dados atuais." />{" "}
                        </div>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              name="card_number"
                              label="Nº do cartão"
                              rules={[
                                {
                                  required: true,
                                  message: "Nº do cartão é obrigatório",
                                },
                              ]}
                            >
                              <MaskedInput mask="1111 1111 1111 1111" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              name="card_holder_name"
                              label="Nome do titular"
                              rules={[
                                {
                                  required: true,
                                  message: "Nome é obrigatório",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col span={12}>
                            <Form.Item
                              name="card_expiration_date"
                              label="Mês de expiração"
                              rules={[
                                {
                                  required: true,
                                  message: "Mês de expiração é obrigatório",
                                },
                              ]}
                            >
                              <MaskedInput mask="11/11" placeholder="01/25" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="card_cvv"
                              label="CVV"
                              rules={[
                                {
                                  required: true,
                                  message: "CVV é obrigatório",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </>
                )}
              </div>

              <PaymentMethod
                title="Boleto"
                description="Gere um boleto para pagamento"
                value="boleto"
                img={boletoImg}
                initialValue={paymentMethod === "boleto"}
                setPaymentMethod={setPaymentMethod}
              />

              <div className={paymentMethod === "boleto" ? style.animated : "none"}>
                {paymentMethod === "boleto" && (
                  <div id="boleto-warning">
                    <Alert
                      type="info"
                      message='Ao escolher o método de pagamento "boleto" ao final de todo atendimento será gerado um novo boleto com 3 dias de
                  vencimento a partir da data do atendimento, além disso será adicionado uma taxa de R$ 3,50 por boleto.'
                    />
                    <br />
                  </div>
                )}
              </div>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <br />
      <div className={style.buttons}>
        {(student?.cardId || paymentMethod === "credit_card" || paymentMethod === "boleto") && (
          <OoriButton icon={<SaveOutlined />} type="primary" htmlType="submit" loading={saveLoading}>
            Salvar
          </OoriButton>
        )}
        {paymentMethod === "credit_card" && !showRegisterNewCard && <Button onClick={toggleRegisterNewCard}>Cadastrar novo cartão</Button>}
      </div>
    </Form>
  );

  function toggleRegisterNewCard(e) {
    e.preventDefault();
    setShowRegisterNewCard(!showRegisterNewCard);
  }

  async function save(values: Store) {
    setSaveLoading(true);

    try {
      if (paymentMethod === "credit_card") {
        const [card_expiration_month, card_expiration_year] = values.card_expiration_date.split("/");
        const validationCard = {
          card_number: values.card_number,
          card_holder_name: values.card_holder_name,
          card_cvv: values.card_cvv,
          card_expiration_month,
          card_expiration_year,
        };
        const cardValidations = pagarme.validate({ card: validationCard });
        if (!cardValidations.card.card_number) {
          message.error("Número do cartão inválido");
          setSaveLoading(false);
          return;
        }

        if (!cardValidations.card.card_holder_name) {
          message.error("Nome do titular inválido");
          setSaveLoading(false);
          return;
        }

        if (!cardValidations.card.card_cvv) {
          message.error("Código de segurança inválido.");
          setSaveLoading(false);
          return;
        }

        pagarme.client
          .connect({ encryption_key: PAGARME_KEY })
          .then((client) => client.security.encrypt(values))
          .then(async (card_hash) => {
            try {
              const response = await studentClient.registerCard({
                studentId: auth.user.id,
                cardHash: card_hash,
              });

              if (response) {
                message.success("Salvo com sucesso");
                afterSave();
                setSaveLoading(false);
                window.location.reload();
              }
            } catch (err) {
              console.log(err);
              message.error("Falha ao registrar o cartão");
              setSaveLoading(false);
            }
          });
      } else {
        const { error } = await studentClient.update(auth.user.id, {
          paymentMethod,
        });
        if (error) {
          message.success("Falha ao salvar");
          return;
        }

        afterSave();
        setSaveLoading(false);
        message.success("Salvo com sucesso");
      }
    } catch {
      message.error("Falha ao salvar dados");
      setSaveLoading(false);
    }
  }

  async function savePaymentAsCreditCard(values: Store) {
    if (creditCardIsValid(values)) {
      try {
        pagarme.client
          .connect({ encryption_key: PAGARME_KEY })
          .then((client) => client.security.encrypt(values))
          .then(async (cardHash) => await saveCard(cardHash))
          .then(async () => await studentClient.update(auth.user.id, { paymentMethod }))
          .then(() => {
            afterSave();
            setSaveLoading(false);
            message.success("Salvo com sucesso");
          });
      } catch (err) {
        console.log(err);
        message.error("Falha ao salvar dados");
      }
    }
  }

  function creditCardIsValid(values: Store): boolean {
    const [card_expiration_month, card_expiration_year] = values.card_expiration_date.split("/");

    const cardToValidate = {
      card_number: values.card_number,
      card_holder_name: values.card_holder_name,
      card_cvv: values.card_cvv,
      card_expiration_month,
      card_expiration_year,
    };

    const cardValidations = pagarme.validate({ card: cardToValidate });

    if (!cardValidations.card.card_number) {
      message.error("Número do cartão inválido");
      setSaveLoading(false);
      return false;
    }

    if (!cardValidations.card.card_holder_name) {
      message.error("Nome do titular inválido");
      setSaveLoading(false);
      return false;
    }

    if (!cardValidations.card.card_cvv) {
      message.error("Código de segurança inválido.");
      setSaveLoading(false);
      return false;
    }

    return true;
  }

  async function saveCard(cardHash) {
    try {
      if (student.creditCard) {
        await studentClient.updateCard({
          studentId: auth.user.id,
          cardHash: cardHash,
          id: student.creditCard.id,
        });
      } else {
        await studentClient.registerCard({
          studentId: auth.user.id,
          cardHash: cardHash,
        });
      }
    } catch {
      message.error("Falha ao registrar o cartão");
      setSaveLoading(false);
    }
  }

  async function savePaymentAsBoleto() {
    try {
      await studentClient.update(auth.user.id, {
        paymentMethod,
      });

      afterSave();
      setSaveLoading(false);
      message.success("Salvo com sucesso");
    } catch {
      message.error("Falha ao salvar");
      setSaveLoading(false);
    }
  }
};

export default PaymentForm;
