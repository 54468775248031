import { getCompany } from './LocalStorageUtils';
import { setCompany } from '../utils/LocalStorageUtils';
import { adminCompanyClient } from '../clients/admin/AdminCompanyClient';
import { extractSubdomain } from './StrUtils';
import defaultStrings from '../default-string.json';

export const defaultPrimaryColor = "#1C4E4E"
export const defaultBtnTextColor = "#ffffff"
export const defaultSecondaryColor = "#ED3833"
export const defaultHighlightColor = "#DFDAE4"
const defaultLogoPath = "https://s3.amazonaws.com/h.oori.me/oori/logo_wendell_branco.png"

var customPrimaryColor = ""
var customBtnTextColor = ""
var customSecondaryColor = ""
var customHighlightColor = ""
var customLogoPath = ""

export function getSubdomain(): string {
  return extractSubdomain(window.location.host)
}

export function getPrimaryColor(): string {
  if(customPrimaryColor !== ""){
    return customPrimaryColor
  }else{
    var company = getCompany()
    if(company !== null){
      customPrimaryColor = JSON.parse(company).primaryColorHexCode
      return customPrimaryColor
    }
    
    return defaultPrimaryColor
  }
}

export function getBtnTextColor(): string {
  if(customBtnTextColor !== ""){
    return customBtnTextColor
  }else{
    var company = getCompany()
    if(company !== null){
      customBtnTextColor = JSON.parse(company).btnTextColorHexCode
      return customBtnTextColor
    }
    return defaultBtnTextColor
  }
}

export function getSecondaryColor(): string {
  if(customSecondaryColor !== ""){
    return customSecondaryColor
  }else{
    var company = getCompany()
    if(company !== null){
      customSecondaryColor = JSON.parse(company).secondaryColorHexCode
      return customSecondaryColor
    }
    return defaultSecondaryColor
  }
}

export function getHighlightColor(): string {
  if(customHighlightColor !== ""){
    return customHighlightColor
  }else{
    var company = getCompany()
    if(company !== null){
      customHighlightColor = JSON.parse(company).highlightColorHexCode
      return customHighlightColor
    }
    return defaultHighlightColor
  }
}

export function getLogoPath(): string {
  if(customLogoPath !== ""){
    return customLogoPath
  }else{
    var company = getCompany()
    if(company !== null){
      customLogoPath = JSON.parse(company).logoUrl
      return customLogoPath
    }
    return defaultLogoPath
  }
}

export function invalidateConfigCache() {
  customPrimaryColor = ""
  customSecondaryColor = ""
  customHighlightColor = ""
  customLogoPath = ""
}

export async function setUpCompanyTheme() {
   
  const { data } = await adminCompanyClient.getTheme()
  
  setCompany(data)
}

export function getCompanyId(){
  var company = getCompany()

    if(company !== null){
      return JSON.parse(company).id
    }
    return ""
}

export function getCompanyDict(){
  var company = getCompany()

  if(company !== null){
    const companyJson = JSON.parse(company)

    if(companyJson.dictionary){
      return JSON.parse(companyJson.dictionary)
    }
  }
 
  return defaultStrings
}
