import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Divider,
  message,
  Select,
  Spin
} from 'antd';
import {
  SaveOutlined,
} from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';

import { tutorSubjectClient } from '../../clients/tutor/TutorSubjectClient';
import { tutorAreaClient } from '../../clients/tutor/TutorAreaClient';
import { tutorClient } from '../../clients/tutor/TutorClient';
import { useAuth } from '../../hooks/AuthHook';
import { ISubject } from '../../types/Subject';

import Button from '../../components/Button';
import { Area } from '../../types/Area';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils';
import TutorIncompleteProfile from '../TutorIncompleteProfile';

interface ISubjectForm {
  subject?: ISubject;
  handleClose: () => void;
}

const SubjectForm: React.FC<ISubjectForm> = ({
  subject,
  handleClose,
}) => {

  const auth = useAuth();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [bigAreas, setBigAreas] = useState<Area[]>([]);
  const [loadingBigAreas, setLoadingBigAreas] = useState(false);
  const [selectedBigArea, setSelectedBigArea] = useState(subject?.subjectGreatArea || {
    id: '',
    name: ''
  });
  const [areas, setAreas] = useState<Area[]>([]);
  const [loadingAreas, setLoadingAreas] = useState(false);
  const [createAvailable, setCreateAvailable] = useState(false);
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    checkStatus();
    loadBigAreas();

    if (subject) {
      form.setFieldsValue(subject);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedBigArea) {
      loadAreas();
    }
    // eslint-disable-next-line
  }, [selectedBigArea]);

  return (
    <>
      {loading && <Spin tip='Carregando' />}
      {/* {!createAvailable && !loading &&
      } */}
      <TutorIncompleteProfile />
      {createAvailable && !loading &&
        <Form
          form={form}
          layout='vertical'
          onFinish={save}
        >
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label='Nome'
                name='name'
                rules={[{
                  required: true,
                  message: 'Preencha um nome para ' + dict.especialidade.singular
                }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label={capitalize(dict.grandeArea)}
                name='subjectGreatAreaId'
                rules={[{
                  required: true,
                  message: 'Preencha qual é a grande área associada'
                }]}
              >
              <Select loading={loadingBigAreas} onChange={(value: string) => setSelectedBigArea(bigAreas.filter((bigArea) => { return bigArea.id == value })[0])}>
                  {bigAreas.map((value: Area) => (
                    <Select.Option value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={capitalize(dict.area.singular)}
                name='subjectAreaId'
                rules={[{
                  required: true,
                  message: 'Preencha qual é a área associada'
                }]}
              >
                <Select loading={loadingAreas}>
                  {areas.map((value: Area) => (
                    <Select.Option value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                label='Descrição'
                name='description'
                rules={[{
                  required: true,
                  message: 'Preenchar a descrição'
                }]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                label={'Valor da hora/' + dict.atendimento.singular}
                name='costPerHour'
                rules={[{
                  required: true,
                  message: `O valor/${dict.atendimento.singular}`
                }]}
              >
                <Input prefix={'R$'} />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation='left'>Cadastro de descontos e adicionais</Divider>
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item
                label='Desconto do pacote'
                name='discountPercentage'
                rules={[{
                  required: true,
                  message: 'Preencha qual é o desconto do pacote'
                }]}
              >
                <Input suffix={"%"} type='number' min={0} max={100} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={'Quantidade mínima de hora/' + dict.atendimento.singular + ' para aplicar o pacote'}
                name='minHoursForDiscount'
                rules={[{
                  required: true,
                  message: `Preencha a hora/${dict.atendimento.singular} mínima para aplicar o desconto`
                }]}
              >
                <Input type='number' min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item
                label={'Máximo de ' + dict.cliente.plural + ' no pacote'}
                name='maxStudentsQty'
                rules={[{
                  required: true,
                  message: `Preencha a quantidade máxima de ${dict.cliente.plural}`
                }]}
              >
                <Input type='number' min={0} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={'Adicional por ' + dict.cliente.singular}
                name='extraCostPerStudent'
                rules={[{
                  required: true,
                  message: 'Preencha qual o adicional por ' + dict.cliente.singular
                }]}
              >
                <Input suffix={"%"} min={0} max={100} />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Button
            icon={<SaveOutlined />}
            type='primary'
            htmlType='submit'
            loading={loading}
          >Salvar</Button>
        </Form>
      }
    </>
  );

  async function checkStatus() {
    setLoading(true);

    try {
      const teacherRes = await tutorClient.findById(auth?.user?.id)

      var completeProfile = true
  
      if (!teacherRes?.data?.name || !teacherRes?.data?.phone) {
        completeProfile = false
       }

      if (!teacherRes?.data?.pagarmeRecipientId) {
        completeProfile = false
      }

      if (!teacherRes?.data?.emailConfirmed) {
        completeProfile = false
      }

      setCreateAvailable(completeProfile);
    } catch (error) {
      message.error('Falha ao encontrar perfil de ' + + dict.profissional.singular);
    } finally {
      setLoading(false);
    }

  }

  async function save(values: Store) {
    const companyId = getCompanyId()
    setLoading(true);

    try {
      const response = subject
        ? await tutorSubjectClient.update(subject.id, {
          ...values,
        })
        : await tutorSubjectClient.create({
          ...values,
          teacherId: auth.user.id,
          companyId: companyId,
          deleted: false
        });

      if (response) {
        message.success(capitalize(dict.especialidade.singular) + ' salva com sucesso');
        handleClose();
      } else {
        message.error('Falha ao salvar ' + dict.especialidade.singular);
      }
    } catch {
      message.error('Falha ao salvar ' + dict.especialidade.singular);
    } finally {
      setLoading(false);
      // afterSave();
    }
  }

  async function loadBigAreas() {
    const companyId = getCompanyId()
    const { results } = await tutorAreaClient.findBigAreasByCompany(companyId);

    setBigAreas(results || []);
    setLoadingBigAreas(false);
  }

  async function loadAreas() {
    const { results } = await tutorAreaClient.findAllArea({
      subjectGreatAreaId: selectedBigArea.id
    });

    setAreas(results || []);
    setLoadingAreas(false);
  }
}

export default SubjectForm;
