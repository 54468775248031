import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Drawer,
  Spin
} from 'antd';
import queryString from 'query-string';

import { tutorSubjectClient } from '../../clients/tutor/TutorSubjectClient';

import SubjectForm from '../SubjectForm';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils';

interface ISubjectViewDrawer {
  showEditForm: boolean;
  closeEditForm: () => void
}

const SubjectViewDrawer: React.FC<ISubjectViewDrawer> = ({
  showEditForm,
  closeEditForm
}) => {

  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [subject, setSubject] = useState<any>();
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    loadSubject();
    // eslint-disable-next-line
  }, []);


  return (
    <Drawer
      title={capitalize(dict.especialidade.singular)}
      visible={showEditForm}
      width={window.innerWidth < 600 ? '100%' : '60%'}
      destroyOnClose
      onClose={closeEditForm}
    >
      {loading && <Spin tip='Carregando' />}
      {!loading && subject &&
        <SubjectForm
          subject={subject}
          handleClose={closeEditForm}
        />
      }
    </Drawer>
  );

  async function loadSubject() {
    const { subjectId }: any = queryString.parse(location.search);
    const { data } = await tutorSubjectClient.findById(subjectId);
    setSubject(data);
    setLoading(false);
  }
}

export default SubjectViewDrawer;
