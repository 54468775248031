import * as React from "react";
import style from "./DashboardStatCard.module.scss";

interface IDashboardStatCard {
    title: string,
    content: string
}

const DashboardStatCard: React.FC<IDashboardStatCard> = (content: IDashboardStatCard) => {
    return (
        <div className={style.classCard}>
             <div className={style.info}>
                <p className={style.title}>{content.title}</p>
                <span className={style.content}>
                    {content.content}
                </span>
            </div>
        </div>
    )
}

export default DashboardStatCard;
