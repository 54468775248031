import { SaveOutlined } from "@ant-design/icons";
import { Col, Form, Input, message, Row } from "antd";
import MaskedInput from "antd-mask-input";
import { Store } from "antd/lib/form/interface";
import * as React from "react";
import { useState, useEffect } from "react";
import { format, parse } from 'date-fns';
import { studentClient } from "../../clients/student/StudentClient";
import Button from "../../components/Button";
import { useAuth } from "../../hooks/AuthHook";
import { removeNonNumbers, validateCPF } from "../../utils/StrUtils";

interface IProfileForm {
  afterSave: () => void;
  student: any;
}

const ProfileForm: React.FC<IProfileForm> = ({ afterSave, student }) => {
  const auth = useAuth();
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(student);
    // eslint-disable-next-line
  }, []);

  return (
    <Form form={form} layout="vertical" onFinish={save}>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Nome"
            rules={[
              {
                required: true,
                message: "Nome é obrigatório",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phone"
            label="WhatsApp"
            rules={[
              {
                required: true,
                message: "Nome é obrigatório",
              },
            ]}
          >
            <MaskedInput mask="(11) 11111-1111" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                required: true,
                message: "E-mail é obrigatório",
              },
              {
                type: "email",
                message: "Digite um e-mail válido",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="password"
            label="Senha"
          >
            <Input type="password" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={12}>
          <Form.Item name="cpf" label="CPF" rules={[
            {
              required: true,
              message: "CPF é obrigatório",
            },
          ]}>
            <MaskedInput mask="111.111.111-11" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="birthDate" label="Data de Nascimento" rules={[
            {
              required: true,
              message: "Data de Nacismento é obrigatória",
            },
          ]}>
            <MaskedInput mask="11/11/1111" />
          </Form.Item>
        </Col>
      </Row>

      <Button
        icon={<SaveOutlined />}
        type="primary"
        htmlType="submit"
        loading={saveLoading}
      >
        Salvar
      </Button>
    </Form>
  );

  async function save(values: Store) {

    if (form.getFieldValue('cpf')) {
      const isValidate = validateCPF(
        form.getFieldValue('cpf').replace(/\D/g, '')
      );
      if (!isValidate) {
        return message.error('O CPF informado é inválido.');
      }
    }


    setSaveLoading(true);

    try {
      const response = await studentClient.update(auth.user.id, {
        ...values,
        cpf: removeNonNumbers(values.cpf),
        phone: '+55' + removeNonNumbers(values.phone),
        birthDate: values.birthDate ? format(parse(values.birthDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd') : '',
      });

      if (response) {
        message.success("Salvo com sucesso");
      } else {
        message.error("Falha ao salvar o perfil");
      }
    } catch {
      message.error("Falha ao salvar o perfil");
    } finally {
      setSaveLoading(false);
      afterSave();
    }
  }
};

export default ProfileForm;
