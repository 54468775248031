import * as React from 'react';

import LoginForm from '../../components/LoginForm';

import style from './AdminLoginPage.module.scss';

const AdminLoginPage: React.FC = () => {
  return (
    <div className={style.loginPage}>
      <LoginForm />
    </div>
  );
}

export default AdminLoginPage;
