import * as React from 'react';
import { useRef } from 'react';
import FullCalendar, { EventClickArg, EventInput } from '@fullcalendar/react';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ClassStatusLabel, IClass } from '../../types/Class';
import { formatToTimeZone } from 'date-fns-timezone';
import { addMinutes } from 'date-fns';
import { getPrimaryColor } from '../../utils/CompanyConfigUtils';

import './Calendar.scss';
import { useLocation } from 'react-router-dom';

interface ICalendar {
  classes: IClass[];
}

const Calendar: React.FC<ICalendar> = ({
  classes
}) => {

  const location = useLocation();
  const calendarRef = useRef<any>(null);

  const events = classes.map((c: IClass): EventInput => {
    const textColor = {
      'Solicitado': '#fa8c16',
      'Agendado': '#52c41a',
      'Ativo': '#1890ff',
      'Concluído': '#1890ff',
      'Cancelado': '#f5222d',
      'Recusado': '#f5222d',
    }

    const date = c.scheduledOnUtc;
    return {
      id: c.id,
      title: `[${ClassStatusLabel[c.statusId]}] 
      ${c?.subject?.name} com ${c?.teacher?.name}`,
      start: formatToTimeZone(date, "YYYY-MM-DD HH:mm", { timeZone: "America/Recife" }),
      end: formatToTimeZone(addMinutes(date, c.scheduledDuration * 60), "YYYY-MM-DD HH:mm", { timeZone: "America/Recife" }),
      className: `event_${ClassStatusLabel[c.statusId]}`,
      backgroundColor: textColor[ClassStatusLabel[c.statusId]]
    }
  });

  return (
    <FullCalendar
      plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
      initialView={window.innerWidth < 600 ? 'timeGridDay' : 'timeGridWeek'}
      weekends={true}
      allDaySlot={false}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: window.innerWidth < 600
          ? 'timeGridDay,dayGridMonth'
          : 'timeGridWeek,dayGridMonth'
      }}
      locale={ptBrLocale}
      events={events}
      stickyHeaderDates={true}
      eventClick={onEventClick}
      dateClick={handleDateClick}
      ref={calendarRef}
    />
  );

  function onEventClick(e: EventClickArg) {
    const { event } = e;
    const { id } = event;

    if (location.pathname.includes('admin')) {
      return
    }

    if (location.pathname.includes('profissional')) {
      window.open(`/profissional/atendimento/${id}`, "_blank");
      return;
    }
    window.open(`/atendimento/${id}`, "_blank");
  }

  function handleDateClick(dateClickInfo) {
    calendarRef?.current?.getApi()?.changeView('timeGridDay', dateClickInfo.date)
  };
}

export default Calendar