import * as React from 'react';
import { 
  Result,     
  message,
  Alert
} from 'antd';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { studentClient } from '../../clients/student/StudentClient';
import { tutorClient } from '../../clients/tutor/TutorClient';
import style from './ConfirmEmail.module.scss';
import HeaderNavigation from '../../components/HeaderNavigation';

const ConfirmEmail: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    confirmEmail()
  // eslint-disable-next-line
  }, []);

  return(
    <div>
      <div className={style.headerNavigation}>
        <HeaderNavigation  />
        </div>
      <div className={style.confirmEmailPage}>
        <Result
          status="success"
          title="E-mail confirmado! 🤙"
          subTitle={
            <>
              <Alert
                type='info'
                message={<>
                Clique no link abaixo e complete os dados do seu cadastro para poder agendar um horário com qualquer profissional OOri.
                <br />
                  {location.pathname.includes('profissional') &&
                    <a href="/profissional/perfil">Completar cadastro</a>
                  }
                  {!location.pathname.includes('profissional') &&
                    <a href="/perfil">Completar cadastro</a>
                  }
                </>
                }
              />
            </>
          }
        />
        <div className={style.footer} />
      </div>
    </div>
  )

  async function confirmEmail(){
    const token = new URLSearchParams(history.location.search).get('code');

    try{
      if(location.pathname.includes('profissional')){
        await tutorClient.confirmEmail(token? token : "")
      }else{
        await studentClient.confirmEmail(token? token : "");
      }
    }catch{
      message.error('Falha ao confirmar email');
    }
  }
}

export default ConfirmEmail;