import * as React from 'react';
import { useState, useEffect } from 'react';
import { Table } from 'antd';
import { formatToTimeZone } from 'date-fns-timezone';

import { IClass, ClassStatusLabel } from '../../types/Class';
import { adminClassClient } from '../../clients/admin/AdminClassClient';
import { ColumnsType } from 'antd/lib/table';
import { ISubject } from '../../types/Subject';
import { fromUnixTime } from 'date-fns';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';
import { capitalize } from '@material-ui/core';
import { IStudent } from '../../types/Student';

interface IClassTableList {
  userId?: string;
  userType?: string;
}

const ClassTableList: React.FC<IClassTableList> = ({
  userId,
  userType
}) => {

  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState<IClass[]>([]);
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    loadClasses();
  // eslint-disable-next-line
  }, []);

  const columns: ColumnsType<IClass> = [{
      dataIndex: 'teacher',
      title: capitalize(dict.profissional.singular),
      render: (teacher?: any) => teacher?.name
    },
    {
    dataIndex: 'subject',
    title: capitalize(dict.atendimento.singular),
    render: (subject: ISubject) => subject.name
  }, {
    dataIndex: 'statusId',
    title: 'Status',
    render: (status: number) => ClassStatusLabel[status]
  }, {
    dataIndex: 'students',
    title: capitalize(dict.cliente.plural),
    render: (students: IStudent[]) => {
      return students.map((student) => student.name ).join(', ')
    }
  }, {
    dataIndex: 'scheduledOnUtc',
    title: 'Data',
    render: (scheduledOnUtc: string) => {
      if (!scheduledOnUtc) {
        return 'Não agendada'
      }
      const date = new Date(scheduledOnUtc)
      return formatToTimeZone(date, 'DD/MM [às] HH:mm', { timeZone: 'America/Recife' })
    }
  }];

  return (
    <>
      <Table
        loading={loading}
        dataSource={classes}
        columns={columns}
        rowKey={(c) => c.classId}
        style={{marginTop: '32px'}}
        scroll={{ x: 400 }}
      />
    </>
  );

  async function loadClasses () {
    const { results } = await adminClassClient.findAll({
      studentId: userType === 'student' ? userId : undefined,
      teacherId: userType === 'teacher' ? userId : undefined,
      companyId: getCompanyId()
    });

    setClasses(results
        .filter((c: IClass) => c.scheduledOnUtc)
        .sort((a: IClass, b: IClass) => {
          const aDate = new Date(a.scheduledOnUtc)
          const bDate = new Date(b.scheduledOnUtc)

          return aDate.getTime() < bDate.getTime() ? 1 : -1
        })
    );
    setLoading(false);
  
  }
}

export default ClassTableList;
