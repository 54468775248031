import * as React from 'react';
import {
  Divider
} from 'antd';

import StudentListTable from '../../componentsAdmin/StudentListTable';

import style from './AdminStudentListPage.module.scss';

const AdminStudentListPage: React.FC = () => {

  return (
    <div className={style.studentListPage}>
      <Divider/>
      <StudentListTable />
    </div>
  );
}

export default AdminStudentListPage;
