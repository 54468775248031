import { axiosClient } from '../axiosHandler';
import { Options } from '../axiosTypes';
import { BASE_URL } from '../configClient';

interface IAdminTutorClient {
  create(data: any): Promise<any>;
  addCompany(companyId: number, teacherId: any): Promise<any>;
  update(id: string, data: any): Promise<any>;
  removeCompany(companyId: number, teacherId: any): Promise<any>;
  findById(id: string): Promise<any>;
  findByEmail(email: string): Promise<any>;
  findAll(companyId: string): Promise<any>;
}

const AdminTutorClient = (
  baseURL: string,
  options?: Options
): IAdminTutorClient => {

  const client = axiosClient(baseURL, options);

  return {
    create,
    addCompany,
    update,
    removeCompany,
    findById,
    findByEmail,
    findAll
  };

  async function create(tutor: any): Promise<any> {

    if (tutor.email) {
      tutor.email = tutor.email.trim().toLowerCase()
    }

    const { data } = await client({
      method: 'post',
      url: '/teacher',
      data: tutor,
    });

    return data;
  }

  async function addCompany(companyId: number, teacherId: number): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: '/teacher/addCompany',
      data: {
        teacherId,
        companyId
      },
    });

    return data;
  }

  async function update(id: string, tutor: any): Promise<any> {

    if (tutor.email) {
      tutor.email = tutor.email.trim().toLowerCase()
    }

    const { data } = await client({
      method: 'put',
      url: '/teacher',
      data: {
        ...tutor,
        id,
      },
    });

    return data;
  }

  async function removeCompany(companyId: number, teacherId: number): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: `/teacher/removeCompany`,
      data: {
        teacherId,
        companyId,
      }
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/teacher/${id}`,
    });

    return data;
  }

  async function findByEmail(email: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/teacher`,
      params: {
        email: email ? email.trim().toLowerCase() : '',
      }
    });

    return data;
  }

  async function findAll(companyId: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/teacher/company/${companyId}`,
    });

    return data;
  }


}

export const adminTutorClient = AdminTutorClient(BASE_URL);