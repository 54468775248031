import { LogoutOutlined } from '@ant-design/icons';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthHook';
import { Button } from 'antd';

const ExitButton: React.FC = () => {

  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  return (
    <Button icon={<LogoutOutlined />} onClick={logout}>
      {" "}
      Sair{" "}
    </Button>
  );

  function logout() {
    auth.signout();

    if (location.pathname.includes("admin")) {
      history.push("/admin/login");
    } else if (location.pathname.includes("profissional")) {
      history.push("/profissional/login");
    } else {
      history.push("/login");
    }

    window.location.reload();

  }
}

export default ExitButton;