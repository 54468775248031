import * as React from 'react';

import LoginForm from '../../components/LoginForm';
import HeaderNavigation from '../../components/HeaderNavigation';

import style from './Login.module.scss';

const Login: React.FC = () => {
  return (
    <div>
      <div className={style.headerNavigation}>
       <HeaderNavigation  />
      </div>
      <div className={style.loginPage}>
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;