import * as React from 'react';
import { useState, useEffect } from 'react';
import { Skeleton, message, Timeline, Alert, Collapse } from 'antd';

import { tutorClient } from '../../clients/tutor/TutorClient';
import { Balance, BalanceOperation } from '../../types/Teacher';
import { useAuth } from '../../hooks/AuthHook';
import { formatCurrency } from '../../utils/CurrencyUtils';
import { formatToTimeZone } from 'date-fns-timezone';
import { BalanceOperationStatusLabel } from '../../types/BalanceOperation';
import { BankCodeLabel, AccountTypeLabel } from '../../types/BankAccount';

import style from './AdminBalance.module.scss';
import { adminCompanyClient } from '../../clients/admin/AdminCompanyClient';

const TutorBalance: React.FC = () => {

  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState<Balance>();
  const [loadingOperations, setLoadingOperations] = useState(true);
  const [balanceOperations, setBalanceOperations] = useState<BalanceOperation[]>([]);

  useEffect(() => {

    loadBalance();
    loadBalanceOperations();

    //eslint-disable-next-line
  }, []);
  
  return (
    <div className={style.adminBalance}>
      {loading && !balance && <Skeleton active />}
      {!loading && balance &&
        <>
          <h1>
            Saldo disponível:<br />
            <span className={style.balanceText}>
              {formatCurrency(balance.available, 2)}
            </span>
          </h1>
          <br />
          <h1>
            Aguardando liberação:<br />
            <span className={style.balanceText}>
              {formatCurrency(balance.waiting_funds, 2)}
            </span>
          </h1>
          <br />
          <h1>
            Já transferido:<br />
            <span className={style.balanceText}>
              {formatCurrency(balance.transferred, 2)}
            </span>
          </h1>
        </>
      }
      {loadingOperations && <Skeleton active />}
      {!loadingOperations && !!balanceOperations.length &&
      <>
      <h1 style={{ marginTop: '25px' }}><strong>Transferências:</strong></h1><br />
      <Timeline style={{ marginTop: '10px' }}>
       {balanceOperations
          .sort((a, b) => {
            const aDate = a.date ? new Date(a.date) : new Date()
            const bDate = b.date ? new Date(b.date) : new Date()
            return bDate.getTime() - aDate.getTime()
          })
         .map((operation: BalanceOperation) => (
        <Timeline.Item color={getTimelineColor(operation.status)}>
          {operation.date ? formatToTimeZone(operation.date, "DD/MM/YY HH:mm", { timeZone: "America/Recife" }) : 'Pendente'}: {formatCurrency(operation.amount, 2)}
          <br />
          <Collapse>
            <Collapse.Panel header="Detalhes" key={operation.id}>
              <BalanceOperationInfo operation={operation} />
            </Collapse.Panel>
          </Collapse>
        </Timeline.Item>))}
      </Timeline>
     </>
      }
      {!loadingOperations && !balanceOperations.length &&
        <Alert style={{ marginTop: '30px' }} type='info' message='Ainda não existe nenhuma transferência feita' />
      }
    </div>
  );

  async function loadBalance () {
    try {
      console.log(auth);
      
      const { data, error } = await adminCompanyClient.getBalance(auth.user.companyId);

      if (error) {
        message.error(error.message || 'Falha ao carregar saldo');
        return;
      }
      setBalance(data);
    } catch {
      message.error('Falha ao carregar saldo');
    } finally {
      setLoading(false);
    }
  }

  async function loadBalanceOperations () {
    try {

      const { results, error } = await adminCompanyClient.getBalanceOperations(auth.user.companyId);

      if (error) {
        message.error(error.message || 'Falha ao carregar saques');
        return;
      }
      setBalanceOperations(results);
    } catch {
      message.error('Falha ao carregar saques');
    }finally {
      setLoadingOperations(false);
    }
  }

  function getTimelineColor (status: string) {

    const GREEN_STATUS = ['transferred'];
    const BLUE_STATUS = ['pending_transfer', 'processing'];
    const RED_STATUS = ['failed'];
    const GREY_STATUS = ['canceled'];

    if (GREEN_STATUS.includes(status)) {
      return 'green';
    }

    if (BLUE_STATUS.includes(status)) {
      return 'blue';
    }

    if (RED_STATUS.includes(status)) {
      return 'red'
    }

    if (GREY_STATUS.includes(status)) {
      return 'grey'
    }

    return '';
  }

}

interface IBalanceOperationInfo {
  operation: BalanceOperation
}

const BalanceOperationInfo: React.FC<IBalanceOperationInfo> = ({
  operation
}) => {

  const formatedDate = operation.date
    ? formatToTimeZone(operation.date, "DD/MM/YY [às] HH:mm", { timeZone: "America/Recife" })
    : undefined;

  return (
    <>
      <strong>Status:</strong> {BalanceOperationStatusLabel[operation.status]} <br />
      <strong>Valor pago:</strong> {formatCurrency(operation.amount, 2)}<br />
      <strong>Taxa:</strong> {formatCurrency(operation.fee, 2)}<br />
      <br />
      <strong>Conta bancária</strong><br />
      <strong>Banco:</strong> {BankCodeLabel[operation.bankAccount.bank_code]} <br />
      <strong>Agência:</strong> {operation.bankAccount.agencia}-{operation.bankAccount.agencia_dv}<br />
      <strong>Conta:</strong> {operation.bankAccount.conta}-{operation.bankAccount.conta_dv}<br />
      <strong>Titular:</strong> {operation.bankAccount.legal_name}<br />
    </>
  );
}

export default TutorBalance;
