import * as React from 'react';
import RegisterForm from '../../components/RegisterForm';
import style from './Register.module.scss';
import HeaderNavigation from '../../components/HeaderNavigation';
import BackButton from '../../components/BackButton'; 

const Register: React.FC = () => {
  return (
    <div className={style.registerPage}>
      <div className={style.header}>
        <BackButton />
        <HeaderNavigation />
      </div>
      <RegisterForm />
      <div className={style.footer} />
    </div>
  );
}

export default Register;