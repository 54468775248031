import * as React from "react";
import { useAuth } from "../../hooks/AuthHook";
import { getFirstName } from "../../utils/StrUtils";
import { useHistory, useLocation } from "react-router-dom";
import ExitButton from "../ExitButton/ExitButton";
import style from "./Header.module.scss";
import { ArrowContainer, Popover } from "react-tiny-popover";

import { LogoutOutlined, MenuOutlined, UserOutlined } from "@ant-design/icons";

const Header: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  return (
    <div className={location.pathname === "/" || location.pathname === "/profissional" ? style.header : style.headerSimple}>
      {(location.pathname === "/" || location.pathname === "/profissional") && (
        <h1 id="greetings">Olá{auth?.user?.name ? `, ${getFirstName(auth?.user?.name)}` : ""}!</h1>
      )}
      {location.pathname.includes("admin") && <ExitButton />}
      {!location.pathname.includes("admin") && (
        <div>
          <Popover
            isOpen={isPopoverOpen}
            positions={["bottom"]}
            padding={8}
            onClickOutside={() => setIsPopoverOpen(false)}
            containerStyle={{
              zIndex: "100",
            }}
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor={"white"}
                arrowSize={10}
                className="popover-arrow-container"
              >
                <div className={style.popupMenu} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                  <div onClick={navigateToProfile} className={style.popupItem}>
                    <span>
                      <UserOutlined style={{ fontSize: "1rem" }} />
                    </span>{" "}
                    Perfil
                  </div>
                  <hr style={{ borderTop: "1px solid #afafaf" }} />
                  <div onClick={logout} className={style.popupItem}>
                    <span>
                      <LogoutOutlined style={{ fontSize: "1rem" }} />
                    </span>{" "}
                    Sair
                  </div>
                </div>
              </ArrowContainer>
            )}
          >
            <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
              <MenuOutlined style={{ fontSize: "1.2rem" }} />
            </div>
          </Popover>
        </div>
      )}
      {isPopoverOpen ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "absolute",
            zIndex: 100,
            left: 0,
            top: 0,
          }}
        ></div>
      ) : null}
    </div>
  );

  function logout() {
    auth.signout();

    if (location.pathname.includes("admin")) {
      history.push("/admin/login");
    } else if (location.pathname.includes("profissional")) {
      history.push("/profissional/login");
    } else {
      history.push("/login");
    }

    window.location.reload();
  }

  function navigateToProfile() {
    if (location.pathname.includes("profissional")) {
      history.push("/profissional/perfil");
      return;
    }

    history.push("/perfil");
  }
};

export default Header;
