import { axiosClient } from '../axiosHandler';
import { Options } from '../axiosTypes';
import { BASE_URL } from '../configClient';
import { AreaFilter } from '../../types/Area';

interface ITutorAreaClient {
  findById(id: string): Promise<any>;
  findAllArea(filter?: AreaFilter): Promise<any>;
  findAllBigArea(filter?: AreaFilter): Promise<any>;
  findBigAreasByCompany(companyId: string): Promise<any>;
  findAreasByCompany(companyId: string): Promise<any>;
}

const TutorAreaClient = (
  baseURL: string,
  options?: Options
): ITutorAreaClient => {

  const client = axiosClient(baseURL, options);

  return {
    findById,
    findAllArea,
    findAllBigArea,
    findAreasByCompany,
    findBigAreasByCompany
  };
  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/area/${id}`,
    });

    return data;
  }

  async function findAllArea(filter?: AreaFilter): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: '/area',
      params: filter || {}
    });

    return data;
  }

  async function findAreasByCompany(companyId: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/area/company/${companyId}`
    });

    return data;
  }

  async function findAllBigArea(filter?: AreaFilter): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: '/greatArea',
      params: filter || {}
    });

    return data;
  }

  async function findBigAreasByCompany(companyId: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/greatArea/company/${companyId}`
    });

    return data;
  }
}

export const tutorAreaClient = TutorAreaClient(BASE_URL);