import * as React from 'react';

import ResetPasswordForm from '../../components/ResetPasswordForm'; 
import style from './ResetPassword.module.scss';
import HeaderNavigation from '../../components/HeaderNavigation';

const ResetPassword: React.FC = () => {
  return (
    <div>
      <div className={style.headerNavigation}>
       <HeaderNavigation  />
      </div>
      <div className={style.resetPasswordPage}>
          <ResetPasswordForm />
          <div className={style.footer} />
      </div>
    </div>
    
  );
}

export default ResetPassword;