import * as React from 'react';
import { useState } from 'react';
import {
  Divider,
  Drawer
} from 'antd';

import Button from '../../components/Button';
import SubjectListTable from '../../componentsTutor/SubjectListTable';
import SubjectForm from '../../componentsTutor/SubjectForm';

import style from './TutorSubjectListPage.module.scss';
import Header from '../../components/Header';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict } from '../../utils/CompanyConfigUtils';

const TutorSubjectListPage: React.FC = () => {

  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [updateView, setUpdateView] = useState(false);
  const dict: CompanyDict = getCompanyDict()

  return (
    <div className={style.subjectListPage}>
      <Header />
      <Button
        type='primary'
        onClick={openRegisterForm}
      >Cadastrar {dict.especialidade.singular}</Button>
      <Divider />
      <SubjectListTable updateView={updateView} />

      <Drawer
        title={'Cadastro de ' + dict.especialidade.singular}
        visible={showRegisterForm}
        width={window.innerWidth < 600 ? '100%' : '60%'}
        destroyOnClose
        onClose={closeRegisterForm}
      >
        <SubjectForm handleClose={closeRegisterForm} />
      </Drawer>
    </div>
  );


  function openRegisterForm() {
    setShowRegisterForm(true);
    setUpdateView(false);
  }

  function closeRegisterForm() {
    setShowRegisterForm(false);
    setUpdateView(true);
  }
}

export default TutorSubjectListPage;
