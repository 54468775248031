import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Table, Popconfirm, Button, message, Drawer } from 'antd';
import { EyeFilled, DeleteOutlined } from '@ant-design/icons';
import queryString from 'query-string';

import { adminAreaClient } from '../../clients/admin/AdminAreaClient';
import { Area } from '../../types/Area';
import AreaForm from '../AreaForm';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';
import { CompanyDict } from '../../types/CompanyDict';

interface IAreaListTable {
  updateView: boolean;
}

const AreaListTable: React.FC<IAreaListTable> = ({
  updateView
}) => {

  const history = useHistory();
  const location = useLocation();

  const [showEditForm, setShowEditForm] = useState(false);
  const [areas, setAreas] = useState<Area[]>([]);
  const [loading, setLoading] = useState(true);
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    loadAreas();
  }, [updateView]);

  const columns = [{ 
      title: 'Nome',
      dataIndex: 'name'
    }, {
      render: (area: Area) => (
        <>
          <Button 
            icon={<EyeFilled />} 
            shape='circle' 
            onClick={() => openArea(area.id)} 
            size='small'
          />
          <Popconfirm 
            title={`Tem certeza que deseja deletar ${area.name}?`}
            onConfirm={() => deleteArea(area.id)}
          >
            <Button 
              icon={<DeleteOutlined />} 
              danger 
              shape='circle' 
              size='small'
              style={{marginLeft: 8}}
            />
          </Popconfirm>
        </>
      ),
    }];

  return (
    <>
      <Table 
        columns={columns}
        dataSource={areas}
        loading={loading}
        rowKey={((r) => r.id)}
      />
      <Drawer 
        title={'Edição de ' + dict.area.singular}
        visible={showEditForm}
        width={window.innerWidth < 600 ? '100%' : '60%'}
        destroyOnClose
        onClose={closeEditForm}
      >
        <AreaForm />
      </Drawer>
    </>
  );

  async function loadAreas() {
    const companyId = getCompanyId()

    const { results, error } = await adminAreaClient.findByCompany(companyId);

    if (error) {
      setAreas([]);
      return;
    }

    setAreas(results);
    setLoading(false);
  }

  function openArea(subjectAreaId: string) {

    const queryValues = queryString.parse(location.search);

    history.push({
      search: new URLSearchParams(
        queryString.stringify({ ...queryValues, subjectAreaId })
      ).toString(),
    });

    setShowEditForm(true);
  }

  async function deleteArea(id: string) {
    const { error } = await adminAreaClient.remove(id);

    if(error) {
      message.error(error.message || 'Falha ao remover');
      return;
    }

    message.success('Remoção realizada com sucesso');
    loadAreas();
  }

  function closeEditForm () {
    setLoading(true);
    loadAreas();

    history.push({
      search: new URLSearchParams(
        queryString.stringify({})
      ).toString(),
    });
    setShowEditForm(false);
  }
}

export default AreaListTable;
