import { ISubject } from "./Subject";
import { ITeacher } from "./Teacher";

export interface IClass {
  id: string;
  classId: string;
  teacher: ITeacher;
  teacherId: string;
  subjectId: string;
  students: any[];
  preClass: string;
  postClass: string;
  scheduledOnUtc: Date;
  limitToScheduleUtc: Date;
  scheduledDuration: number;
  startOnUtc: Date;
  endOnUtc: Date;
  status: ClassStatus;
  statusId: number;
  subject: ISubject;
  subjectName: string;
  costPerStudent: number;
  numberOfClasses: number;
  usersStatus?: UserStatus[];
  refuseJustification?: string;
  packageId?: string;
  companyId: string;
}

export interface UserStatus {
  classId: string;
  type: 'student' | 'teacher';
  userId?: string;
  teacherId?: string;
  studentId?: string;
  statusId: ClassStatus;
}
export interface IClassFilter {
  id?: string;
  userId?: string;
  studentId?: string;
  teacherId?: string;
  companyId?: string;
}

export enum ClassStatus {
  REQUESTED = 10,
  SCHEDULED = 15,
  ACCEPTED = 20,
  ACTIVE = 30,
  DONE = 40,
  CANCELED = 50,
  REFUSED = 60,
}

export const ClassStatusLabel = {
  [ClassStatus.REQUESTED]: "Solicitado",
  [ClassStatus.SCHEDULED]: "Agendado",
  [ClassStatus.ACCEPTED]: "Aceito",
  [ClassStatus.ACTIVE]: "Ativo",
  [ClassStatus.DONE]: "Concluído",
  [ClassStatus.CANCELED]: "Cancelado",
  [ClassStatus.REFUSED]: "Recusado",
};
