import { isBefore, isEqual } from "date-fns";
import { formatToTimeZone } from "date-fns-timezone";
import React, { useState } from "react";
import { getPrimaryColor, getBtnTextColor, getHighlightColor } from "../../utils/CompanyConfigUtils";

import style from "./ScheduleTimeForm.module.scss";

interface IScheduleTimeForm {
  times: Date[];
  selectedDate: Date;
  selectedTimes: Date[];
  handleSelectTime: Function;
  scheduleDayFormRef: any;
}

const ScheduleTimeForm: React.FC<IScheduleTimeForm> = ({ times, selectedTimes, selectedDate, handleSelectTime, scheduleDayFormRef }) => {
  const timeNow = new Date();
  const [isScheduleDayFormScrollEnabled, setIsScheduleDayFormScrollEnabled] = useState(true);

  const scrollToScheduleDayForm = () => {
    if (isScheduleDayFormScrollEnabled) {
      scheduleDayFormRef.scrollIntoView({ behavior: "smooth" });
      setIsScheduleDayFormScrollEnabled(false);
    }
  };

  return (
    <div className={style.timeForm}>
      {times.map((time) => {
        const formatedTime = formatToTimeZone(time, "HH:mm", { timeZone: "America/Recife" });
        return (
          <div
            className={style.timeButton}
            style={
              selectedTimes?.some((selectedTime) => {
                return isEqual(selectedTime, time);
              })
                ? { display: "none" }
                : isBefore(
                    new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), time.getHours(), time.getMinutes()),
                    timeNow
                  )
                ? { backgroundColor: "lightgray", color: "dimgrey", cursor: "not-allowed" }
                : {}
            }
            onClick={
              isBefore(
                new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), time.getHours(), time.getMinutes()),
                timeNow
              )
                ? () => {}
                : () => {
                    handleSelectTime(
                      time,
                      selectedTimes?.some((selectedTime) => {
                        return isEqual(selectedTime, time);
                      })
                    );
                    scrollToScheduleDayForm();
                  }
            }
          >
            {formatedTime}
          </div>
        );
      })}
    </div>
  );
};

export default ScheduleTimeForm;
