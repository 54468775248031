import { axiosClient } from '../axiosHandler';
import { Options } from '../axiosTypes';
import { BASE_URL } from '../configClient';

interface IStudentSubjectClient {
  findAll(): Promise<any>;
  findById(id: string): Promise<any>;
  findByCompany(companyId: string): Promise<any>;
}

const StudentSubjectClient = (
  baseURL: string,
  options?: Options
): IStudentSubjectClient => {

  const client = axiosClient(baseURL, options);

  return {
    findAll,
    findById,
    findByCompany
  };

  async function findAll(): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: '/subject',
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/subject/${id}`,
    });

    return data;
  }

  async function findByCompany(companyId: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/subject?companyId=${companyId}`,
    });

    return data;
  }


}

export const studentSubjectClient = StudentSubjectClient(BASE_URL);