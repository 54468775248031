import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Table
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EyeFilled } from '@ant-design/icons';
import queryString from 'query-string';

import { adminStudentClient } from '../../clients/admin/AdminStudentClient';

import StudentviewDrawer from '../StudentViewDrawer';

const StudentListTable: React.FC = () => {

  const history = useHistory();
  const location = useLocation();

  const [showEditForm, setShowEditForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState<any[]>([]);

  useEffect(() => {
    loadStudents();
  }, []);


  const columns: ColumnsType<{}> = [
    {
    title: 'Nome',
    dataIndex: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Telefone',
      dataIndex: 'phone'
    },
    {
      title: 'CPF',
      dataIndex: 'cpf'
    },
    {
      dataIndex: 'id',
      render: (id: string) => (
        <EyeFilled onClick={() => openStudent(id)} />
      ),
    }
  ];

  return (
    <>
      <Table
        dataSource={students}
        columns={columns}
        rowKey={((r) => r.id)}
        loading={loading}
        onRow={(record) => {
          return {
            onClick: () => openStudent(record.id)
          };
        }}
      />
      {showEditForm && <StudentviewDrawer 
        showEditForm={showEditForm}
        closeEditForm={closeEditForm}
      />}
    </>
  );

  async function loadStudents () {
    const { results } = await adminStudentClient.findAll();
    setStudents(results);
    setLoading(false);
  }

  function openStudent (studentId: string) {
    const queryValues = queryString.parse(location.search);

    history.push({
      search: new URLSearchParams(
        queryString.stringify({ ...queryValues, studentId })
      ).toString(),
    });

    setShowEditForm(true);
  }

  function closeEditForm() {
    setLoading(true);
    loadStudents();

    history.push({
      search: new URLSearchParams(
        queryString.stringify({})
      ).toString(),
    });

    setShowEditForm(false);
  }

}

export default StudentListTable;
