import { axiosClient } from '../axiosHandler';
import { Options } from '../axiosTypes';
import { BASE_URL } from '../configClient';

interface IAdminStudentClient {
  create(data: any): Promise<any>;
  update(id: string, data: any): Promise<any>;
  remove(id: string): Promise<any>;
  findById(id: string): Promise<any>;
  findAll(): Promise<any>;
}

const AdminStudentClient = (
  baseURL: string,
  options?: Options
): IAdminStudentClient => {

  const client = axiosClient(baseURL, options);

  return {
    create,
    update,
    remove,
    findById,
    findAll
  };

  async function create(student: any): Promise<any> {

    if (student.email) {
      student.email = student.email.trim().toLowerCase()
    }

    const { data } = await client({
      method: 'post',
      url: '/student',
      data: student,
    });

    return data;
  }

  async function update(id: string, student: any): Promise<any> {

    if (student.email) {
      student.email = student.email.trim().toLowerCase()
    }

    const { data } = await client({
      method: 'put',
      url: '/student',
      data: {
        ...student,
        id,
      },
    });

    return data;
  }

  async function remove(id: string): Promise<any> {
    const { data } = await client({
      method: 'post',
      url: `/student/${id}`,
    });

    return data;
  }

  async function findById(id: string): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: `/student/${id}`,
    });

    return data;
  }

  async function findAll(): Promise<any> {
    const { data } = await client({
      method: 'get',
      url: '/student',
    });

    return data;
  }


}

export const adminStudentClient = AdminStudentClient(BASE_URL);