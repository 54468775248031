import * as React from 'react';
import { useState, useEffect } from "react";
import { Col, Form, Input, message, Row, Select, Button, Divider, Alert } from "antd";
import { Store } from 'antd/lib/form/interface';
import { SaveOutlined } from "@ant-design/icons";
import { tutorClient } from "../../clients/tutor/TutorClient";
import { useAuth } from "../../hooks/AuthHook";
import { BANKS, Bank } from './BankList';
import { ITeacher } from '../../types/Teacher';
import { MaskedInput } from 'antd-mask-input';
import { validateCPF, removeNonNumbers } from '../../utils/StrUtils';
import { adminCompanyClient } from '../../clients/admin/AdminCompanyClient';
import OoriButton from '../../components/Button';

interface ITutorPaymentForm {
  afterSave: () => void;
  teacher;
  isAdmin?: Boolean;
}

const TutorPaymentForm: React.FC<ITutorPaymentForm> = ({
  afterSave,
  teacher,
  isAdmin
}) => {

  const auth = useAuth();
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  const [bankAccount, setBankAccount] = useState<any>({});
  const [transferPeriodice, setTransferPeriodice] = useState<'daily' | 'weekly' | 'monthly'>();
  const [documentType, setDocumentType] = useState<'cpf' | 'cnpj'>();

  useEffect(() => {
    loadBankAccount();
    // eslint-disable-next-line
  }, []);

  const rules = [{
    required: true,
    message: 'Campo obrigatório'
  }];

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={save}
    >
      <Row gutter={8}>
        <Col span={24}>
          <Form.Item name='bankCode' label='Banco' rules={rules}>
            <Select showSearch optionFilterProp='children'>
              {BANKS.map((bank: Bank) => {
                return (
                  <Select.Option key={bank.id + ''} value={bank.id}>
                    {bank.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item name='agency' label='Agência' rules={rules}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='agencyDigit' label='Dígito (0 se não houver)' rules={rules}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item name='accountNumber' label='Nº da conta' rules={rules}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='accountDigit' label='Dígito da conta' rules={rules}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item name='accountType' label='Tipo da conta' rules={rules}>
            <Select showSearch optionFilterProp='children'>
              <Select.Option key='conta_corrente' value='conta_corrente'>
                Conta Corrente
              </Select.Option>
              <Select.Option key='conta_poupanca' value='conta_poupanca'>
                Conta Poupança
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='fullName' label='Nome do Titular' rules={[...rules, {
            type: 'string',
            max: 30
          }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>

      <Col span={12}>
          <Form.Item name='documentType' label='Tipo do documento (CPF ou CNPJ)' rules={rules}>
            <Select showSearch optionFilterProp='children' onChange={updateDocumentNumber}>
              <Select.Option key='cpf' value='cpf'>
                CPF
              </Select.Option>
              <Select.Option key='cnpj' value='cnpj'>
                CNPJ
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        
        <Col span={12}>
            { form.getFieldValue('documentType') === 'cpf' &&
              <Form.Item name='documentNumber' label='Número do documento' rules={rules}>
                <MaskedInput mask='111.111.111-11' />
              </Form.Item>
            }
            {form.getFieldValue('documentType') === 'cnpj' &&
              <Form.Item name='documentNumber' label='Número do documento' rules={rules}>
                <MaskedInput mask='11.111.111/1111-11' />
              </Form.Item>
            }
        </Col>
      </Row>

      <Divider orientation='left'>Periodicidade de transferência</Divider>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name='transferInterval'
            label='Período de transferência'
            rules={rules}
          >
            <Select
              onChange={updatePeriodice}
            >
              <Select.Option key='daily' value='daily'>
                Diária
              </Select.Option>
              <Select.Option key='weekly' value='weekly'>
                Semanal
              </Select.Option>
              <Select.Option key='monthly' value='monthly'>
                Mensal
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          {transferPeriodice === 'weekly' &&
            <Form.Item
              name='transferDay'
              label='Dia de transferência'
              rules={rules}
            >
              <Select>
                <Select.Option key='1' value='1'>
                  Segunda-feira
                </Select.Option>
                <Select.Option key='2' value='2'>
                  Terça-feira
                </Select.Option>
                <Select.Option key='3' value='3'>
                  Quarta-feira
                </Select.Option>
                <Select.Option key='4' value='4'>
                  Quinta-feira
                </Select.Option>
                <Select.Option key='5' value='5'>
                  Sexta-feira
                </Select.Option>
              </Select>
            </Form.Item>
          }
          {transferPeriodice && transferPeriodice === 'monthly' &&
            <Form.Item
              name='transferDay'
              label='Dia de transferência (de 1 a 31)'
              rules={rules}
            >
              <Input type='number' min={0} max={31} />
            </Form.Item>
          }
        </Col>
        <Alert
        type='info'
        message={'Seu saldo OOri será transferido automaticamente para sua conta cadastrada no dia escolhido. Será cobrado o valor de R$ 3,67 por transferência.'}
      />
      </Row>
      <br />

      <OoriButton
        icon={<SaveOutlined />}
        type='primary'
        htmlType='submit'
        loading={saveLoading}>
          Salvar
      </ OoriButton>
      <br /><br />
      
    </Form>
  );

  async function loadBankAccount() {
    let transferDay, transferInterval

    if (!isAdmin) {
      var { results: [data] } = await tutorClient.getPaymentInfo(teacher.id);
      transferDay = teacher.transferDay
      transferInterval = teacher.transferInterval
      setTransferPeriodice(transferInterval)
    } else {
      var { data } = await adminCompanyClient.getPaymentInfo(teacher.companyId);
      data = data.bankAccount

      var companyRes = await adminCompanyClient.findById(teacher.companyId);
      console.log(companyRes);
      
      transferDay = companyRes.data.transferDay
      transferInterval = companyRes.data.transferInterval
      setTransferPeriodice(transferInterval)
    }
    
    setDocumentType(data?.documentType ? data?.documentType : 'cpf')
    
    form.setFieldsValue({
      bankCode: data?.bankCode,
      agency: data?.agencyNumber,
      agencyDigit: data?.agencyDg,
      accountNumber: data?.accountNumber,
      accountDigit: data?.accountDg,
      accountType: data?.type,
      fullName: data?.legalName,
      documentType: data?.documentType ? data?.documentType : 'cpf',
      documentNumber: data?.documentNumber,
      transferDay: `${transferDay}`,
      transferInterval: transferInterval,
    });

    setBankAccount({
      id: data?.id,
      bankCode: data?.bankCode,
      agencyNumber: data?.agencyNumber,
      agencyDg: data?.agencyDg,
      accountNumber: data?.accountNumber,
      accountDg: data?.accountDg,
      type: data?.type,
      legalName: data?.legalName,
      documentType: data?.documentType,
      documentNumber: data?.documentNumber,
    });

  }

  async function save(values: Store) {

    const { legalName } = values;

    if (legalName && legalName.length > 30) {
      message.error('O nome não deve conter mais de 30 caracteres.');
      return;
    }

    if (form.getFieldValue('documentNumber') && form.getFieldValue('cpf')) {
      const isValidate = validateCPF(
        form.getFieldValue('documentNumber').replace(/\D/g, '')
      );
      if (!isValidate) {
        return message.error('O CPF informado é inválido.');
      }
    }

    setSaveLoading(true);

    try {
      const bankAccountIsDefined = !!bankAccount.id;
      let response
      if (!isAdmin) {
        response = bankAccountIsDefined
        ? await tutorClient.updatePaymentInfo({
          ...values,
          id: bankAccount.id,
          documentNumber: removeNonNumbers(values.documentNumber),
          teacherId: auth.user.id,
        })
        : await tutorClient.setPaymentInfo({
          ...values,
          documentNumber: removeNonNumbers(values.documentNumber),
          teacherId: auth.user.id,
        });
      } else {
        response = bankAccountIsDefined
        ? await adminCompanyClient.updatePaymentInfo({
          ...values,
          id: bankAccount.id,
          documentNumber: removeNonNumbers(values.documentNumber),
          companyId: teacher.companyId,
        })
        : await adminCompanyClient.setPaymentInfo({
          ...values,
          documentNumber: removeNonNumbers(values.documentNumber),
          companyId: teacher.companyId,
        });
      }

      if (response) {
        message.success("Informações de pagamento salvas com sucesso");
      } else {
        message.error("Falha ao salvar");
      }
    } catch (err) {
      message.error(err?.response?.data?.error?.message || 'Falha ao salvar');
    } finally {
      setSaveLoading(false);
      afterSave();
    }

  }

  function updatePeriodice(value: 'daily' | 'weekly' | 'monthly') {
    setTransferPeriodice(value);
    form.setFieldsValue({
      transferDay: ''
    });
  }

  function updateDocumentNumber(value: 'cpf' | 'cnpj') {
    setDocumentType(value)
    form.setFieldsValue({ documentNumber: '' })
  }
}

export default TutorPaymentForm;
