import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Table, Popconfirm, Button, message,
} from 'antd';
import {
   EyeFilled, DeleteOutlined
} from '@ant-design/icons'
import { ColumnsType } from 'antd/lib/table';
import queryString from 'query-string';

import { adminTutorClient } from '../../clients/admin/AdminTutorClient';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';

import TutorViewDrawer from '../TutorViewDrawer';
import { CompanyDict } from '../../types/CompanyDict';

interface ITutorListTable {
  updateView: boolean;
}


const TutorListTable: React.FC<ITutorListTable> = ({
  updateView
}) => {

  const history = useHistory();
  const location = useLocation();
  const dict: CompanyDict = getCompanyDict()

  const [showEditForm, setShowEditForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tutors, setTutors] = useState<any[]>([]);

  useEffect(() => {
    loadTutors();
  }, [updateView]);

  const columns: ColumnsType<{}> = [
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
    title: 'Nome',
    dataIndex: 'name',
    responsive: ["sm"]
    },
    {
      dataIndex: 'id',
      render: (id: string) => (
        <>
          <Button 
            icon={<EyeFilled />} 
            shape='circle' 
            onClick={() =>openTutor(id)}
            size='small'
          />
          {/* <Popconfirm 
            title={'Tem certeza que deseja deletar este ' + dict.profissional.singular + '?'}
            onConfirm={(e) => deleteTeacher(id)}>
            <Button 
              icon={<DeleteOutlined />} 
              danger 
              shape='circle' 
              size='small'
              style={{marginLeft: 4}}
            />
          </Popconfirm> */}
        </>
      ),
    }
  ];

  return (
    <>
      <Table
        dataSource={tutors}
        columns={columns}
        rowKey={(r) => r.id}
        loading={loading}
      />

      {showEditForm && <TutorViewDrawer 
        showEditForm={showEditForm}
        closeEditForm={closeEditForm}
      />}
    </>
  );

  async function loadTutors() {
    const companyId = getCompanyId()
    const { results } = await adminTutorClient.findAll(companyId);
    setTutors(results);
    setLoading(false);
  }

  function openTutor(teacherId: string) {

    const queryValues = queryString.parse(location.search);

    history.push({
      search: new URLSearchParams(
        queryString.stringify({ ...queryValues, teacherId })
      ).toString(),
    });

    setShowEditForm(true);
  }

  async function deleteTeacher (teacherId: string) {
    const companyId = getCompanyId()

    const { error } = await adminTutorClient.removeCompany(parseInt(companyId), teacherId);

    if (!error) {
      loadTutors();
      return;
    }

    message.error(error.message || 'Não foi possível deletar ' + dict.especialidade.singular);
  }

  function closeEditForm() {
    setLoading(true);
    loadTutors();

    history.push({
      search: new URLSearchParams(
        queryString.stringify({})
      ).toString(),
    });
    setShowEditForm(false);
  }

}

export default TutorListTable;
