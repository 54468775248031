import * as React from 'react';
import { useState, useEffect } from "react";
import { Col, Form, Input, message, Row, Select, Divider, Alert } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useAuth } from "../../hooks/AuthHook";
import { adminCompanyClient } from '../../clients/admin/AdminCompanyClient';
import OoriButton from '../../components/Button';
import style from "./AdminSplitForm.module.scss";
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict } from '../../utils/CompanyConfigUtils';

const TutorPaymentForm: React.FC<any> = ({
  afterSave,
  admin
}) => {

  const auth = useAuth();
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  const dict: CompanyDict = getCompanyDict()

  useEffect(() => {
    initForm();
    // eslint-disable-next-line
  }, []);

  const rules = [{
    required: true,
    message: 'Campo obrigatório'
  }];

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={save}
    >
      <Col style={{marginBottom: '18px'}}>
        <h1 className={style.title}>Divisão do pagamento</h1>
        <h2 className={style.subtitle}>Configure a porcentagem dos pagamentos que será repassada para o {dict.profissional.singular} e que ficará para a administração</h2>
      </Col>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item name='adminSplitShare' label='% do admin' rules={rules}>
            <Input suffix={"%"} type='number' min={0} max={100} onBlur={() => {
              calculateOtherSplitShare('adminSplitShare')
            }}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='teacherSplitShare' label={'% do ' + dict.profissional.singular} rules={rules}>
            <Input suffix={"%"} type='number' min={0} max={100} onBlur={() => {
              calculateOtherSplitShare('teacherSplitShare')
            }}/>
          </Form.Item>
        </Col>
      </Row>
      <p><strong>Dica:</strong> As porcentagens acima devem sempre dar 100% no total.</p>
      <p>Exemplo: Configurando 10% para o admin e 90% para os {dict.profissional.plural}, dado um pagamento de R$110,00 o admin fica com </p>

      <br />

      <OoriButton
        icon={<SaveOutlined />}
        type='primary'
        htmlType='submit'
        loading={saveLoading}>
          Salvar
      </ OoriButton>
      <br />
      <br />
      <Alert
        type='info'
        message={<>
          <strong>Lembrete:</strong> Essa divisão será aplicada no valor dos pagamentos após a dedução da taxa de 9% da OOri
          <br /><br />Exemplo: Configurando 10% para o admin e 90% para os {dict.profissional.plural}.<br />Dado um pagamento de R$100,00:
          <ul>
            <li>Serão cobrados R$100,00 do cliente</li>
            <li>-R$9,00 referente à taxa de 9% da OOri</li>
            <li>Dos R$91,00 restantes, 10% irá para o admin (R$9,10) e 90% para o {dict.profissional.singular} (R$81,90)</li>
          </ul>
        </>}
      />
    </Form>
  );

  async function initForm() {

    console.log("loadBankAccount");

    const { data } = await adminCompanyClient.findById(auth.user.companyId)
    
    form.setFieldsValue({
      adminSplitShare: data?.adminSplitShare,
      teacherSplitShare: data?.teacherSplitShare
    })

  }

  function calculateOtherSplitShare(updatedField) {

    if (updatedField === 'adminSplitShare') {
      form.setFieldsValue({
        teacherSplitShare: 100 - form.getFieldValue('adminSplitShare')
      })  
    } else {
      form.setFieldsValue({
        adminSplitShare: 100 - form.getFieldValue('teacherSplitShare')
      })  
    }
  }

  async function save(values) {

    // console.log(form.getFieldValue('adminSplitShare') + form.getFieldValue('teacherSplitShare'));

    if (parseInt(form.getFieldValue('adminSplitShare')) + parseInt(form.getFieldValue('teacherSplitShare')) != 100 ) {
      message.error('A soma das porcentagens deve ser igual a 100%');
      return;
    }

    setSaveLoading(true);

    try {
     
      const response = await adminCompanyClient.updateCompany({
        adminSplitShare: parseInt(values.adminSplitShare),
        teacherSplitShare: parseInt(values.teacherSplitShare),
        id: auth.user.companyId
      })

      if (response) {
        message.success("Informações de divisão de pagamento salvas com sucesso");
      } else {
        message.error("Falha ao salvar");
      }
    } catch (err) {
      message.error(err?.response?.data?.error?.message || 'Falha ao salvar');
    } finally {
      setSaveLoading(false);
      afterSave();
    }

  }

  
}

export default TutorPaymentForm;
