import * as React from 'react';
import { useState } from 'react';
import {
  Collapse,
  Divider,
  Skeleton,
  Button,
  message,
  Modal,
  Input,
  Alert,
  Row
} from 'antd';

import ClassCard from '../../components/ClassCard';
import { tutorClassClient } from '../../clients/tutor/TutorClassClient';
import { IClass, ClassStatus } from '../../types/Class';

import style from './TutorClassCard.module.scss';
import { useAuth } from '../../hooks/AuthHook';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils';

interface ITutorClassCard {
  currClass: IClass;
  afterSave: () => void;
}

const { Panel } = Collapse;

const TutorClassCard: React.FC<ITutorClassCard> = ({
  currClass,
  afterSave
}) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [refuseLoading, setRefuseLoading] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const [detailedClass, setDetailedClass] = useState<IClass>();
  const [justification, setJustification] = useState('');
  const [showRefuseModal, setShowRefuseModal] = useState(false);
  const [cancelDisclaimer, setCancelDisclaimer] = useState('');
  const [cancelDisclaimerLoading, setCancelDisclaimerLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const dict: CompanyDict = getCompanyDict()

  return (
    <div className={style.tutorClassCard}>
      <ClassCard currClass={currClass} />
      <div className={style.actions}>
        <Collapse onChange={loadClassDetails} bordered={false}>
          <Panel header='Detalhes' key='1'>
            {loading &&
              <Skeleton active />
            }
            {!loading &&
              <>
                <Divider orientation='left'>{capitalize(dict.especialidade.singular)}</Divider>
                <p>{detailedClass?.subject?.name}</p>
                <Divider orientation='left'>{capitalize(dict.cliente.plural)}</Divider>
                <ul>
                  {detailedClass?.students.map((student) => (
                    <li key={student.studentId}>{student?.name || ''}</li>
                  ))}
                </ul>
                <div className={style.footerActions}>
                  { currClass.statusId === ClassStatus.REQUESTED &&
                    <>
                      <Button
                        onClick={openRefuseModal}
                      >Recusar</Button>
                      <Button
                      type='primary'
                      loading={acceptLoading}
                      onClick={acceptClass}
                      >Aceitar</Button>
                    </>
                  }
                  <>
                    {currClass.statusId === ClassStatus.SCHEDULED  &&
                      <Button
                        danger
                        // loading={cancelDisclaimerLoading}
                        onClick={displayCancelConfirmationModal}
                      >Cancelar</Button>
                    }
                    {/* {currClass.statusId === ClassStatus.ACTIVE  &&
                      <Button
                      type='primary'
                      loading={doneLoading}
                      onClick={doneClass}
                      >Concluir</Button>
                    } */}
                  </>
                </div>
                <Modal
                  title='Tem certeza que quer cancelar este atendimento?'
                  visible={showCancelConfirmationModal}
                  width={window.innerWidth < 600 ? '100%' : '50%'}
                  onOk={cancelClass}
                  onCancel={closeCancelConfirmationModal}
                  okText={`Cancelar ${dict.atendimento.singular}`}
                  cancelText='Voltar'
                  okButtonProps={{
                    loading: cancelLoading
                  }}
                >
                  <p>Ao cancelar o atendimento ele não poderá mais ser alterado ou reagendado.</p>
                </Modal>
              </>
            }
          </Panel>
        </Collapse>
      </div>
      <Modal
        title='Motivo da recusa'
        visible={showRefuseModal}
        width={window.innerWidth < 600 ? '100%' : '50%'}
        onOk={confirmRefuseClass}
        onCancel={closeRefuseModal}
        okText='Recusar'
        cancelText='Cancelar'
        okButtonProps={{
          loading: refuseLoading
        }}
      >
        <p>
          Por favor, descreva abaixo o motivo da recusa para enviar à pessoa.
        </p>
        <Input.TextArea
          placeholder='Justificativa'
          onChange={(e) => setJustification(e.target.value)}
          rows={3}
        />
      </Modal>
    </div>
  );

  function displayCancelConfirmationModal() {
    setShowCancelConfirmationModal(true)
  }

  function closeCancelConfirmationModal() {
    setShowCancelConfirmationModal(false)
  }

  async function loadClassDetails () {

    setLoading(true);

    const { data } = await tutorClassClient.findById(currClass.id);

    setDetailedClass(data);

    setLoading(false);
  }

  async function acceptClass () {

    setAcceptLoading(true);

    await updateStatus(ClassStatus.SCHEDULED);

    setAcceptLoading(false);
  }

  async function openRefuseModal () {
    setShowRefuseModal(true);
  }

  async function closeRefuseModal () {
    setShowRefuseModal(false);
  }

  async function confirmRefuseClass () {

    if (!justification) {
      message.error('Por favor, insirar o motivo da recusa.');
      return;
    }

    setRefuseLoading(true);

    const { error } = await tutorClassClient.update(currClass.id, {
      statusId: ClassStatus.REFUSED,
      refuseJustification: justification
    });

    if (error) {
      message.error(error.message || 'Falha ao salvar.');
      return;
    }

    message.success('Atualizada com sucesso!');
    setRefuseLoading(false);
    setJustification('');
    setShowRefuseModal(false);
    
    afterSave();
  }

  async function doneClass () {

    setDoneLoading(true);

    updateStatus(ClassStatus.DONE);

    setDoneLoading(false);
  }

  async function cancelClass () {
    if (currClass) {
      setCancelLoading(true);
      try {
        const { error } = await tutorClassClient.cancel(currClass.id, auth.user.id);

        if (error) {
          message.error(error.message || "Falha ao salvar");
          return;
        }

        message.success("Atendimento cancelado com sucesso!");
        setShowCancelConfirmationModal(false)
      } catch (err) {
        console.log(err);
        message.error(err.message || "Falha ao salvar");
      } finally {
        setCancelLoading(false);
        afterSave();
      }
    }
  }

  async function updateStatus (statusId: ClassStatus) {
    const { error } = await tutorClassClient.update(currClass.id, {
      statusId
    });

    if (error) {
      message.error(error.message || 'Falha ao salvar.');
      return;
    }

    afterSave();
    message.success('Atualizada com sucesso!');
  }

}

export default TutorClassCard;
