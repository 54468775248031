import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu as AntdMenu } from 'antd';

import { getPrimaryColor, getLogoPath, getHighlightColor, getSecondaryColor } from '../../utils/CompanyConfigUtils';

import style from './Menu.module.scss';

const { 
  Sider
} = Layout;

interface MenuItem {
  title: string,
  link: string,
  key: string,
  icon: React.ReactNode
}

interface ISideMenu {
  menuList: MenuItem[]
}

const SideMenu: React.FC<ISideMenu> = ({
  menuList
}) => {

  const location = useLocation();
  const primaryColor = getPrimaryColor();
  const secondaryColor = getSecondaryColor();
  const highlightColor = getHighlightColor();

  return (
    <>
      <Sider className={style.menu} style={{backgroundColor: primaryColor}}>
        <Logo />
        <AntdMenu style={{
          backgroundColor: primaryColor,
          borderRightColor: primaryColor}}>
          {menuList.map((item) => 
            <AntdMenu.Item 
              icon={item.icon} 
              key={item.key}
              className={location.pathname === item.link 
                ? 'ant-menu-item-selected'
                : ''
              } 
              style={ location.pathname === item.link ? { color: primaryColor, backgroundColor: highlightColor} : {}}
            >
              <Link to={item.link}>{item.title}</Link>
            </AntdMenu.Item>)
          }
        </AntdMenu>
      </Sider>
      <div className={style.mobileMenu}>
          {menuList.map((item) => 
            <Link 
              to={item.link} 
              key={item.key}
            >
              <div 
                className={location.pathname === item.link 
                  ? [style.mobileMenuItem, style.active].join(' ')
                  : style.mobileMenuItem
                } 
                key={item.key}
                style={{color: secondaryColor}}
              >
                {item.icon}
                <span className={style.title}>{item.title}</span>
              </div>
            </Link>
          )}
      </div>
    </>
  );
}

const Logo: React.FC = () => {
  const home = window.location.pathname.includes('profissional')
    ? '/profissional'
    : window.location.pathname.includes('admin')
      ? '/admin'
      : '/';

    const logoPath = getLogoPath()

  return (
    <Link to={home}>
      <img src={logoPath} alt=''/>
    </Link>
  );
}

export default SideMenu;
