import * as React from 'react';
import { useState, useEffect } from 'react';
import { Alert, Collapse, Button, message, Divider } from 'antd';
import { fromUnixTime } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { studentClient } from '../../clients/student/StudentClient';
import { useAuth } from '../../hooks/AuthHook';
import { Debt } from '../../types/Debts';

import style from './StudentDebts.module.scss';

const { Panel } = Collapse;

const StudentDebts: React.FC = () => {

  const auth = useAuth();
  const [debts, setDebts] = useState<Debt[]>([]);

  useEffect(() => {
    loadDebts();
  //eslint-disable-next-line
  }, [])

  return (
    <div className={style.studentDebts}>
      {!!debts.length &&
      <>
        <Alert 
          type='warning'
          message={debts.length === 1 
            ? `Você tem uma cobrança em aberto`
            : `Você tem ${debts.length} cobranças em aberto`
          }
        />
        <br />
        <Collapse>
          {debts.map((debt: Debt) => (
            <Panel 
              header={`Cobrança: ${debt.class.subject.name} - ${
                formatToTimeZone(new Date(debt.createdAt), "DD/MM/YYYY", { timeZone: "America/Recife" })
              }`} 
              key={debt.transactionId}>
              <div className={style.studentDebt}>
                <p>Vencimento:&nbsp;
                  <strong>
                    {formatToTimeZone(new Date(debt.boletoExpirationDate), "DD/MM/YYYY", { timeZone: "America/Recife" })}
                  </strong>
                </p>
                <a href={debt.boletoUrl} rel="noreferrer noopener" target='_blank'>Visualizar Boleto</a>
                <Divider />
                <CopyToClipboard 
                  text={debt.boletoBarcode}
                  style={{marginBottom: '16px'}}
                  onCopy={() => message.success('Código de barras copiado')}
                >
                  <Button> Copiar código de barras</Button>
                </CopyToClipboard>
                <strong>{debt.boletoBarcode}</strong>
              </div>
            </Panel>
          ))
          }
        </Collapse>
      </>
      }
    </div>
  );

  async function loadDebts () {
    const { data } = await studentClient.getDebts(auth.user.id);
    setDebts(data.debts);
  }
}

export default StudentDebts;
