import * as jwt from 'jwt-simple';
import * as React from 'react';
import { fromUnixTime, addDays } from 'date-fns';
import { createContext, useContext, useState } from 'react';
import { useCookie } from './CookieHook';
import { deleteCompany } from '../utils/LocalStorageUtils';
import { invalidateConfigCache } from '../utils/CompanyConfigUtils';


const authContext = createContext<IAuthHook>({
  tokenKey: '',
  authToken: '',
  user: null,
  signin: null,
  signout: null,
} as any);

export interface IAuthUser {
  companyId: string;
  name: string;
  email: string;
  id: string;
  userType: string;
}

export interface IAuthHook {
  authToken: string;
  user: IAuthUser;
  signin: (token: string, cookieKey: string) => void;
  signout: () => void;
}

export const AuthProvider: React.FC<{ children: React.ReactNode, authToken: string }> = ({
  children,
  authToken
}) => {
  return (
    <authContext.Provider value={useAuthProvider(authToken)}>
      {children}
    </authContext.Provider>
  )
};

export const useAuth = () => useContext(authContext);

export const useAuthProvider: (tokenKey: string) => IAuthHook = (tokenKey: string) => {
  const [authToken, setAuthTokenCookie, clearAuthTokenCookie] = useCookie(
    tokenKey
  );

  const [user, setUser] = useState<any>(() => {
    if (authToken) {
      const { id, userType, name, email, companyId } = jwt.decode(authToken, '', true);
      return { id, userType, name, email, companyId };
    }
    return undefined;
  });

  const signin = (token: string, cookieKey: string) => {
    const { iat, id, userType, name, email, companyId } = jwt.decode(token, '', true);

    setUser({
      id,
      userType,
      name,
      email,
      companyId
    });
    
    const generatedDate = fromUnixTime(iat);
    const expireIn = addDays(generatedDate, 7);

    setAuthTokenCookie(cookieKey , token, {
      expires: expireIn,
    });
  };

  const signout = () => {
    clearAuthTokenCookie();
    setUser(undefined);
    deleteCompany();
    invalidateConfigCache()
  };
  
  return {
    authToken,
    user,
    signin,
    signout,
  };
};
