import * as React from 'react';
import { useState } from 'react';
import { 
  Divider,
  Drawer
} from 'antd';

import Button from '../../components/Button';
import TutorListTable from '../../componentsAdmin/TutorListTable';
import TutorForm from '../../componentsAdmin/TutorForm';
import ExitButton from "../../components/ExitButton/ExitButton";

import style from './AdminTutorListPage.module.scss';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict } from '../../utils/CompanyConfigUtils';

const AdminTutorListPage: React.FC = () => {

  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [updateView, setUpdateView] = useState(false);
  const dict: CompanyDict = getCompanyDict()

  return (
    <div className={style.tutorListPage}>
      <ExitButton />
      <div style={{height: 10}}/>
      <Button 
        type='primary'
        onClick={openRegisterForm}
        style={{marginTop: 20}}
      >Adicionar {dict.profissional.singular}</Button>
      <Divider />
      <TutorListTable updateView={updateView} />

      <Drawer
        title={'Cadastro de  ' + dict.profissional.singular}
        visible={showRegisterForm}
        width={window.innerWidth < 600 ? '100%' : '60%'}
        destroyOnClose
        onClose={closeRegisterForm}
      >
        <TutorForm afterSave={closeRegisterForm} />
      </Drawer>
    </div>
  );

  function openRegisterForm () {
    setShowRegisterForm(true);
    setUpdateView(false);
  }

  function closeRegisterForm () {
    setShowRegisterForm(false);
    setUpdateView(true);
  }
}

export default AdminTutorListPage;
