import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Input, Select, message, Row, Col, Spin } from 'antd';
import Button from '../../components/Button';
import { SaveOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import queryString from 'query-string';

import { adminAreaClient } from '../../clients/admin/AdminAreaClient';
import { Area } from '../../types/Area';
import { adminBigAreaClient } from '../../clients/admin/AdminBigAreaClient';
import { CompanyDict } from '../../types/CompanyDict';
import { getCompanyDict, getCompanyId } from '../../utils/CompanyConfigUtils';
import { capitalize } from '../../utils/StrUtils'

const AreaForm: React.FC = () => {

  const location = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingArea, setLoadingArea] = useState(false);
  const [loadingBigAreas, setLoadingBigAreas] = useState(true);
  const [area, setArea] = useState<Area>();
  const [bigAreas, setBigAreas] = useState<Area[]>([]);
  const dict: CompanyDict = getCompanyDict()

  const { subjectAreaId }: any = queryString.parse(location.search);

  useEffect(() => {
    if (subjectAreaId) {
      loadArea();
    }

    loadBigAreas();
    //eslint-disable-next-line
  }, []);

  if (loadingArea) {
    return <Spin tip='Carregando...' />
  }

  return (
    <Form form={form} layout='vertical' onFinish={save}>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item label='Nome' name='name'>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={capitalize(dict.grandeArea)}
            name='subjectGreatAreaId'
          >
            <Select loading={loadingBigAreas}>
              {bigAreas.map((value: Area) => (
              <Select.Option value={value.id}>
                {value.name}
              </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Button 
        icon={<SaveOutlined />} 
        type='primary'
        htmlType='submit'
        loading={loading}
      >Salvar</Button> 
    </Form>
  );

  async function save (values: Store) {
    setLoading(true);

    try {
      const companyId = getCompanyId()

      const { success, error } = subjectAreaId && area
        ? await adminAreaClient.update(area.id, values)
        : await adminAreaClient.create({
          ...values,
          companyId: companyId
        });

      if(error && !success) {
        message.error(error.message || 'Falha ao salvar');
        return;
      } 
      message.success('Salvo com sucesso');
    } catch {
      message.error('Falha ao salvar');
    } finally {
      setLoading(false);
    }
  }

  async function loadArea () {
    setLoadingArea(true);
    const { data }  = await adminAreaClient.findById(subjectAreaId);
    form.setFieldsValue(data);
    setArea(data);
    setLoadingArea(false);
  }

  async function loadBigAreas () {
    const companyId = getCompanyId()

    const { results } = await adminBigAreaClient.findByCompany(companyId);

    setBigAreas(results || []);
    setLoadingBigAreas(false);
  }
}

export default AreaForm;
